import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=394a03c8&scoped=true&"
var script = {}
import style0 from "./Header.vue?vue&type=style&index=0&id=394a03c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394a03c8",
  null
  
)

export default component.exports