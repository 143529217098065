<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="header_title"><p>配信状況</p></div>
          </div>

          <div class="modal-body">
            <div class="div_search_show">
              <div class="div_search_memo2">
                <p>端末数：{{ totalCount }} 件</p>
              </div>
            </div>

            <div class="div_search_result title">
              <table class="t_contents_list">
                <thead>
                  <tr>
                    <th scope="col" class="t_device_no">端末番号</th>
                    <th scope="col" class="t_status_flg">配信状況</th>
                    <th scope="col" class="t_store_nm">店舗名</th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="div_search_result items" id="div_items">
              <table class="table_contents_chiled">
                <tbody>
                  <tr v-for="(row, key) in clientList" :key="key">
                    <td class="t_device_no">
                      {{ row.clientId }}
                    </td>
                    <td class="t_status_flg">
                      {{ row.statusFlg == 0 ? "配信成功" : "配信失敗" }}
                    </td>
                    <td class="t_store_nm">{{ row.storeNm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <div class="footer">
              <button class="button3" @click="$emit('close')">
                キャンセル
              </button>
              <button class="button6" @click="show('register')">
                緊急配信
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        ref="modal"
        :modaltyp="modalTyp"
        @register="register"
      ></ModalWindow>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";

export default {
  props: ["fromscheduleid", "fromplaylistid"], //配信状況子画面用スケジュールID、プレイリストID
  components: {
    ModalWindow,
  },
  name: "Modal",

  //表示処理
  activated: function () {
    console.log("CnfDeliveryStatusChilde is opend.");

    const condition = {
      fromscheduleid: this.fromscheduleid,
    };

    axios
      .get("/deliverystatus?" + "scheduleId=" + condition.fromscheduleid, {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      })
      .then((response) => {
        if (response.data.status == 0 || response.data.status == 1) {
          this.currentPage = response.data.currentPage;
          this.totalPage = response.data.totalPage;
          this.totalCount = response.data.number;
          this.clientList = response.data.data;

          //テーブルが5行以下の場合は、下線を表示する
          document.querySelector(".table_contents_chiled").style[
            "border-bottom"
          ] = "hidden";

          if (this.clientList != null) {
            if (this.clientList.length < 6) {
              document.querySelector(".table_contents_chiled").style[
                "border-bottom"
              ] = "dotted 1px";
            }
          }

          // 検索結果の表示
          document.querySelector("div.div_search_memo2").style.visibility =
            "visible";
        }
        //その他DBエラー（構文エラー、DB接続失敗）の場合
        else if (response.data.status == 2) {
          //エラーポップアップを表示
          this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          console.log("CnfDeliveryChild search ERROR2");
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // エラーポップアップを表示「予期せぬエラーが発生しました。」
        this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
      })
      .finally(() => {
        //スクロールバーを一番上に
        document.querySelector("#div_items").scrollTop = 0;

        console.log("CnfDeliveryChilde search End");
        this.axiosFlg = 0;
      });
  },
  data() {
    return {
      clientList: [],
      totalCount: 0,
      currentPage: 1,
      requestPage: 1,
      totalPage: 1,
      modalTyp: "", //ポップアップ種別
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentClientList: [],
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    //緊急配信ボタン押下処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfUrgent register Start");

        this.currentClientList = [];
        //配信失敗の端末にのみ、送信する
        for (var i = 0; i < this.clientList.length; i++) {
          if (this.clientList[i].statusFlg == 1) {
            this.currentClientList.push(this.clientList[i].clientId);
          }
        }
        const condition = {
          clientList: this.currentClientList, //子画面から取得した端末番号
          playlistId: this.fromplaylistid, //選択行のプレイリストID
        };

        let searchFlag = false;
        axios
          .post("/emergency/save/", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              console.log("CnfUrgent register Success");

              //完了ポップアップを表示
              this.$refs.modal.modalCompletion();
            }
            //1:DBエラー（構文エラー、DB接続失敗）
            else if (response.data.status == 1) {
              console.log("CnfUrgent register ERROR1");
              this.$refs.modal.modalError(),
                (this.modalTyp = "緊急配信登録に失敗しました。");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("CnfUrgent register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },
    //ポップアップ表示処理
    show(modaltyp) {
      //登録ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*--------------------------modal------------------------------*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  height: 680x;
  margin: 0px auto;
  padding: 0px 0px 20px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  width: 100%;
  height: 50px;
  color: white;
  font-size: 36px;
  background-color: #1a2f77;
}

.modal-body {
  margin: 10px 0;
  margin-left: 1px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/*-------------------------------------------------------------*/
/*-------------------------common------------------------------*/

.content {
  min-width: 1200px;
  overflow: auto;
  padding: 10px 30px;
  vertical-align: top;
}

button {
  width: 120px;
}

th,
td {
  font-weight: normal;
  border-style: solid;
}

th {
  border-width: 1px;
}

td {
  border-width: 0 1px 0 0;
}

button.tab {
  background-color: white;
  border-style: solid;
  border-width: 0;
}

button.tab.selected {
  border-width: 1px;
}
/*-------------------------------------------------------------*/
/*-------------------------thisPageStyle-----------------------*/

.div_search_result.items {
  margin-top: -1px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 758px;
  height: 302.5px;
  border-width: 1px;
  border-style: solid;
  margin-left: 17px;
}

/*キャンセルボタン */
.button3 {
  background-color: #1a2f77;
  color: white;
  /* border-style: none; */
  border-radius: 6px;
  margin-right: 100px;
}

/*緊急配信ボタン */
.button6 {
  background-color: hsl(0, 81%, 54%);
  color: white;
  border-radius: 6px;
  margin-left: 30px;
}
.div_search_show {
  display: flex;
  text-align: center;
}

.div_search_result.title {
  margin-left: 17px;
  width: 743px;
}
.div_search_btn {
  margin-right: 110px;
  margin-top: 30px;
}
.pageButton {
  height: 10px;
  width: 20px;
}
.footer {
  text-align: center;
  margin-left: 250px;
}
.header_title {
  text-align: center;
}
.page_num {
  white-space: nowrap;
}

/*タイトル */
.t_device_no {
  width: 250px;
  padding: 10px 10px;
}
.t_status_flg {
  width: 251px;
  padding: 10px 10px;
}
.t_store_nm {
  width: 250px;
  padding: 10px 10px;
}

/*行 */
td.t_device_no {
  width: 250px;
  padding: 0 10px;
}
td.t_status_flg {
  width: 250px;
  padding: 0 10px;
}
td.t_store_nm {
  width: 250px;
  padding: 0 10px;
}
.table_contents_chiled,
.t_contents_list {
  border: 0px;
  width: 760px;
}
/*-------------------------------------------------------------*/

/*枠線*/

.div_search_memo2 {
  text-align: center;
  margin-right: 30px;
  width: 200px;
  visibility: hidden; /*初期表示時は非表示*/
}
.table_contents_chiled {
  border: 0px;
}

/*ページ数のマージン調整*/
.pagechild {
  margin-top: 6px;
}
</style>
