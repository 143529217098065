<template>
  <div>
    <keep-alive>
      <component v-bind:is="component"></component>
    </keep-alive>
  </div>
</template>

<script>
import Unaccessed from "./contents/Unaccessed";
import MngAreas from "./contents/MngAreas";
import MngProducts from "./contents/MngProducts";
import MngStores from "./contents/MngStores";
import MngDevices from "./contents/MngDevices";
import MngPlaylists from "./contents/MngPlaylists";
import MngContents from "./contents/MngContets";
import CnfDelivery from "./contents/CnfDelivery";
import CnfUrgent from "./contents/CnfUrgent";
import CsvInprtExprt from "./contents/CsvInprtExprt";

export default {
  computed: {
    component() {
      return this.$store.state.component;
    },
  },
  components: {
    Unaccessed,
    MngAreas,
    MngProducts,
    MngStores,
    MngDevices,
    MngPlaylists,
    MngContents,
    CnfDelivery,
    CnfUrgent,
    CsvInprtExprt,
  },
};
</script>
<style scoped>
div {
  font-size: 16px;
}
</style>
