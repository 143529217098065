<template>
  <div>
    <Header></Header>
    <div class="body">
      <div>
        <Menu></Menu>
      </div>
      <div class="content">
        <Contents></Contents>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Menu from "../components/Menu";
import Contents from "../components/Contents";

export default {
  components: {
    Header,
    Menu,
    Contents,
  },
  created: function () {
    this.$store.commit("transition", "Unaccessed");
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

button {
  width: 120px;
  font-size: 18px;
}

input[type="date"] {
  width: 220px;
  height: 28px;
  font-size: 16px;
  padding: 0 3px;
}

input[type="text"] {
  width: 220px;
  height: 28px;
  font-size: 16px;
  padding: 0 3px;
}

select {
  width: 230px;
  height: 32px;
  font-size: 16px;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

th,
td {
  font-weight: normal;
  border-style: solid;
  padding: 0px 5px;
}

.div_search_result thead th {
  height: 30px;
  cursor: pointer;
}

th {
  border-width: 1px 1px 0 1px;
  /* height: 45px; */
  background-color: #b2c3ff;
}

td {
  border-width: 0 1px 0 0;
  height: 25px;
  word-break: break-word;
}

.table-title {
  font-size: 18px;
}
</style>

<style scoped>
.body {
  display: inline-flex;
  flex-direction: row;
}
.content {
  /* width: 100%; */
  /* min-width: 1200px; */
  overflow: hidden;
  padding: 10px 20px;
  font-size: 16px;
  vertical-align: top;
}
</style>