import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	actions: {
		autoLogin: function({ commit }) {
			const idToken = localStorage.getItem("idToken");
			if (!idToken) {
				return;
			}
			commit("updateIdToken", idToken);
		},
	},
	state: {
		component: "",
		idToken: null,
	},
	getters: {
		idToken: (state) => state.idToken,
	},
	mutations: {
		transition(state, component) {
			state.component = component;
		},
		logout(state) {
			state.idToken = null;
		},
		updateIdToken(state, idToken) {
			state.idToken = idToken;
		},
	},
});
