import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login';
import Main from './views/Main';
import store from './store';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
            path: '/login',
            component: Login,
            beforeEnter(to, from, next) {
                console.log('router to /login idToken :'+ store.getters.idToken);
                if (store.getters.idToken) {
                    next('/main');
                } else {
                    next();
                }
            }
        },
        {
            path: '/main',
            component: Main,
            beforeEnter(to, from, next) {
                console.log('router to /main idToken :'+ store.getters.idToken);
                if (store.getters.idToken) {
                    next();
                } else {
                    next('/login');
                }
            }
        },
        {
            path: '*',
            redirect: '/login',
            beforeEnter() {
                console.log('redirect idToken :'+ store.getters.idToken);
            }
        }
	],
});
