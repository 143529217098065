<template>
  <div class="root">
    <div class="title">
      <H1>デジタルサイネージ配信管理</H1>
      <H1>ログイン画面</H1>
    </div>
    <div class="user-info">
      <label for="user-name">ユーザー名</label>
      <input type="text" id="user-name" maxlength="40" autocomplete="off" />
    </div>
    <div class="user-info">
      <label for="user-pw">パスワード</label>
      <input type="password" id="user-pw" maxlength="12" autocomplete="off" />
    </div>
    <div class="message-area">
      <p id="message"></p>
    </div>
    <div class="login-execute">
      <button @click="login">ログイン</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      pw: "",
    };
  },
  created: function () {
    this.$store.commit("transition", "Login");
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    login: function () {
      console.log("login button pressed.");
      let userName = document.querySelector("#user-name").value;
      let userPw = document.querySelector("#user-pw").value;

      if (userName == "" || userPw == "") {
        // ユーザ名、パスワード未入力エラー
        document.querySelector("#message").innerHTML =
          "ユーザー名またはパスワードが未入力です。";
        document.querySelector("#user-pw").value = "";
      } else {
        document.querySelector("#message").innerHTML = "";

        axios
          .post("/login", { userId: userName, password: userPw })
          .then((response) => {
            console.log("login response " + response.data);

            if (response.data.token) {
              const idToken = response.data.token;
              const expiresIn = response.data.expiresIn;
              const refreshIn = response.data.refreshIn;
              const now = new Date();
              const expireTime = now.getTime() + response.data.expiresIn;
              this.$store.commit("updateIdToken", idToken);
              localStorage.setItem("idToken", idToken);
              localStorage.setItem("expiresIn", expiresIn);
              localStorage.setItem("expireTime", expireTime);
              localStorage.setItem("refreshIn", refreshIn);
            }
            console.log(
              "localstorage read idToken: " + this.$store.getters.idToken
            );

            this.$router.push("/main");
            this.userName = "";
            this.userPw = "";
          })
          .catch((error) => {
            // 認証エラー
            document.querySelector("#message").innerHTML =
              "ユーザー名またはパスワードが間違っています。";
            document.querySelector("#user-pw").value = "";
            console.log("login error " + error);
          });
      }
    },
  },
};
</script>

<style scoped>
.root,
.title {
  margin-top: 40px;
  display: flex;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #33447e;
}

.title H1 {
  margin: 5px 0 5px 0;
}

label {
  margin-right: 20px;
}

input {
  border-radius: 4px;
  border-color: #33447e;
  border-style: solid;
  border-width: 1px;
  padding: 0 5px;
  width: 220px;
}

label,
input,
button {
  font-size: 24px;
}

.user-info {
  margin-top: 30px;
}
.login-execute {
  margin-top: 40px;
}

button {
  width: 381px;
  padding: 3px;
  background-color: #33447e;
  color: #fff;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
}

.message-area {
  margin-top: 20px;
}

#message {
  color: red;
}
</style>