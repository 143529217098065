<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="header_title"><p>端末選択</p></div>
          </div>

          <div class="modal-body">
            <table class="table_search">
              <tbody>
                <tr>
                  <div class="div_group1">
                    <div class="div_reg_el">
                      <td class="td_store-no">
                        <div class="div_label-wrapper">
                          <label>店舗コード </label>
                        </div>
                      </td>
                      <td class="td_store-no">
                        <input
                          type="text"
                          id="store-id"
                          maxlength="16"
                          oninput="value = value.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i,'');"
                        />
                      </td>
                    </div>

                    <div class="div_reg_el">
                      <td class="td_store-nm">
                        <div class="div_label-wrapper">
                          <label>店舗名</label>
                        </div>
                      </td>
                      <td class="td_store-nm">
                        <input type="text" id="store-nm" maxlength="20" />
                      </td>
                    </div>

                    <div class="div_reg_el">
                      <td class="td_product-grp-nm">
                        <div class="div_label-wrapper">
                          <label>商品グループ</label>
                        </div>
                      </td>
                      <td class="td_product-grp-nm">
                        <input type="text" id="product-grp-nm" maxlength="20" />
                      </td>
                    </div>

                    <div class="div_reg_el">
                      <div class="div_search_button">
                        <button
                          class="button1"
                          @click="onClickSearch('search')"
                        >
                          検索
                        </button>
                      </div>
                    </div>
                  </div>

                  <td class="td_file_ref">
                    <div class="div_group2">
                      <div class="file_ref">
                        <input
                          type="text"
                          value=""
                          id="devicefile-id"
                          disabled="true"
                        />
                        <label for="label-input">
                          <input
                            type="file"
                            name="file"
                            id="label-input"
                            @change="changeFile"
                            style="display: none"
                            @click="onClickChangeFile"
                          />参照
                        </label>
                      </div>
                      <div class="div_capture_btn">
                        <button
                          class="button7"
                          @click="onClickSearch('capture')"
                          disabled="disabled"
                        >
                          取込
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="div_search_show">
              <div class="div_search_title">
                <label>端末一覧</label>
              </div>

              <div class="div_search_memo2">
                <p>検索結果：{{ totalCount }} 件</p>
                <div>
                  <div class="div-page-btnchild">
                    <button class="prevchild" @click="prevPage" disabled="true">
                      ≪
                    </button>
                    <p class="pagechild">{{ currentPage }} / {{ totalPage }}</p>
                    <button class="nextchild" @click="nextPage">≫</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="div_search_result title">
              <table class="t_contents_list">
                <thead>
                  <tr>
                    <th scope="col" class="t_chk_device">
                      <input
                        type="checkbox"
                        v-model="AllChecked"
                        value="1"
                        class="chkAll"
                        @click="clickAllChecked"
                        disabled="true"
                      />
                    </th>
                    <th scope="col" class="t_device_typ" @click="sortClientId">
                      端末番号
                    </th>
                    <th scope="col" class="t_store_nm" @click="sortStoreNm">
                      店舗名
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="div_search_result items" id="div_items">
              <table class="table_contents_chiled">
                <tbody>
                  <tr v-for="(row, key) in clientList" :key="key">
                    <td class="t_device">
                      <input
                        type="checkbox"
                        class="chk"
                        v-on:click="
                          clickChecked($event, row, key, row.clientId)
                        "
                      />
                    </td>
                    <td class="t_device_typ">
                      {{ row.clientId }}
                    </td>
                    <td class="t_store_nm">{{ row.storeNm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <div class="footer">
              <button class="button6" @click="onDecision" disabled="disabled">
                決定
              </button>
              <button class="button3" @click="$emit('close')">
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalWindow ref="modal" :modaltyp="modalTyp"></ModalWindow>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";

export default {
  components: {
    ModalWindow,
  },
  name: "Modal",
  data() {
    return {
      clientList: [],
      totalCount: 0,
      currentPage: 1,
      requestPage: 1,
      totalPage: 1,
      selectRow: [], //選択された行
      sortItem: "clientId", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgclientId: 0, // ソートフラグ(端末番号)
      sortFlgstoreNm: 0, // ソートフラグ(店舗名)
      AllChecked: 0, //チェックボックス全選択フラグ
      modalTyp: "", //ポップアップ種別
      csvArray: [], //CSVファイル→JSONファイルに変換したデータ
      selectItem: [], //選択した端末番号
      axiosFlg: 0, // 通信フラグ(連打対応)
      searchTyp: "", // 検索種別（検索ボタンと取込ボタンの判別用）
      currentStoreId: "", // 検索店舗コード保持用
      currentStoreNm: "", // 検索店舗名保持用
      currentProdNm: "", // 検索商品グループ名保持用
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDeliveryChilde search Start");
        const condition = {
          storeId: this.currentStoreId,
          storeNm: this.currentStoreNm,
          prodNm: this.currentProdNm,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
          page: this.requestPage,
        };

        axios
          .get(
            "/clientpopsearch?" +
              "storeId=" + encodeURIComponent(this.escape(condition.storeId)) +
              "&" +
              "storeNm=" + encodeURIComponent(this.escape(condition.storeNm)) +
              "&" +
              "prodNm=" + encodeURIComponent(this.escape(condition.prodNm)) +
              "&" +
              "page=" + condition.page +
              "&" +
              "sortItem=" + condition.sortItem +
              "&" +
              "sortDirection=" + condition.sortDirection,
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status == 0 || response.data.status == 1) {
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;
              this.clientList = response.data.data;

              //テーブルが5行以下の場合は、下線を表示する
              document.querySelector(".table_contents_chiled").style[
                "border-bottom"
              ] = "hidden";

              if (this.clientList != null) {
                if (this.clientList.length < 6) {
                  document.querySelector(".table_contents_chiled").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo2").style.visibility =
                "visible";

              //全選択チェックボックスの活性
              document.querySelector(".chkAll").disabled = false;

              // 初回検索時は端末番号の昇順
              if (this.sortItem === "clientId" && this.sortDirection === 0) {
                document.querySelector(".t_device_typ").innerText = "端末番号▲";
              }

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                //全選択チェックボックスの非活性
                document.querySelector(".chkAll").disabled = true;

                document.querySelector(
                  "div.div-page-btnchild"
                ).style.visibility = "hidden";
                document.querySelector(".t_device_typ").innerText = "端末番号";
                document.querySelector(".t_store_nm").innerText = "店舗名";
              } else {
                document.querySelector(
                  "div.div-page-btnchild"
                ).style.visibility = "visible";

                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prevchild").disabled = true;
                } else {
                  document.querySelector("button.prevchild").disabled = false;
                }

                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.nextchild").disabled = true;
                } else {
                  document.querySelector("button.nextchild").disabled = false;
                }
                console.log("CnfDeliveryChild search Success");
              }

              // //選択されている行と値をクリア
              // if (this.selectRow != null) {
              //   this.selectRow.style["background-color"] = "transparent";
              //   const objectNum = 10;
              //   for (let count = 0; count < objectNum; count++) {
              //     let objectName = "contents" + (count + 1);
              //     this.$set(this.clientList, objectName, "");
              //   }
              // }
            }
            //その他DBエラー（構文エラー、DB接続失敗）の場合
            else if (response.data.status == 2) {
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              console.log("CnfDeliveryChild search ERROR2");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
             //スクロールバーを一番上に
            document.querySelector('#div_items').scrollTop=0;

            //チェックボックス更新
            this.checkAction();
            //Allチェックボックスを更新
            this.AllCheckCheck();

            console.log("CnfDeliveryChilde search End");
            this.axiosFlg = 0;
          });
      }
    },
    checkAction() {
      //チェックリストに該当するデバイスIDが存在した場合、その行をチェックする
      //クライアントIDリストを作成
      var clientIdList = [];
      if (this.clientList != null) {
        for (var i = 0; i < this.clientList.length; i++) {
          clientIdList.push(this.clientList[i]["clientId"]);
        }
      }

      //チェックボックス
      var CHK = document.getElementsByClassName("chk");
      // 全てのチェックを外す
      for (i = 0; CHK.length > i; i++) {
        CHK[i].checked = false;
      }

      //選択行の色を戻す
      for (var k = 0; CHK.length > k; k++) {
        //選択行の色を解除
        this.selectRow = document.querySelector(
          ".table_contents_chiled"
        ).lastElementChild.children[k];
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      //対象項目にチェックを入れる
      for (i = 0; i < CHK.length; i++) {
        //番号取得
        const index = this.selectItem.indexOf(clientIdList[i]);
        if (index >= 0) {
          CHK[i].checked = true;
          //選択行の色を変更
          this.selectRow = document.querySelector(
            ".table_contents_chiled"
          ).lastElementChild.children[i];
          this.selectRow.style["background-color"] = "#0080ff20";
        }
      }
    },
    //ALLチェックのチェックをつけたり外したりする
    AllCheckCheck(){
      //チェックボックス
      var CHK = document.getElementsByClassName("chk");
      document.querySelector(".chkAll").checked=true;
      for(var i=0;i<CHK.length;i++){
        if(!CHK[i].checked){
          document.querySelector(".chkAll").checked=false;
          break;
        }
      }
    },

    // 検索ボタン押下時処理
    onClickSearch(searchtyp) {
      this.searchTyp = searchtyp;
      this.requestPage = 1;

      if (this.searchTyp == "search") {
        this.currentStoreId = document.querySelector("#store-id").value;
        this.currentStoreNm = document.querySelector("#store-nm").value;
        this.currentProdNm = document.querySelector("#product-grp-nm").value;
        //チェックリストを初期化
        this.selectItem = [];
        // 決定ボタンを非活性
        document.querySelector(".button6").disabled = true;
        document.querySelector(".button6").style["background-color"] = "gray";
        // 検索メソッドの呼び出し
        this.search();
      } else if (this.searchTyp == "capture") {
        //取込メソッドの呼び出し
        this.capture();
      }
    },

    //決定ボタン押下時
    onDecision() {
      //var CHK = document.getElementsByClassName("chk");
      //var num = 0;

      //チェックのある端末番号を取得する
      //for (var i = 0; CHK.length > i; i++) {
      //if (CHK[i].checked === true) {
      //this.$set(this.selectItem, num, {
      //clientId: this.clientList[i].clientId,
      //});
      //num = num + 1;
      //}
      //}
      //親画面に取得した端末番号を渡す
      this.$emit("parentMethod", this.selectItem);
    },
    chengeArray: function (vueConponent) {
      this.$papa.parse(vueConponent.file, {
        header: false,
        complete: function (results) {
          console.log("CnfDeliveryChild CsvToJson");
          //送信用string配列を作成 csvファイルの一列目が対象
          var StringArray = [];
          for (var i = 0; i < results.data.length; i++) {
            StringArray.push(results.data[i][0]);
          }
          vueConponent.csvArray = StringArray;
        },
      });
    },
    //参照ボタン初期化
    onClickChangeFile: function (e) {
      //ファイル名を初期化
      e.target.value = "";
    },
    //参照ボタン押下処理
    changeFile: function (e) {
      //ファイル情報の取得
      const files = e.target.files;
      this.file = files[0];
      var name = this.file.name;

      //CSV変換
      if (this.isCorrectExtension(name)) {
        //ファイル名に表示
        document.getElementById("devicefile-id").value = name;

        //チェックリスト初期化
        this.selectItem = [];

        //JSONデータに変換
        this.chengeArray(this);
        //取込ボタンを活性
        document.querySelector(".button7").disabled = false;
        document.querySelector(".button7").style["background-color"] =
          "#1a2f77";
      } else {
        //エラーポップアップ表示
        this.$refs.modal.modalError(),
          (this.modalTyp = "CSVファイルを選択してください。");
      }
    },
    //ファイルの拡張子判定
    isCorrectExtension(name) {
      //スペース以外の文字で始まって「.csv」で終わる文字(大文字・小文字を区別しない)
      var format = new RegExp("([^s]+(\\.(csv))$)", "i");
      return format.test(name);
    },

    //取込ボタン押下時処理
    capture() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDeliveryChild capture Start");
        const condition = {
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
          clientIds: this.csvArray,
        };
        axios
          .post("/clientcapture", condition, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;
              this.clientList = response.data.data;
              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo2").style.visibility =
                "visible";

              //全選択チェックボックスの活性
              document.querySelector(".chkAll").disabled = false;

              //チェックボックス更新
              this.checkAction();

              // 初回検索時は端末番号の昇順
              if (this.sortItem === "clientId" && this.sortDirection === 0) {
                document.querySelector(".t_device_typ").innerText = "端末番号▲";
              }

              if (
                response.data.currentPage === 1 ||
                response.data.totalPage < 2
              ) {
                document.querySelector("button.prevchild").disabled = true;
              } else {
                document.querySelector("button.prevchild").disabled = false;
              }
              if (
                response.data.currentPage === response.data.totalPage ||
                response.data.totalPage < 2
              ) {
                document.querySelector("button.nextchild").disabled = true;
              } else {
                document.querySelector("button.nextchild").disabled = false;
              }

              console.log("CnfDeliveryChild capture Success");
            }
            //
            else if (response.data.status == 1) {
              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo2").style.visibility =
                "visible";

              document.querySelector("div.div-page-btnchild").style.visibility =
                "hidden";

              console.log("CnfDeliveryChild capture ERROR1");
              //エラーポップアップを表示
              this.$refs.modal.modalError(),
                (this.modalTyp = "ファイルが破損しています。");
              return;
            }
            // 2:DBエラー（構文エラー、DB接続失敗）
            else if (response.data.status == 2) {
              console.log("CnfDeliveryChild capture ERROR2");
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("CnfUrgentChilde capture End");
            this.axiosFlg = 0;
          });
      }
    },

    //チェックボックス全選択処理
    clickAllChecked: function (event) {
      var CHK = document.getElementsByClassName("chk");
      var AllChecked=document.querySelector(".chkAll").checked
      var actionFlg;

      //決定ボタンを活性
      document.querySelector(".button6").disabled = false;
      document.querySelector(".button6").style["background-color"] = "#1a2f77";

      // 全体用のチェックボックスの状態を判断して行のチェックボックスに設定するフラグを格納する
      if (event.target.checked == true) {
        actionFlg = true;
      } else {
        actionFlg = false;
      }
      // 行のチェックボックスにチェックの付けたり外したりする
      for (var i = 0; CHK.length > i; i++) {
        CHK[i].checked = actionFlg;
      }

      //全体用チェックボックスtrueの場合
      if (AllChecked == true) {
        for (var j = 0; CHK.length > j; j++) {
          //選択行の色を変更
          this.selectRow = document.querySelector(
            ".table_contents_chiled"
          ).lastElementChild.children[j];
          this.selectRow.style["background-color"] = "#0080ff20";

          //決定ボタンを活性
          document.querySelector(".button6").disabled = false;
          document.querySelector(".button6").style["background-color"] =
            "#1a2f77";
        }
        //全ての項目をチェックリストに追加
        if (this.clientList.length != null) {
          for (i = 0; i < this.clientList.length; i++) {
            //重複チェック
            if (!this.selectItem.includes(this.clientList[i]["clientId"])) {
              this.selectItem.push(this.clientList[i]["clientId"]);
            }
          }
        }
      } else {
        for (var k = 0; CHK.length > k; k++) {
          //選択行の色を解除
          this.selectRow = document.querySelector(
            ".table_contents_chiled"
          ).lastElementChild.children[k];
          this.selectRow.style["background-color"] = "transparent";
          this.selectRow = null;
        }
        //チェックした項目を削除
        for (i = 0; i < this.clientList.length; i++) {
          var index = this.selectItem.findIndex(
            (item) => item === this.clientList[i]["clientId"]
          );
          if (index >= 0) {
            this.selectItem.splice(index, 1);
          }
        }
        //決定ボタンを非活性
        if (this.selectItem.length == 0) {
          document.querySelector(".button6").disabled = true;
          document.querySelector(".button6").style["background-color"] = "gray";
        }
      }
    },

    //チェックボックス処理
    clickChecked: function (event, row, key, clientId) {
      if (event.target.checked == true) {
        //選択行の色を変更
        this.selectRow = document.querySelector(
          ".table_contents_chiled"
        ).lastElementChild.children[key];

        this.selectRow.style["background-color"] = "#0080ff20";

        //決定ボタンを活性
        document.querySelector(".button6").disabled = false;
        document.querySelector(".button6").style["background-color"] =
          "#1a2f77";

        //チェックした項目を保存
        this.selectItem.push(clientId);
      } else {
        //選択行の色を変更
        this.selectRow = document.querySelector(
          ".table_contents_chiled"
        ).lastElementChild.children[key];

        this.selectRow.style["background-color"] = "white";
        this.selectRow = null;

        //チェックボックスにひとつもチェックがない場合、決定ボタンを非活性
        var CHK = document.getElementsByClassName("chk");
        var isChecked = false;
        for (let i = 0; i < CHK.length; i++) {
          if (CHK[i].checked) {
            isChecked = true;
          }
        }

        if (!isChecked) {
          document.querySelector(".button6").disabled = true;
          document.querySelector(".button6").style["background-color"] = "gray";
        }

        //チェックした項目を削除
        var index = this.selectItem.findIndex((item) => item === clientId);
        if (index >= 0) {
          this.selectItem.splice(index, 1);
        }
      }

      if (event.target.checked == false) {
        this.AllChecked = 0;
      }
    },
    //前ボタン押下処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        if (this.searchTyp == "search") {
          // 検索メソッドの呼び出し
          this.search();
        } else if (this.searchTyp == "capture") {
          //取込メソッドの呼び出し
          this.capture();
        }
      }
    },
    //次ボタン押下処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        if (this.searchTyp == "search") {
          // 検索メソッドの呼び出し
          this.search();
        } else if (this.searchTyp == "capture") {
          //取込メソッドの呼び出し
          this.capture();
        }
      }
    },
    // ソート機能(端末番号)
    sortClientId() {
      console.log("CnfDeliveryChild sortClientId Start");
      if (this.clientList != null && this.clientList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "clientId";
        // 昇順・降順の切替
        if (this.sortFlgclientId == 0) {
          this.sortDirection = 1;
          this.sortFlgclientId = 1;
          document.querySelector(".t_device_typ").innerText = "端末番号▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgclientId = 0;
          document.querySelector(".t_device_typ").innerText = "端末番号▲";
        }
        document.querySelector(".t_store_nm").innerText = "店舗名";

        if (this.searchTyp == "") {
          this.searchTyp = "search";
        }

        // 検索メソッドの呼び出し
        if (this.searchTyp == "search") {
          // 検索メソッドの呼び出し
          this.search();
        } else if (this.searchTyp == "capture") {
          //取込メソッドの呼び出し
          this.capture();
        }
        console.log("CnfDeliveryChild sortClientId End");
      }
    },

    // ソート機能(店舗名)
    sortStoreNm() {
      console.log("CnfDeliveryChild sortStoreNm Start");
      if (this.clientList != null && this.clientList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "storeNm";
        // 昇順・降順の切替
        if (this.sortFlgstoreNm == 0) {
          this.sortDirection = 1;
          this.sortFlgstoreNm = 1;
          document.querySelector(".t_store_nm").innerText = "店舗名▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgstoreNm = 0;
          document.querySelector(".t_store_nm").innerText = "店舗名▲";
        }
        document.querySelector(".t_device_typ").innerText = "端末番号";

        if (this.searchTyp == "") {
          this.searchTyp = "search";
        }

        // 検索メソッドの呼び出し
        if (this.searchTyp == "search") {
          // 検索メソッドの呼び出し
          this.search();
        } else if (this.searchTyp == "capture") {
          //取込メソッドの呼び出し
          this.capture();
        }
        console.log("CnfDeliveryChild sortStoreNm End");
      }
    },
    // //画面の初期化
    // clearDisp() {
    //   //入力値のクリア
    //   document.querySelector("#store-id").value = "";
    //   document.querySelector("#store-nm").value = "";
    //   document.querySelector("#product-grp-nm").value = "";
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*--------------------------modal------------------------------*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  height: 680x;
  margin: 0px auto;
  padding: 0px 0px 20px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  width: 100%;
  height: 50px;
  color: white;
  font-size: 36px;
  background-color: #1a2f77;
}

.modal-body {
  margin: 10px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/*-------------------------------------------------------------*/
/*-------------------------common------------------------------*/

.content {
  min-width: 1200px;
  overflow: auto;
  padding: 10px 30px;
  vertical-align: top;
}

button {
  width: 120px;
}

th,
td {
  font-weight: normal;
  border-style: solid;
}

th {
  border-width: 1px;
}

td {
  border-width: 0 1px 0 0;
}

button.tab {
  background-color: white;
  border-style: solid;
  border-width: 0;
}

button.tab.selected {
  border-width: 1px;
}
/*-------------------------------------------------------------*/
/*-------------------------thisPageStyle-----------------------*/
input[type="text"] {
  height: 23px;
  width: 180px;
}

.div_search_result.items {
  margin-top: -1px;
  overflow-y: auto;
  width: fit-content;
  height: 302.5px;
  border-width: 1px;
  border-style: solid;
  margin-left: 17px;
}
.table_search td {
  border-style: hidden;
}

.table_search {
  margin-left: 30px;
  border-style: hidden;
}

.button1,
.button3 {
  background-color: #1a2f77;
  color: white;
  /* border-style: none; */
  border-radius: 6px;
}

button.button6,
button.button7 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

.button6 {
  margin-right: 50px;
}
.div_search_show {
  display: flex;
  text-align: center;
}
.div_search_title {
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-right: 400px;
}
.div_search_result.title {
  margin-left: 17px;
  width: 743px;
}
.div_search_btn {
  margin-right: 110px;
  margin-top: 30px;
}
.pageButton {
  height: 10px;
  width: 20px;
}
.footer {
  text-align: center;
}
.header_title {
  text-align: center;
}
.page_num {
  white-space: nowrap;
}

/*タイトル */
.t_chk_device {
  width: 15px;
  padding: 10px 10px;
}
.t_device_typ {
  width: 300px;
  padding: 10px 10px;
}
.t_store_nm {
  width: 370px;
  padding: 10px 10px;
}
td.t_device {
  padding: 0 10px;
}
td.t_device_typ {
  width: 300px;
  padding: 0 10px;
}
td.t_store_nm {
  width: 370px;
  padding: 0 10px;
}
.table_contents_chiled {
  border: 0px;
  width: 741px;
}

/*-------------------------------------------------------------*/

.div_search,
.div_reg_el {
  display: flex;
  padding: 9px 0 0 0;
}

/*参照ボタン*/
label[for="label-input"] {
  color: black;
  background-color: rgb(239, 239, 239);
  border-style: outset;
  border-width: 2px;
  border-color: rgb(118, 118, 118);
  width: 70px;
  height: 19px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  margin-left: 10px;
}
.div_label-wrapper {
  display: inline-block;
  width: 115px;
  text-align: right; /*右揃え*/
}
/*検索ボタン*/
.div_reg_el:nth-child(4) {
  justify-content: center;
  margin-top: 20px;
}
/*ファイル名*/
input[id="devicefile-id"],
select {
  width: 200px;
  height: 23px;
  margin-left: 40px;
}
/*取込ボタン*/
.div_capture_btn {
  margin: 70px 0 0 90px;
}

/*枠線*/
.div_group1,
.div_group2 {
  border-style: dotted;
  border-radius: 10px;
  border-width: 2px;
  padding-right: 20px;
  padding-top: 15px;
  height: 180px;
}
/*ページボタン*/
.div-page-btnchild {
  display: flex;
  justify-content: center;
}
button.prevchild {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.nextchild {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
.div_search_memo2 {
  text-align: center;
  margin-right: 30px;
  width: 200px;
  visibility: hidden; /*初期表示時は非表示*/
}
.table_contents_chiled {
  border: 0px;
}
td.t_store_nm {
  border-right: 0px;
}
/* 
.items tr {
  height: 50px;
}
 */
.file_ref {
  padding-top: 30px;
}
/*ページ数のマージン調整*/
.pagechild {
  margin-top: 6px;
}
</style>
