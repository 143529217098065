<template>
  <div>
    <div class="div_capture">
      <div class="div_radio_grp">
        <input
          type="radio"
          name="capture1"
          id="device-input"
          value="deviceinput"
          v-model="csvtypinput"
          @click="clickCheckedin('device-input')"
        />端末一覧
        <input
          type="radio"
          name="capture2"
          id="playlist-input"
          value="playlistinput"
          v-model="csvtypinput"
          @click="clickCheckedin('playlist-input')"
        />プレイリスト配信一覧
      </div>
      <div class="file_ref">
        <div class="div-reg-el">
          <input type="text" value="" id="contentfile-id" disabled="true" />
          <label for="label-input">
            <input
              type="file"
              name="file"
              id="label-input"
              class="input_btn"
              @change="changeFile"
              style="display: none"
              disabled="true"
            />参照
          </label>
          <div class="div_capture_btn">
            <button
              class="capture_btn"
              @click="show('csvinput')"
              disabled="true"
            >
              取込
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="div_output">
      <div class="div_radio_grp2">
        <input
          type="radio"
          name="output1"
          value="deviceoutput"
          v-model="csvtypoutput"
          @click="clickCheckedout"
        />端末一覧
        <input
          type="radio"
          name="output2"
          value="playlistoutput"
          v-model="csvtypoutput"
          id="playlistout-input"
          @click="clickCheckedout"
        />プレイリスト配信一覧
      </div>
      <div class="div_output_btn">
        <button class="output_btn" @click="show('csvoutput')" disabled="true">
          出力
        </button>
      </div>
    </div>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @csvinput="onClickCapture"
      @csvoutput="onClickOutput"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";

export default {
  components: {
    ModalWindow,
  },
  data() {
    return {
      file: {}, //参照ファイル名
      csvtypinput: null, //ラジオボタン値取得用
      csvtypoutput: null, //ラジオボタン値取得用
      csvoutput: [], //CSV出力データリスト
      modalTyp: null,
      b64data: "", //base64に変換後のCSVデータ
      axiosFlg: 0, // 通信フラグ(連打対応)
      test: [],
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },

  methods: {
    //参照ボタン押下処理
    changeFile: function (e) {
      console.log("CsvInprtExprt changeFile Start");
      //ファイル情報の取得
      const files = e.target.files;
      this.file = files[0];

      //ファイル名を表示
      var name = this.file.name;
      if (this.isCorrectExtension(name)) {
        //ファイル名に表示
        document.getElementById("contentfile-id").value = name;
        // 取込ボタンの活性化
        document.querySelector("button.capture_btn").disabled = false;
        document.querySelector(".capture_btn").style["background-color"] =
          "#1a2f77";
      } else {
        //エラーポップアップ表示
        this.$refs.modal.modalError(),
          (this.modalTyp = "CSVファイルを選択してください。");
      }

      //CSVデータ取り込んで、Base64エンコード文字列に変換する
      let vm = this;
      vm.workers = [];

      let reader = new FileReader();
      reader.readAsText(this.file);

      reader.onload = () => {
        let lines = reader.result;
        vm.workers = lines;
        console.log(vm.workers);

        //base64にエンコード
        this.b64data = window.btoa(unescape(encodeURIComponent(vm.workers)));
        console.log(this.b64data);
        console.log("CsvInprtExprt changeFile End");
      };

      //changeイベント発火のためファイル名を初期化しておく
      document.getElementById("label-input").value = "";
    },

    //ファイルの拡張子判定
    isCorrectExtension(name) {
      //スペース以外の文字で始まって「.csv」で終わる文字(大文字・小文字を区別しない)
      var format = new RegExp("[^\\s]+.(csv|CSV)$", "i");
      return format.test(name);
    },

    //取込ボタン押下処理
    onClickCapture() {
      if (this.axiosFlg === 0) {
        console.log("CsvInprtExprt onClickCapture Start");
        this.axiosFlg = 1;
        //参照ファイル未指定で、取込ボタン押下した場合
        if (document.getElementById("contentfile-id").value == null) {
          //エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp = "取込ファイルを指定してください");
          return;
        }

        //端末一覧の場合
        if (this.csvtypinput == "deviceinput") {
          this.csvtypinput = "t";
        }
        //プレイリスト配信一覧の場合
        else if (this.csvtypinput == "playlistinput") {
          this.csvtypinput = "p";
        }

        const condition = {
          //ラジオボタン識別
          radioButton: this.csvtypinput,
          data: this.b64data,
        };
        axios
          .post("/csv/import", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            //0:正常
            if (response.data.status == 0) {
              console.log("CsvInprtExprt onClickCapture Success");
              //完了ポップアップ
              this.$refs.modal.modalError(),
                (this.modalTyp = "CSVの取込に成功しました。");

              this.clearDispCap();

              //入力値をクリアする
              document.getElementById("contentfile-id").value = "";
              document.querySelector(".input_btn").disabled = true;
              document.querySelector('label[for="label-input"]').style[
                "color"
              ] = "white";
              document.querySelector("button.capture_btn").disabled = true;
              document.querySelector(".capture_btn").style["background-color"] =
                "#767676";
            }
            //1:CSVファイルエラー（ファイル破損、入力エラー）
            else if (response.data.status == 1) {
              // ラジオボタンの選択状態を保持
              if (condition.radioButton == "t") {
                document.getElementById("device-input").checked = true;
              } else if (condition.radioButton == "p") {
                document.getElementById("playlist-input").checked = true;
              }
              console.log("CsvInprtExprt onClickCapture ERROR1");
              //messageで受け取ったメッセージを取得
              var message = response.data.message;
              //エラーポップアップを表示
              this.$refs.modal.modalError(), (this.modalTyp = message);

              return;
            }
            //2:その他DBエラー（構文エラー、DB接続失敗）
            else if (response.data.status == 2) {
              // ラジオボタンの選択状態を保持
              if (condition.radioButton == "t") {
                document.getElementById("device-input").checked = true;
              } else if (condition.radioButton == "p") {
                document.getElementById("playlist-input").checked = true;
              }
              console.log("CsvInprtExprt onClickCapture ERROR2");
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");

              return;
            }
          })
          .catch((error) => {
            console.log(error);
            // ラジオボタンの選択状態を保持
            if (condition.radioButton == "t") {
              document.getElementById("device-input").checked = true;
            } else if (condition.radioButton == "p") {
              document.getElementById("playlist-input").checked = true;
            }
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("CsvInprtExprt onClickCapture End");
            this.axiosFlg = 0;
          });
      }
    },

    //取込用ラジオボタン選択時
    clickCheckedin: function (id) {
      if (id == "device-input") {
        document.getElementById("playlist-input").checked = false;
      } else if (id == "playlist-input") {
        document.getElementById("device-input").checked = false;
      }
      // 参照ボタンの活性化
      document.querySelector(".input_btn").disabled = false;
      document.querySelector('label[for="label-input"]').style["color"] =
        "black";
    },

    //出力用ラジオボタン選択時
    clickCheckedout: function () {
      // 出力ボタンの活性化
      document.querySelector("button.output_btn").disabled = false;
      document.querySelector(".output_btn").style["background-color"] =
        "#1a2f77";
    },

    //出力ボタン押下処理
    onClickOutput() {
      if (this.axiosFlg === 0) {
        console.log("CsvInprtExprt onClickOutput Start");
        this.axiosFlg = 1;
        const FileDownload = require("js-file-download");

        //端末一覧の場合
        if (this.csvtypoutput == "deviceoutput") {
          this.csvtypoutput = "t";
        }
        //プレイリスト配信一覧の場合
        else if (this.csvtypoutput == "playlistoutput") {
          this.csvtypoutput = "p";
        }
        const condition = {
          //ラジオボタン識別
          radiobutton: this.csvtypoutput,
        };

        axios
          .get("/csv/export/" + condition.radiobutton, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (this.csvtypoutput == "t") {
              console.log("CsvInprtExprt onClickOutput csvtypoutput");
              FileDownload(response.data, "devicelist.csv");

              this.clearDispOut();
            } else if (this.csvtypoutput == "p") {
              console.log("CsvInprtExprt onClickOutput playlistdelivery");
              FileDownload(response.data, "playlistdelivery.csv");

              this.clearDispOut();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("CsvInprtExprt onClickOutput End");
            this.axiosFlg = 0;
          });
      }
    },
    // 画面の初期化処理
    clearDispCap() {
      //ファイル名のクリア
      document.getElementById("contentfile-id").value = "";

      // 取込ボタンの非活性化
      document.querySelector("button.capture_btn").disabled = true;
      document.querySelector(".capture_btn").style["background-color"] = "gray";
    },
    // 画面の初期化処理
    clearDispOut() {
      // 出力ボタンの非活性化
      document.querySelector("button.output_btn").disabled = true;
      document.querySelector(".output_btn").style["background-color"] = "gray";
    },
    //ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },
  },
};
</script>

<style scoped>
.div_radio_grp {
  margin-top: 100px;
  margin-left: 35px;
}

.div_radio_grp2 {
  margin-top: 120px;
  margin-left: 35px;
}

.fileUploder .uploader {
  display: none;
}

.file_ref {
  display: flex;
  margin-top: 20px;
  margin-left: 35px;
}

.div_capture_btn {
  margin-left: 30px;

}

.div_output_btn {
  margin-left: 462px;

}

button.capture_btn,
button.output_btn {
  background-color: gray;
  color: white;
  border-style: none;
  border-radius: 6px;
    height: 33px;
}

button.ref_btn {
  width: 70px;
}

/*参照ボタン*/
label[for="label-input"] {
  color: #bfbfbf;
  background-color: rgb(239, 239, 239);
  border-style: outset;
  border-width: 2px;
  border-color: rgb(118, 118, 118);
  width: 70px;
  height: 26px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 3px;
  margin-left: 10px;
}
/*ファイル名*/
input[id="contentfile-id"],
select {
  width: 300px;
  /* height: 23px; */
}
.div-reg-el {
  display: flex;
  padding: 15px 0 0 0;
}

/*ラジオボタンの間隔用*/
input[id="playlist-input"],
input[id="playlistout-input"],
select {
  margin-left: 50px;
}
</style>
