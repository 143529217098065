<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="header_title"><p>コンテンツ選択</p></div>
          </div>

          <div class="modal-body">
            <table class="table_search">
              <tbody>
                <tr class="tr_contents">
                  <td class="td_contetnts_name">
                    <div class="div_label-wrapper">
                      <label>コンテンツ名</label>
                    </div>
                  </td>
                  <td class="td_contetnts_name">
                    <input
                      type="text"
                      class="textbox"
                      id="contents_name"
                      value=""
                      maxlength="30"
                    />
                  </td>
                  <td class="td_contents_kind">
                    <div class="div_label-wrapper">
                      <label>コンテンツ種別</label>
                    </div>
                  </td>
                  <td class="td_contents_kind">
                    <select id="contents_kind">
                      <option></option>
                      <option
                        v-for="option in productGroupOptions"
                        v-bind:key="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr class="tr_upload_date">
                  <td>
                    <div class="div_label-wrapper">
                      <label>アップロード日</label>
                    </div>
                  </td>
                  <td cals="2">
                    <input class="date" id="date_first_child" type="date" />
                    <label id="date">～</label>
                    <input class="date" id="date_end_child" type="date" />
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="div_search_btn btn">
              <button class="button1" @click="onClickSearch">検索</button>
            </div>
            <div class="div_search_show">
              <div class="div_search_title">
                <label class="table-title">コンテンツ一覧</label>
              </div>

              <div class="div_search_memo2">
                <p>検索結果：{{ totalCount }} 件</p>
                <div>
                  <div class="div-page-btnchild">
                    <button class="prevchild" @click="prevPage" disabled="true">
                      ≪
                    </button>
                    <p class="pagechild">{{ currentPage }} / {{ totalPage }}</p>
                    <button class="nextchild" @click="nextPage">≫</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="div_search_result title">
              <table class="t_contents_list">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="t_contents_name"
                      @click="sortContentsNm"
                    >
                      コンテンツ名
                    </th>
                    <th
                      scope="col"
                      class="t_contents_kind"
                      @click="sortContentsTyp"
                    >
                      コンテンツ種別
                    </th>
                    <th scope="col" class="t_contents_prev">表示</th>
                    <th scope="col" class="t_upload_date" @click="sortUploadDt">
                      アップロード日
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="div_search_result items" id="div_items">
              <table class="table_contents_chiled">
                <tbody>
                  <tr
                    v-for="(row, key) in contentsList"
                    :key="key"
                    @click="onPlaylistChiled(row, key)"
                  >
                    <td class="t_contents_name">{{ row.contentsNm }}</td>
                    <td class="t_contents_kind">
                      {{ row.contentsTyp == 0 ? "動画" : "静止画" }}
                    </td>
                    <td class="t_contents_prev">
                      <img
                        v-bind:src="startButtonImagePath"
                        v-on:click="onStartButton(row, key)"
                        class="btn_start"
                        align="center"
                      />
                    </td>
                    <td class="t_upload_date">{{ row.uploadDt }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <div class="footer">
              <button id="button2" @click="onDecision" disabled="disabled">
                決定
              </button>
              <button class="button3" @click="$emit('close')">
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalWindow ref="modal" :modaltyp="modalTyp"></ModalWindow>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";

export default {
  // props: ["modalTyp"],
  components: {
    ModalWindow,
  },
  name: "Modal",
  data() {
    return {
      startButtonImagePath: require("@/assets/png/startButton.png"),
      contentsList: [],
      totalCount: 0,
      currentPage: 1,
      requestPage: 1,
      totalPage: 1,
      modalTyp: "", //ポップアップ種別
      selectRow: null, //選択された行
      selectMovie: null,
      selectContentsSize: 0,  //コンテンツサイズ
      sortItem: "contentsNm", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgContentsNm: 0, // ソートフラグ(コンテンツ名)
      sortFlgContentsTyp: 0, // ソートフラグ(コンテンツ種別)
      sortFlgUploadDt: 0, // ソートフラグ(アップロード日)
      currentContentfile: null, //選択中のコンテンツファイル
      axiosFlg: 0, // 通信フラグ(連打対応)
      productGroupOptions: [
        { id: "0", name: "動画" },
        { id: "1", name: "静止画" },
      ],
      currentContentsNm: "", // 検索コンテンツ名保持用
      currentContentsTyp: "", // 検索コンテンツ種別保持用
      currentUploadDtStart: "", // 検索最終更新開始日保持用
      currentUploadDtEnd: "", // 検索最終更新終了日保持用
      pictureData: null, //画像データ
      movieData: null, //動画データ
      blob: null, //動画または写真のbinaryデータをラップしたもの
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;

        const condition = {
          contentsNm: this.currentContentsNm,
          contentsTyp: this.currentContentsTyp,
          uploadDtStart: this.currentUploadDtStart,
          uploadDtEnd: this.currentUploadDtEnd,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        // 端末指定区分の設定
        for (var i = 0; i < this.productGroupOptions.length; i++) {
          if (condition.contentsTyp == this.productGroupOptions[i].name) {
            condition.contentsTyp = this.productGroupOptions[i].id;
            break;
          }
        }

        axios
          .get(
            "/contentspopsearch?" +
              "contentsNm=" +
              encodeURIComponent(this.escape(condition.contentsNm)) +
              "&" +
              "contentsTyp=" +
              encodeURIComponent(condition.contentsTyp) +
              "&" +
              "uploadDtStart=" +
              encodeURIComponent(condition.uploadDtStart) +
              "&" +
              "uploadDtEnd=" +
              encodeURIComponent(condition.uploadDtEnd) +
              "&" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status == 0 || response.data.status == 1) {
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;
              // this.playListItem = response.data.data;
              this.contentsList = response.data.data;

              //テーブルが5行以下の場合は、下線を表示する
              document.querySelector(".table_contents_chiled").style[
                "border-bottom"
              ] = "hidden";

              if (this.contentsList != null) {
                if (this.contentsList.length < 6) {
                  document.querySelector(".table_contents_chiled").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo2").style.visibility =
                "visible";

              // 初回検索時は端末番号の昇順
              if (this.sortItem === "contentsNm" && this.sortDirection === 0) {
                document.querySelector(".t_contents_name").innerText =
                  "コンテンツ名▲";
              }

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector(
                  "div.div-page-btnchild"
                ).style.visibility = "hidden";
                document.querySelector(".t_contents_name").innerText =
                  "コンテンツ名";
                document.querySelector(".t_contents_kind").innerText =
                  "コンテンツ種別";
                document.querySelector(".t_upload_date").innerText =
                  "アップロード日";
              } else {
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prevchild").disabled = true;
                } else {
                  document.querySelector("button.prevchild").disabled = false;
                }

                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.nextchild").disabled = true;
                } else {
                  document.querySelector("button.nextchild").disabled = false;
                }
              }

              //選択されている行と値をクリア
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
                const objectNum = 10;
                for (let count = 0; count < objectNum; count++) {
                  let objectName = "contents" + (count + 1);
                  this.$set(this.contentsList, objectName, "");
                }
              }
            }
            //その他DBエラー（構文エラー、DB接続失敗）の場合
            else if (response.data.status == 2) {
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            document.querySelector("#div_items").scrollTop = 0;
            console.log("MngPlaylistsChilde search End");
            this.axiosFlg = 0;
          });
      }
    },
    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;

      //日付のハイフンを削除
      var sourceStr1 = document.querySelector("#date_first_child").value;
      var datefirst = sourceStr1.replace(/-/g, "");
      var sourceStr2 = document.querySelector("#date_end_child").value;
      var dateend = sourceStr2.replace(/-/g, "");

      (this.currentContentsNm = document.querySelector("#contents_name").value),
        (this.currentContentsTyp = document.querySelector(
          "#contents_kind"
        ).value),
        (this.currentUploadDtStart = datefirst),
        (this.currentUploadDtEnd = dateend),
        // 検索メソッドの呼び出し
        this.search();
    },

    //プレイリスト一覧を押下時の処理
    onPlaylistChiled(row, key) {
      //決定ボタンを活性
      document.querySelector("#button2").disabled = false;
      document.querySelector("#button2").style["background-color"] = "#1a2f77";

      //前回選択されていた場合選択箇所の色を変更
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }
      //動画ファイルパスを格納
      this.selectMovie = this.contentsList[key]["contentsFile"];
      //コンテンツサイズを格納
      this.selectContentsSize = this.contentsList[key]["contentsSize"]

      //選択行の色を変更
      this.selectRow = document.querySelector(
        ".table_contents_chiled"
      ).lastElementChild.children[key];
      this.selectRow.style["background-color"] = "#0080ff20";
    },

    //決定ボタン押下時
    onDecision() {
      let viewTime = "";
      // 静止画の場合、再生時間時間にデフォルト値（30秒）を設定
      if (this.selectRow.children[1].innerText == "静止画") {
        viewTime = "30";
      }
      //親画面にコンテンツ名を渡す
      this.$emit(
        "parentMethod",
        this.selectRow.children[0].innerHTML,
        viewTime,
        this.selectMovie,
        this.selectContentsSize,
      );
    },

    //再生ボタン押下時処理
    onStartButton(row, key) {
      //trの処理を行う
      this.onPlaylistChiled(row, key);

      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        this.$emit("playMovie", this.selectMovie);
        this.axiosFlg = 0;
      }
    },

    //前ボタン押下処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },
    //次ボタン押下処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
        document.querySelectorAll("tr.selected");
      }
    },

    // ソート機能(コンテンツ名)
    sortContentsNm() {
      if (this.contentsList != null && this.contentsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "contentsNm";
        // 昇順・降順の切替
        if (this.sortFlgContentsNm == 0) {
          this.sortDirection = 1;
          this.sortFlgContentsNm = 1;
          document.querySelector(".t_contents_name").innerText =
            "コンテンツ名▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgContentsNm = 0;
          document.querySelector(".t_contents_name").innerText = "コンテンツ▲";
        }
        document.querySelector(".t_contents_kind").innerText = "コンテンツ種別";
        document.querySelector(".t_upload_date").innerText = "アップロード日";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(コンテンツ種別)
    sortContentsTyp() {
      if (this.contentsList != null && this.contentsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "contentsTyp";
        // 昇順・降順の切替
        if (this.sortFlgContentsTyp == 0) {
          this.sortDirection = 1;
          this.sortFlgContentsTyp = 1;
          document.querySelector(".t_contents_kind").innerText =
            "コンテンツ種別▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgContentsTyp = 0;
          document.querySelector(".t_contents_kind").innerText =
            "コンテンツ種別▲";
        }
        document.querySelector(".t_upload_date").innerText = "アップロード日";
        document.querySelector(".t_contents_name").innerText = "コンテンツ名";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(アップロード日)
    sortUploadDt() {
      if (this.contentsList != null && this.contentsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "uploadDt";
        // 昇順・降順の切替
        if (this.sortFlgUploadDt == 0) {
          this.sortDirection = 1;
          this.sortFlgUploadDt = 1;
          document.querySelector(".t_upload_date").innerText =
            "アップロード日▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgUploadDt = 0;
          document.querySelector(".t_upload_date").innerText =
            "アップロード日▲";
        }
        document.querySelector(".t_contents_name").innerText = "コンテンツ名";
        document.querySelector(".t_contents_kind").innerText = "コンテンツ種別";
        // 検索メソッドの呼び出し
        this.search();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*--------------------------modal------------------------------*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  height: 700px;
  margin: 0px auto;
  padding: 0px 0px 20px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  width: 100%;
  color: white;
  font-size: 36px;
  background-color: #1a2f77;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/*-------------------------------------------------------------*/
/*-------------------------common------------------------------*/

.content {
  min-width: 1200px;
  overflow: auto;
  padding: 10px 30px;
  vertical-align: top;
}

button {
  width: 120px;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  border-style: solid;
  border-width: 1px;
  table-layout: fixed;
}

.div_search_result {
  margin-left: 40px;
}

.table_contents_chiled {
  border: 0px;
  width: 692px;
}

.table_search th,
.table_search td {
  font-weight: normal;
  border-style: solid;
  padding: 10px 10px;
}

th {
  border-width: 1px;
}

td {
  border-width: 0 1px 0 0;
}

button.tab {
  background-color: white;
  border-style: solid;
  border-width: 0;
}

button.tab.selected {
  border-width: 1px;
}

.div_search_btn {
  text-align: right;
  margin-left: auto;
}

/*-------------------------------------------------------------*/
/*-------------------------thisPageStyle-----------------------*/
.div_search_result.items {
  margin-top: -1px;
  height: 303px;
  overflow-y: auto;
  width: fit-content;
  border-style: solid;
  border-width: 1px;
}
.table_search {
  border-style: hidden;
}
.table_search tr td {
  border-style: hidden;
}
.div_label-wrapper {
  display: inline-block;

  width: 117px;
}
.button1,
#button2,
.button3 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
}

button#button2 {
  background-color: gray;
  margin-right: 50px;
}

.div_search_show {
  display: flex;
  text-align: center;
}
.div_search_title {
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 30px;
}
.div_search_btn {
  margin-right: 50px;
  margin-top: 20px;
}
.pageButton {
  height: 10px;
  width: 20px;
}
.footer {
  text-align: center;
}
.header_title {
  text-align: center;
}
.page_num {
  white-space: nowrap;
}

.t_contents_name {
  width: 244px;
}

td.t_contents_name {
  width: 200px;
}

.t_contents_kind {
  width: 123px;
}

td.t_contents_kind {
  width: 99px;
}

.t_contents_prev {
  width: 63px;
}

td.t_contents_prev {
  width: 50px;
  text-align: center;
}

.t_upload_date {
  width: 218px;
}

td.t_upload_date {
  width: 180px;
}

.btn_start {
  width: 20px;
}

/*ページボタン*/
.div-page-btnchild {
  display: flex;
  justify-content: center;
}
button.prevchild {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.nextchild {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

.div_search_memo2 {
  text-align: center;
  margin-left: 320px;
  width: 200px;
  visibility: hidden; /*初期表示時は非表示*/
}

td.t_upload_date {
  border-right: 0px;
}

/*最終更新日のマージン調整*/
#date {
  margin-left: 10px;
  margin-right: 10px;
}

tr.tr_upload_date,
tr.tr_contents {
  display: flex;
}

/*ページ数のマージン調整*/
.pagechild {
  margin-top: 6px;
}
/*-------------------------------------------------------------*/
</style>