<template>
  <div>
    <div class="select">
      <div class="div_search">
        <div class="div_playlist_name">
          <div class="div_label-wrapper">
            <label>プレイリスト名</label>
          </div>
          <input
            type="text"
            class="textbox"
            id="playlist-name"
            value=""
            maxlength="30"
          />
        </div>
        <div class="div_store_name">
          <label for="chk-device-num">使用端末なし</label>
          <input type="checkbox" id="chk-device-num" class="chkbox" />
        </div>
      </div>
      <div class="div_search">
        <div class="div_last_upgrade_date">
          <div class="div_label-wrapper">
            <label>最終更新日</label>
          </div>
          <input class="date" id="date_first" type="date" max="2999-12-31" />
          <label id="date">～</label>
          <input class="date" id="date_end" type="date" max="2999-12-31" />
        </div>
      </div>
      <div class="div_search_btn btn">
        <button class="button1" @click="onClickSearch">検索</button>
      </div>
      <div class="div_search_show">
        <div class="div_search_title">
          <label class="table-title">プレイリスト一覧</label>
        </div>

        <div class="div_search_memo">
          <p>検索結果：{{ totalCount }} 件</p>
          <div>
            <div class="div-page-btn">
              <button class="prev" @click="prevPage" disabled="true">≪</button>
              <p>{{ currentPage }} / {{ totalPage }}</p>
              <button class="next" @click="nextPage">≫</button>
            </div>
          </div>
        </div>
      </div>

      <div class="div_search_result title">
        <table class="table_playlist_title">
          <thead>
            <tr>
              <th scope="col" class="t_playlist_id" @click="sortPlayId">
                プレイリストID
              </th>
              <th scope="col" class="t_playlist_name" @click="sortPlayList">
                プレイリスト名
              </th>
              <th scope="col" class="t_use_device_num" @click="sortUnitNumber">
                使用端末台数
              </th>
              <th
                scope="col"
                class="t_last_upgrade_date"
                @click="sortLastAccess"
              >
                最終更新日
              </th>
              <!-- <th scope="col" class="t_space"></th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div class="div_search_result items">
        <table class="table_playlist targetTable">
          <tbody>
            <tr
              v-for="(row, key) in playListItem"
              :key="key"
              @click="onPlaylist(row, key)"
            >
              <td class="t_playlist_id">{{ row.playlistId }}</td>
              <td class="t_playlist_name">{{ row.playlistNm }}</td>
              <td class="t_use_device_num">{{ row.unitNumber }}</td>
              <td class="t_last_upgrade_date">{{ row.lastAccess }}</td>
              <!-- <td class="t_space"></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="div_regist">
      <div class="tab_sel" btn>
        <button v-on:click="onCreate" class="create-btn">新規</button>
      </div>
      <div class="div_reg_el">
        <div class="div_playlist_name">
          <label>プレイリスト名</label>
          <input
            type="text"
            id="textbox_playlist_name2"
            disabled="disabled"
            maxlength="30"
          />
        </div>
      </div>
      <div class="div_regist">
        <div class="select_contents">
          <table class="table_border_style_hidden">
            <tbody>
              <tr v-for="index in 20" :key="index">
                <td class="td_contents_label_area">
                  <label>コンテンツ{{ index }}</label>
                </td>
                <td class="td_contents_select_area">
                  <span
                    class="contents"
                    v-on:mouseover="showEraseIcon(index)"
                    v-on:mouseleave="hideEraseIcon(index)"
                  >
                    <input
                      :id="'text_contents' + index"
                      type="text"
                      class="text_contents"
                      disabled="disabled"
                      v-model="contentsList['contents' + index]['contents']"
                    />
                    <button
                      @click="eraseContentName(index)"
                      class="eraseIcon"
                      v-bind:class="{
                        active: eraseIconIsActive[index],
                        inActive: !eraseIconIsActive[index],
                      }"
                    >
                      ✖
                    </button>
                    <button
                      @click="onSelect(index)"
                      class="btn_select"
                      disabled="true"
                    >
                      選択
                    </button>
                  </span>
                  <label id="playtime">再生時間</label>
                  <input
                    :id="'textbox_playtime' + index"
                    type="text"
                    maxlength="3"
                    oninput="value = value.replace(/[^0-9]+/i,'');"
                    class="textbox_playtime"
                    v-model="contentsList['contents' + index]['viewTime']"
                    disabled="true"
                  />
                  <label>秒</label>
                </td>
                <td class="td_btn_start">
                  <img
                    v-bind:src="startButtonImagePath"
                    v-on:click="
                      onStartButton(
                        contentsList['contents' + index]['contentsFileName']
                      )
                    "
                    class="btn_start"
                  />
                </td>
                <td class="td_contents_size">
                  <input
                    :id="'hidden_contents_size' + index"
                    type="hidden"
                    class="hidden_contents_size"
                    v-model="contentsList['contents' + index]['contentsSize']"
                    readonly
                  />
                  <label class="label_contents_size">
                    {{
                      contentsList["contents" + index]["contentsSize"]
                        | contentsSizeFormat
                    }}
                  </label>
                  <label>MB</label>
                </td>
                <td class="td_contents_label_area">
                  <label>コンテンツ{{ index + 20 }}</label>
                </td>
                <td class="td_contents_select_area">
                  <span
                    class="contents"
                    v-on:mouseover="showEraseIcon(index + 20)"
                    v-on:mouseleave="hideEraseIcon(index + 20)"
                  >
                    <input
                      :id="'text_contents' + (index + 20)"
                      type="text"
                      class="text_contents"
                      disabled="disabled"
                      v-model="
                        contentsList['contents' + (index + 20)]['contents']
                      "
                    />
                    <button
                      @click="eraseContentName(index + 20)"
                      class="eraseIcon"
                      v-bind:class="{
                        active: eraseIconIsActive[index + 20],
                        inActive: !eraseIconIsActive[index + 20],
                      }"
                    >
                      ✖
                    </button>
                    <button
                      @click="onSelect(index + 20)"
                      class="btn_select"
                      disabled="true"
                    >
                      選択
                    </button>
                  </span>
                  <label id="playtime2">再生時間</label>
                  <input
                    :id="'textbox_playtime' + (index + 20)"
                    type="text"
                    maxlength="3"
                    oninput="value = value.replace(/[^0-9]+/i,'');"
                    class="textbox_playtime"
                    v-model="
                      contentsList['contents' + (index + 20)]['viewTime']
                    "
                    disabled="true"
                  />

                  <label>秒</label>
                </td>
                <td class="td_btn_start">
                  <img
                    v-bind:src="startButtonImagePath"
                    v-on:click="
                      onStartButton(
                        contentsList['contents' + (index + 20)][
                          'contentsFileName'
                        ]
                      )
                    "
                    class="btn_start"
                  />
                </td>
                <td class="td_contents_size">
                  <input
                    :id="'hidden_contents_size' + (index + 20)"
                    type="hidden"
                    class="hidden_contents_size"
                    v-model="
                      contentsList['contents' + (index + 20)]['contentsSize']
                    "
                    readonly
                  />
                  <label class="label_contents_size">
                    {{
                      contentsList["contents" + (index + 20)]["contentsSize"]
                        | contentsSizeFormat
                    }}
                  </label>
                  <label>MB</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="div_reg_el">
        <div class="div_update_btn btn">
          <button @click="show('update')" class="button2" disabled="true">
            更新
          </button>
        </div>
        <div class="div_insert_btn btn">
          <button @click="show('register')" class="button3" disabled="true">
            登録
          </button>
        </div>
        <div class="div_delete_btn btn">
          <button @click="show('deletion')" class="button4" disabled="true">
            削除
          </button>
        </div>
      </div>
      <div>
        <testModel
          v-if="showModal"
          :modalTyp="modalTyp"
          @close="showModal = false"
          @parentMethod="updateSelectContetns"
          @playMovie="onStartButton"
        ></testModel>

        <movieModal
          ref="movie"
          :showMovieData="movieData"
          :showMovieType="movieType"
          v-if="showMovie"
          @close="showMovie = false"
        ></movieModal>
        <pictureModal
          v-if="showPicture"
          v-bind:showPictureData="this.pictureData"
          @close="showPicture = false"
        ></pictureModal>
        <LoadingModal
          v-if="showLoading"
          @close="showLoading = false"
        ></LoadingModal>
      </div>
      <ModalWindow
        ref="modal"
        :modaltyp="modalTyp"
        @update="update"
        @register="register"
        @deletion="deletion"
      ></ModalWindow>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import testModel from "./MngPlaylistsChilde";
import movieModal from "./MovieModal";
import pictureModal from "./PictureModal";
import ModalWindow from "./ModalWindow";
import LoadingModal from "./LoadingModal";
import He from "he";

export default {
  components: {
    testModel,
    movieModal,
    pictureModal,
    ModalWindow,
    LoadingModal,
  },

  filters: {
    contentsSizeFormat: function (size) {
      let byte = Number(size);

      // 入力値が0以下の場合は0を返却する
      if (isNaN(byte) || byte <= 0) {
        return 0;
      }

      // 小数点第１位を残して切り上げ
      let mbyte = Math.ceil((byte / 1024 / 1024) * 10) / 10;

      if (mbyte < 10) {
        // 9.9以下の場合はそのまま返却
        return mbyte;
      } else {
        // 10以上の場合は小数点以下を切り上げて返却
        return Math.ceil(mbyte);
      }
    },
  },

  created: function () {
    console.log("MngPlaylist is opened.");
    axios
      .get("/playlistmaxsize", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.status == 0) {
          this.playlistMaxSize = response.data.size;
        }
        // その他のエラー
        else {
          // エラーポップアップを表示「予期せぬエラーが発生しました。」
          this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          return;
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status === 401) {
          return;
        }
        // エラーポップアップを表示「予期せぬエラーが発生しました。」
        this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
        console.log(error);
      });
  },

  data() {
    return {
      showModal: false,
      startButtonImagePath: require("@/assets/png/startButton.png"),
      totalCount: 0,
      requestPage: 1,
      currentPage: 1,
      totalPage: 1,
      showMovie: false,
      playListItem: [],
      contentsList: {
        contents1: {},
        contents2: {},
        contents3: {},
        contents4: {},
        contents5: {},
        contents6: {},
        contents7: {},
        contents8: {},
        contents9: {},
        contents10: {},
        contents11: {},
        contents12: {},
        contents13: {},
        contents14: {},
        contents15: {},
        contents16: {},
        contents17: {},
        contents18: {},
        contents19: {},
        contents20: {},
        contents21: {},
        contents22: {},
        contents23: {},
        contents24: {},
        contents25: {},
        contents26: {},
        contents27: {},
        contents28: {},
        contents29: {},
        contents30: {},
        contents31: {},
        contents32: {},
        contents33: {},
        contents34: {},
        contents35: {},
        contents36: {},
        contents37: {},
        contents38: {},
        contents39: {},
        contents40: {},
      },
      List: [],
      selectRow: null, //選択された行
      selectedPlaylistId: null, //選択されたプレイリストID
      selectContentsName: null, //選択ボタンのテキストボックス
      selectContentsNo: null, //選択されたボタンNo
      sortItem: "playlistId", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgPlaylistId: 0, // ソートフラグ(プレイリストID)
      sortFlgplayList: 0, // ソートフラグ(プレイリスト名)
      sortFlgunitCheck: 0, // ソートフラグ(使用端末台数)
      sortFlglastAccess: 0, // ソートフラグ(最終更新日)
      modalTyp: "", //ポップアップ種別
      movieData: null, //動画データ
      movieType: null, //動画コンテンツタイプ
      showLoading: null, //ローディング画面
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentPlaylistNm: "", // 検索プレイリスト名保持用
      currentUnitCheck: "", // 検索チェックボックス保持用
      currentLastAccessStart: "", // 検索最終更新開始日保持用
      currentLastAccessEnd: "", // 検索最終更新終了日保持用
      showPicture: false, //画像表示
      pictureData: null, //画像データ
      blob: null, //動画または写真のbinaryデータをラップしたもの
      eraseIconIsActive: [],
      playlistMaxSize: 1024 * 10, // 最大プレイリストサイズ
    };
  },

  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    created() {
      for (let i = 1; i < 11; i++) {
        this.eraseIconIsActive[i] = false;
      }
    },
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;

        const condition = {
          playlistNm: this.currentPlaylistNm,
          unitCheck: this.currentUnitCheck,
          lastAccessStart: this.currentLastAccessStart,
          lastAccessEnd: this.currentLastAccessEnd,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        axios
          .get(
            "/playlistsearch?" +
              "playlistNm=" +
              encodeURIComponent(this.escape(condition.playlistNm)) +
              "&" +
              "unitCheck=" +
              encodeURIComponent(condition.unitCheck) +
              "&" +
              "lastAccessStart=" +
              encodeURIComponent(condition.lastAccessStart) +
              "&" +
              "lastAccessEnd=" +
              encodeURIComponent(condition.lastAccessEnd) +
              "&" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            // 初回検索時はプレイリストIDの昇順
            if (this.sortItem === "playlistId" && this.sortDirection === 0) {
              document.querySelector(".t_playlist_id").innerText =
                "プレイリストID▲";
            }

            // 検索結果、ページ遷移ボタンの表示
            document.querySelector("div.div_search_memo").style.visibility =
              "visible";
            document.querySelector("div.div-page-btn").style.visibility =
              "visible";

            // if (this.playListItem != null && this.playListItem.length > 0) {
            //   document.querySelector(".targetTable").style.width = "auto";
            // } else {
            //   document.querySelector(".targetTable").style.width = "1184px";
            // }

            //0:正常、1:検索結果0件
            if (response.data.status == 0 || response.data.status == 1) {
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;
              this.playListItem = response.data.data;

              //テーブルが6行以下の場合は、下線を表示する
              document.querySelector(".table_playlist").style["border-bottom"] =
                "hidden";

              if (this.playListItem != null) {
                if (this.playListItem.length < 7) {
                  document.querySelector(".table_playlist").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";

              // 初回検索時はプレイリスト名の昇順
              if (this.sortItem === "playlistId" && this.sortDirection === 0) {
                document.querySelector(".t_playlist_id").innerText =
                  "プレイリストID▲";
              }

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector(".t_playlist_id").innerText =
                  "プレイリストID";
                document.querySelector(".t_playlist_name").innerText =
                  "プレイリスト名";
                document.querySelector(".t_use_device_num").innerText =
                  "使用端末台数";
                document.querySelector(".t_last_upgrade_date").innerText =
                  "最終更新日";
              }

              if (
                response.data.currentPage === 1 ||
                response.data.totalPage < 2
              ) {
                document.querySelector("button.prev").disabled = true;
              } else {
                document.querySelector("button.prev").disabled = false;
              }

              if (
                response.data.currentPage === response.data.totalPage ||
                response.data.totalPage < 2
              ) {
                document.querySelector("button.next").disabled = true;
              } else {
                document.querySelector("button.next").disabled = false;
              }

              //選択されている行と値をクリア
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
                const objectNum = 40;
                for (let count = 0; count < objectNum; count++) {
                  let objectName = "data" + (count + 1);
                  this.$set(this.contentsList, objectName, "");
                }
              }
            }
            //2:その他DBエラー（構文エラー、DB接続失敗）
            else if (response.data.status == 2) {
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            document.querySelector(".div_search_result.items").scrollTop = 0;
            console.log("MngPlaylists search End");
            this.axiosFlg = 0;
          });
      }
    },
    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;

      //日付のハイフンを削除
      var sourceStr1 = document.querySelector("#date_first").value;
      var datefirst = sourceStr1.replace(/-/g, "");
      var sourceStr2 = document.querySelector("#date_end").value;
      var dateend = sourceStr2.replace(/-/g, "");

      this.currentPlaylistNm = document.querySelector("#playlist-name").value;
      this.currentUnitCheck = document.querySelector("#chk-device-num").checked
        ? 1
        : 0;
      this.currentLastAccessStart = datefirst;
      this.currentLastAccessEnd = dateend;

      // 検索メソッドの呼び出し
      this.search();
    },
    //戻るボタン押下時の処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },
    //進むボタン押下時の処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
      }
    },
    //プレイリスト一覧を押下時の処理
    onPlaylist(row, key) {
      //登録ボタンを非活性化
      document.querySelector(".button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      //更新ボタン、削除ボタンを活性化
      document.querySelector(".button2").disabled = false;
      document.querySelector(".button2").style["background-color"] = "#1a2f77";
      document.querySelector(".button4").disabled = false;
      document.querySelector(".button4").style["background-color"] = "#1a2f77";
      //テキストボックスを非活性化
      document.querySelector("#textbox_playlist_name2").disabled = true;
      //選択されたプレイリストの内容をテキストボックスに反映
      document.querySelector("#textbox_playlist_name2").value = row.playlistNm;
      //選択ボタンを活性化
      let selectButton = document.querySelectorAll(".btn_select");
      for (var i = 0; i < selectButton.length; i++) {
        selectButton[i].disabled = false;
      }
      //再生時間テキストボックスを活性化
      let playlistTextBox = document.querySelectorAll(".textbox_playtime");
      for (i = 0; i < playlistTextBox.length; i++) {
        playlistTextBox[i].disabled = false;
      }
      // 選択されたプレイリストIDを保持
      this.selectedPlaylistId = row.playlistId;

      //コンテンツリストを取得
      axios
        .get("/playlistchoice/" + row.playlistId, {
          headers: {
            Authorization: `Bearer ${this.idToken}`,
          },
        })
        .then((response) => {
          //0:正常
          if (response.data.status == 0) {
            this.List = response.data.data;

            //コンテンツファイル名、再生時間の設定
            const objectNum = 40;

            //初期化
            for (let count = 0; count < objectNum; count++) {
              let objectName = "contents" + (count + 1);
              this.$set(this.contentsList, objectName, "");
            }

            for (let count = 0; count < this.List.length; count++) {
              let objectName = "contents" + this.List[count]["viewNumber"];
              this.$set(this.contentsList, objectName, this.List[count]);
            }
          }
          //1:DBエラー（構文エラー、DB接続失敗など）
          else if (response.data.status == 1) {
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          }
          //2:存在しないデータ
          else if (response.data.status == 2) {
            //エラーポップアップを表示
            this.$refs.modal.modalError(),
              (this.modalTyp = "すでに存在しないデータです。");
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data.status === 401) {
            return;
          }
          // エラーポップアップを表示「予期せぬエラーが発生しました。」
          this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
        });

      //前回選択されていた場合選択箇所の色を変更
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }
      //選択行の色を変更
      this.selectRow =
        document.querySelector(".table_playlist").lastElementChild.children[
          key
        ];
      this.selectRow.style["background-color"] = "#0080ff20";
    },
    //新規ボタン押下時の処理
    onCreate() {
      //プレイリストに選択行があった場合、選択を外す。
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      //選択ボタンを活性化
      let selectButton = document.querySelectorAll(".btn_select");
      for (var i = 0; i < selectButton.length; i++) {
        selectButton[i].disabled = false;
      }
      //再生時間テキストボックスを活性化
      let playlistTextBox = document.querySelectorAll(".textbox_playtime");
      for (i = 0; i < playlistTextBox.length; i++) {
        playlistTextBox[i].disabled = false;
      }
      //登録ボタンを活性化
      document.querySelector(".button3").disabled = false;
      document.querySelector(".button3").style["background-color"] = "#1a2f77";
      //更新ボタン、削除ボタンを非活性化
      document.querySelector(".button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      document.querySelector(".button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";
      //テキストボックスを活性化
      document.querySelector("#textbox_playlist_name2").disabled = false;

      //プレイリスト名、コンテンツ、再生時間をクリア
      document.querySelector("#textbox_playlist_name2").value = "";

      this.contentsList = {
        contents1: {},
        contents2: {},
        contents3: {},
        contents4: {},
        contents5: {},
        contents6: {},
        contents7: {},
        contents8: {},
        contents9: {},
        contents10: {},
        contents11: {},
        contents12: {},
        contents13: {},
        contents14: {},
        contents15: {},
        contents16: {},
        contents17: {},
        contents18: {},
        contents19: {},
        contents20: {},
        contents21: {},
        contents22: {},
        contents23: {},
        contents24: {},
        contents25: {},
        contents26: {},
        contents27: {},
        contents28: {},
        contents29: {},
        contents30: {},
        contents31: {},
        contents32: {},
        contents33: {},
        contents34: {},
        contents35: {},
        contents36: {},
        contents37: {},
        contents38: {},
        contents39: {},
        contents40: {},
      };
    },
    onSelect(ButtonNo) {
      //選択ボタンのコンテンツ
      this.selectContentsName = "contents" + ButtonNo;
      this.selectContentsNo = ButtonNo;
      //コンテンツ選択モーダルウィンドウを表示
      this.showModal = true;
    },

    //再生ボタン押下時処理
    onStartButton(fileName) {
      console.log("MngPlaylists onStartButton Start");
      if (
        typeof fileName !== "undefined" &&
        fileName != null &&
        fileName != ""
      ) {
        if (this.axiosFlg === 0) {
          this.axiosFlg = 1;
          //ローディング画面 開始
          this.showLoading = true;
          //コンテンツリストを取得
          const condition = {
            contents_file: fileName,
          };
          axios
            .create({
              responseType: "arraybuffer",
            })
            .get("/contentspreview/" + condition.contents_file, {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            })
            .then((response) => {
              console.log(response);
              var contents = response.data;
              var unit8 = new Uint8Array(contents);

              if (contents.byteLength == 1 && unit8[0] == 1) {
                // エラーポップアップを表示「すでに存在しないデータです。」
                this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
                console.log("MngPlaylists onStartButton fauld");
              } else {
                //コンテンツタイプを取得
                let videoOrPicContentType = response.headers["content-type"];

                //動画ポップアップ表示
                if (
                  videoOrPicContentType == "video/mp4" ||
                  videoOrPicContentType == "video/webm"
                ) {
                  let blob = new Blob([contents], {
                    type: videoOrPicContentType,
                  });
                  this.movieData = window.URL.createObjectURL(blob);
                  this.movieType = videoOrPicContentType;
                  console.log("the movie is started");
                  this.showMovie = true;
                }
                //画像ポップアップ表示
                else if (
                  videoOrPicContentType == "image/bmp" ||
                  videoOrPicContentType == "image/gif" ||
                  videoOrPicContentType == "image/jpeg" ||
                  videoOrPicContentType == "image/png"
                ) {
                  this.blob = new Blob([contents], {
                    type: videoOrPicContentType,
                  });
                  this.pictureData = window.URL.createObjectURL(this.blob);
                  console.log("the picture is started");
                  this.showPicture = true;
                } else {
                  //error処理
                  throw "このデータの拡張子は対応していません。";
                }
                console.log("MngPlaylists onStartButton Success");
              }
            })
            .catch((error) => {
              console.log(error);
              if (error.response && error.response.data.status === 401) {
                return;
              }
              // エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              console.log("MngPlaylists onStartButton fauld");
            })
            .finally(() => {
              console.log("MngPlaylists onStartButton End");
              this.axiosFlg = 0;
              //ローディング画面 終了
              this.showLoading = false;
            });
        }
      }
    },

    //更新ボタン押下時の処理
    update() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        //送信用プレイリスト作成
        const sendPlaylist = [];
        const objectNum = 40;
        //var modalFlg = 0; //コンテンツ未入力モーダル用フラグ

        let playlistSize = 0;
        for (let count = 0; count < objectNum; count++) {
          let objectName = "contents" + (count + 1);

          //コンテンツ未入力エラー判定
          if (typeof this.contentsList[objectName]["contents"] != "undefined") {
            // modalFlg = 1;
            //プレイリスト作成
            sendPlaylist.push({
              viewNumber: count + 1,
              contents: this.contentsList[objectName]["contents"],
              viewTime: this.contentsList[objectName]["viewTime"],
              contentsFileName:
                this.contentsList[objectName]["contentsFileName"],
            });
            // 合計コンテンツサイズを計算
            playlistSize += this.contentsList[objectName]["contentsSize"];
          }
        }

        // コンテンツ未選択時警告処理
        if (sendPlaylist.length == 0) {
          console.log("MngPlaylists: no select contents.");
          //エラーポップアップを表示「コンテンツが未入力です。」
          this.$refs.modal.modalError(),
            (this.modalTyp = "コンテンツが未入力です。");
          this.axiosFlg = 0;
          return;
        }

        // アップロードコンテンツサイズ最大サイズオーバー警告処理
        if (playlistSize > this.playlistMaxSize * 1024 * 1024) {
          console.log("MngPlaylists: over Maximum playlist size.");
          //エラーポップアップを表示「MBを超えるコンテンツはアップロード出来ません。」
          this.$refs.modal.modalError(),
            (this.modalTyp =
              this.playlistMaxSize +
              "MBを超えるコンテンツはアップロード出来ません。");
          this.axiosFlg = 0;
          return;
        }

        const condition = {
          playlistNm: document.querySelector("#textbox_playlist_name2").value,
          playlistId: this.selectedPlaylistId,
          data: sendPlaylist,
        };

        let searchFlag = false;
        axios
          .post("/playlistup", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngPlaylists update Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngPlaylists update ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 2) {
              console.log("MngPlaylists update ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("MngPlaylists update End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },
    //登録ボタン押下時の処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        //送信用プレイリスト作成
        const sendPlaylist = [];
        const objectNum = 40;
        //var modalFlg = 0; //コンテンツ未入力モーダル用フラグ

        //プレイリスト名が未入力の場合、エラー
        if (document.querySelector("#textbox_playlist_name2").value == "") {
          this.axiosFlg = 0;
          //プレイリスト名未入力エラー
          this.$refs.modal.modalError(),
            (this.modalTyp = "プレイリスト名が未入力です。");
          this.axiosFlg = 0;
          return;
        }

        let playlistSize = 0;
        for (let count = 0; count < objectNum; count++) {
          let objectName = "contents" + (count + 1);

          this.axiosFlg = 0;
          //コンテンツ未入力エラー判定
          console.log(this.contentsList[objectName]["contents"]);
          if (typeof this.contentsList[objectName]["contents"] != "undefined") {
            // modalFlg = 1;
            //プレイリスト作成
            sendPlaylist.push({
              viewNumber: count + 1,
              contents: this.contentsList[objectName]["contents"],
              viewTime: this.contentsList[objectName]["viewTime"],
              contentsFileName:
                this.contentsList[objectName]["contentsFileName"],
            });
            // 合計コンテンツサイズを計算
            playlistSize += this.contentsList[objectName]["contentsSize"];
          }
        }

        // コンテンツ未選択時警告処理
        if (sendPlaylist.length == 0) {
          console.log("MngPlaylists: no select contents.");
          //エラーポップアップを表示「コンテンツが未入力です。」
          this.$refs.modal.modalError(),
            (this.modalTyp = "コンテンツが未入力です。");
          this.axiosFlg = 0;
          return;
        }

        // アップロードコンテンツサイズ最大サイズオーバー警告処理
        if (playlistSize > this.playlistMaxSize * 1024 * 1024) {
          console.log("MngPlaylists: over Maximum playlist size.");
          //エラーポップアップを表示「MBを超えるコンテンツはアップロード出来ません。」
          this.$refs.modal.modalError(),
            (this.modalTyp =
              this.playlistMaxSize +
              "MBを超えるコンテンツはアップロード出来ません。");
          this.axiosFlg = 0;
          return;
        }

        const condition = {
          playlistNm: document.querySelector("#textbox_playlist_name2").value,
          data: sendPlaylist,
        };

        let searchFlag = false;
        axios
          .post("/playlistin", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              console.log("MngPlaylists register Success");
              // 画面の初期化
              this.clearDisp();
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            //該当のプレイリストが存在する場合
            if (response.data.status == 1) {
              console.log("MngPlaylists register ERROR1");
              //エラーポップアップを表示
              this.$refs.modal.modalError(),
                (this.modalTyp = "すでに登録されているプレイリスト名です。");
            }
            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngPlaylists register ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("MngPlaylists register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },
    //削除ボタン押下時の処理
    deletion() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        const condition = {
          // playlistId: document.querySelector("#select_playlist_name").value,
          playlistId: this.selectedPlaylistId,
        };

        let searchFlag = false;
        axios
          .post("/playlistdel", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngPlaylists deletion Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngPlaylists deletion ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 2) {
              console.log("MngPlaylists deletion ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("MngPlaylists deletion End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //ダイアログから呼び出す関数
    updateSelectContetns(
      contentName,
      viewTime,
      contentsFileName,
      contentsSize
    ) {
      //コンテンツのテキストボックスの値を更新
      contentName = He.decode(contentName);
      const content = {
        contents: contentName,
        viewTime: viewTime,
        contentsFileName: contentsFileName,
        contentsSize: contentsSize,
      };
      // コンテンツ名設定
      this.$set(this.contentsList, this.selectContentsName, content);

      // 再生時間が空の場合、再生時間テキストボックスを非活性化
      if (viewTime == "") {
        document.getElementById(
          "textbox_playtime" + this.selectContentsNo
        ).disabled = true;
      } else {
        document.getElementById(
          "textbox_playtime" + this.selectContentsNo
        ).disabled = false;
      }
      //ダイアログを閉じる
      this.showModal = false;
    },

    //ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },

    // ソート機能(プレイリストID)
    sortPlayId() {
      if (this.playListItem != null && this.playListItem.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistId";
        // 昇順・降順の切替
        if (this.sortFlgplayListId == 0) {
          this.sortDirection = 1;
          this.sortFlgplayListId = 1;
          document.querySelector(".t_playlist_id").innerText =
            "プレイリストID▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgplayListId = 0;
          document.querySelector(".t_playlist_id").innerText =
            "プレイリストID▲";
        }
        document.querySelector(".t_playlist_name").innerText = "プレイリスト名";
        document.querySelector(".t_use_device_num").innerText = "使用端末台数";
        document.querySelector(".t_last_upgrade_date").innerText = "最終更新日";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(プレイリスト名)
    sortPlayList() {
      if (this.playListItem != null && this.playListItem.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistNm";
        // 昇順・降順の切替
        if (this.sortFlgplayList == 0) {
          this.sortDirection = 1;
          this.sortFlgplayList = 1;
          document.querySelector(".t_playlist_name").innerText =
            "プレイリスト名▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgplayList = 0;
          document.querySelector(".t_playlist_name").innerText =
            "プレイリスト名▲";
        }
        document.querySelector(".t_playlist_id").innerText = "プレイリストID";
        document.querySelector(".t_use_device_num").innerText = "使用端末台数";
        document.querySelector(".t_last_upgrade_date").innerText = "最終更新日";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(使用端末台数名)
    sortUnitNumber() {
      if (this.playListItem != null && this.playListItem.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "unitNumber";
        // 昇順・降順の切替
        if (this.sortFlgunitCheck == 0) {
          this.sortDirection = 1;
          this.sortFlgunitCheck = 1;
          document.querySelector(".t_use_device_num").innerText =
            "使用端末台数▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgunitCheck = 0;
          document.querySelector(".t_use_device_num").innerText =
            "使用端末台数▲";
        }
        document.querySelector(".t_playlist_id").innerText = "プレイリストID";
        document.querySelector(".t_playlist_name").innerText = "プレイリスト名";
        document.querySelector(".t_last_upgrade_date").innerText = "最終更新日";

        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // ソート機能(最終更新日)
    sortLastAccess() {
      if (this.playListItem != null && this.playListItem.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "lastAccess";
        // 昇順・降順の切替
        if (this.sortFlglastAccess == 0) {
          this.sortDirection = 1;
          this.sortFlglastAccess = 1;
          document.querySelector(".t_last_upgrade_date").innerText =
            "最終更新日▼";
        } else {
          this.sortDirection = 0;
          this.sortFlglastAccess = 0;
          document.querySelector(".t_last_upgrade_date").innerText =
            "最終更新日▲";
        }
        document.querySelector(".t_playlist_id").innerText = "プレイリストID";
        document.querySelector(".t_playlist_name").innerText = "プレイリスト名";
        document.querySelector(".t_use_device_num").innerText = "使用端末台数";
        // 検索メソッドの呼び出し
        this.search();
      }
    },

    // 画面の初期化処理
    clearDisp() {
      this.playListItem = [];
      this.contentsList = {
        contents1: {},
        contents2: {},
        contents3: {},
        contents4: {},
        contents5: {},
        contents6: {},
        contents7: {},
        contents8: {},
        contents9: {},
        contents10: {},
        contents11: {},
        contents12: {},
        contents13: {},
        contents14: {},
        contents15: {},
        contents16: {},
        contents17: {},
        contents18: {},
        contents19: {},
        contents20: {},
        contents21: {},
        contents22: {},
        contents23: {},
        contents24: {},
        contents25: {},
        contents26: {},
        contents27: {},
        contents28: {},
        contents29: {},
        contents30: {},
        contents31: {},
        contents32: {},
        contents33: {},
        contents34: {},
        contents35: {},
        contents36: {},
        contents37: {},
        contents38: {},
        contents39: {},
        contents40: {},
      };

      // 検索条件は初期化対象外
      //入力値をクリア
      // document.querySelector("#playlist-name").value = "";
      // document.querySelector("#chk-device-num").checked = false;
      // document.querySelector("#date_first").value = "";
      // document.querySelector("#date_end").value = "";

      //プレイリスト名、コンテンツ、再生時間をクリア
      document.querySelector("#textbox_playlist_name2").value = "";
      document.querySelector("#textbox_playlist_name2").disabled = true;

      // 検索結果、ページ遷移ボタンの非表示
      document.querySelector("div.div_search_memo").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";

      // ソートフラグの初期化
      this.sortFlgplayListId = 0;
      this.sortFlgplayList = 0;
      this.sortFlgunitCheck = 0;
      this.sortFlglastAccess = 0;

      //プレイリストに選択行があった場合、選択を外す。
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      // テーブルヘッダの初期化
      document.querySelector(".t_last_upgrade_date").innerText = "最終更新日";
      document.querySelector(".t_playlist_id").innerText = "プレイリストID";
      document.querySelector(".t_playlist_name").innerText = "プレイリスト名";
      document.querySelector(".t_use_device_num").innerText = "使用端末台数";

      // 更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";
      //選択ボタンを非活性化
      let selectButton = document.querySelectorAll(".btn_select");
      for (var i = 0; i < selectButton.length; i++) {
        selectButton[i].disabled = true;
      }
      //再生時間テキストボックスを活性化
      let playlistTextBox = document.querySelectorAll(".textbox_playtime");
      for (i = 0; i < playlistTextBox.length; i++) {
        playlistTextBox[i].disabled = true;
      }
    },

    // コンテンツ名消去アイコンを表示する
    showEraseIcon(index) {
      const val = this.contentsList["contents" + index]["contents"];
      if (typeof val !== "undefined") {
        this.$set(this.eraseIconIsActive, index, true);
      }
    },

    // コンテンツ名消去アイコンを隠す
    hideEraseIcon(index) {
      this.$set(this.eraseIconIsActive, index, false);
    },

    // コンテンツ名を消去する
    eraseContentName(index) {
      const objectName = "contents" + index;
      this.$set(this.contentsList, objectName, "");
      // 再生時間テキストボックスを活性化
      document.getElementById("textbox_playtime" + index).disabled = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*-----------------common---------------------------------------------*/

.content {
  min-width: 1200px;
  padding: 10px 30px;
  vertical-align: top;
}

button {
  width: 120px;
}

button.tab {
  background-color: white;
  border-style: solid;
  border-width: 0;
}

button.tab.selected {
  border-width: 1px;
}
/*---------------------------------------------------------*/

body {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

/*-----------入力フィールド-----------*/
.div_search,
.div_reg_el {
  display: flex;
  padding: 15px 0 0 0;
}

.div_playlist_name {
  width: 460px;
  display: inline-block;
}
.div_store_name {
  width: 470px;
  display: inline-block;
  margin-top: 5px;
}
.div_last_upgrade_date {
  width: 600px;
  display: inline-block;
}

.div_label-wrapper {
  display: inline-block;
  width: 140px;
}

.textbox {
  width: 200px;
}
.text_contents {
  width: 170px;
}

input[type="text"] {
  height: 23px;
}
/*最終更新日のマージン調整*/
#date {
  margin-left: 10px;
  margin-right: 10px;
}
/*最終更新日*/
input[id="date_first"],
input[id="date_end"] {
  width: 200px;
  height: 27px;
}

.div_search_title {
  padding-top: 10px;
}
/*-----------------------------------*/
/*-------------ボタン-----------------*/
.div_search_show {
  display: flex;
}
.pageButton {
  height: 10px;
  width: 20px;
}
.page_num {
  white-space: nowrap;
}

.div_search_memo {
  text-align: center;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
  width: 150px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.button3,
button.button2,
button.button4 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}
button.create-btn {
  width: 70px;
  border-radius: 6px;
}
.btn_select {
  vertical-align: middle;
  height: 26px;
  width: 50px;
  font-size: 12px;
}
.div_search_btn {
  text-align: right;
  margin-left: auto;
  margin-top: -30px;
}

/*コンテンツ欄（8行スクロール）*/
.select_contents {
  height: 255px;
  overflow-y: scroll;
}

/*-----------------------------------*/
/*--------------一覧-----------------*/

.div_search_result.items {
  margin-top: -1px;
  overflow-y: auto;
  overflow-x: hidden;
  border-style: solid;
  border-width: 1px;
  height: 165px;
  width: auto;
}

.div_regist {
  margin-top: 10px;
}

.div_regist .div_reg_el:nth-child(4) {
  display: flex;
  justify-content: flex-end;
}

.div_update_btn,
.div_insert_btn,
.div_delete_btn {
  margin-right: 20px;
}

.t_playlist_id {
  width: 150px;
}
.t_playlist_name {
  width: 550px;
}
.t_use_device_num {
  width: 200px;
}
.t_last_upgrade_date {
  width: 250px;
}
td.t_last_upgrade_date {
  border-right: 0px;
}

#textbox_playlist_name2 {
  height: 23px;
  width: 300px;
  margin-left: 34px;
}
.textbox_playtime {
  width: 35px;
  height: 23px;
  margin-right: 3px;
}
.btn_start {
  width: 20px;
}
.td_btn_start {
  padding: 5px 0px 0px 0px;
}
.label_contents_size {
  display: inline-block;
  text-align: right;
  width: 30px;
  margin-right: 2px;
}
/* .td_contents_select_area {
  padding-left: 30px;
  padding-right: 10px;
} */
.td_contents_select_area {
  padding-left: 10px;
  padding-right: 5px;
}

.table_border_style_hidden tr td {
  border-style: hidden;
}

tr.selected {
  background-color: #0080ff20;
}

tr.selected td {
  border-top-color: #0055ff59;
  border-bottom-color: #0055ff59;
}
/*-----------------------------------*/
/*ページボタン*/
.div-page-btn {
  display: flex;
  justify-content: center;
}
button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

/*再生時間のマージン調整*/
#playtime,
#playtime2,
.btn_start {
  margin-left: 8px;
  margin-right: 3px;
}

#chk-device-num {
  margin-left: 20px;
  transform: scale(1.6);
}

.eraseIcon {
  width: 40px;
  border: none;
  background: transparent;
  margin-left: -40px;
  font-size: 16px;
  font-weight: bold;
  outline: none;
}

.active {
  display: inline;
}

.inActive {
  display: none;
}
</style>
