<template>
  <div>
    <modal
      name="modal-content"
      :clickToClose="false"
      width="500px"
      height="200px"
    >
      <div class="modal-body">
        <p v-if="this.modaltyp === 'update'">更新しますか？</p>
        <p v-else-if="this.modaltyp === 'register'">登録しますか？</p>
        <p v-else-if="this.modaltyp === 'deletion'">削除しますか？</p>
        <p v-else-if="this.modaltyp === 'csvinput'">
          CSVファイルを取り込みますか？
        </p>
        <p v-else-if="this.modaltyp === 'csvoutput'">
          CSVファイルを出力しますか？
        </p>
      </div>
      <div class="div-reg-el1">
        <button class="button1" @click="modalContentHide">キャンセル</button>
        <button class="button2" @click="show">OK</button>
      </div>
    </modal>

    <modal
      name="modal-completioncontent"
      :clickToClose="false"
      width="500px"
      height="200px"
    >
      <div class="modal-body">
        <!--<p v-if="this.modaltyp === 'update'">更新が完了しました。</p>
        <p v-else-if="this.modaltyp === 'register'">登録が完了しました。</p>
        <p v-else-if="this.modaltyp === 'deletion'">削除が完了しました。</p>-->
        <p>処理が完了しました。</p>
      </div>
      <div class="div-reg-el2">
        <button class="button3" @click="modalCompletionHide">閉じる</button>
      </div>
    </modal>

    <modal
      name="modal-errorcontent"
      :clickToClose="false"
      width="500px"
      height="200px"
    >
      <div class="modal-body">
        <p><span v-html="modaltyp"></span></p>
      </div>

      <div class="div-reg-el3">
        <button class="button4" @click="modalErrorHide">閉じる</button>
      </div>
    </modal>

    <modal
      name="modal-common"
      :clickToClose="false"
      width="500px"
      height="200px"
    >
      <div class="modal-body">
        <p v-if="this.modaltyp === 'common01'">
          有効でない文字が入力されています。
        </p>
        <p v-else-if="this.modaltyp === 'common02'">
          該当のデータが見つかりませんでした。
        </p>
        <p v-else-if="this.modaltyp === 'common03'">
          予期せぬエラーが発生しました。
        </p>
        <p v-else-if="this.modaltyp === 'common04'">
          すでに登録されているデータです。
        </p>
        <p v-else-if="this.modaltyp === 'common05'">
          すでに存在しないデータです。
        </p>
      </div>
      <div class="div-reg-el4">
        <button class="button5" @click="modalCommonHide">閉じる</button>
      </div>
    </modal>

    <modal
      name="modal-upload"
      :clickToClose="false"
      width="500px"
      height="200px"
    >
      <div class="modal-body">
        <p>アップロード中です</p>
        <p>しばらくお待ちください</p>
      </div>
    </modal>
  </div>
</template>>


<script>
export default {
  props: ["modaltyp"], //ポップアップ種別
  data() {
    return {};
  },
  methods: {
    //表示処理
    //確認ポップアップ表示
    modalContent() {
      this.$modal.show("modal-content");
    },
    //確認ポップアップOKボタン押下後処理
    show() {
      this.$modal.hide("modal-content");

      if (this.modaltyp == "update") {
        //親の更新メソッドを実行
        this.$emit("update");
      } else if (this.modaltyp == "register") {
        //親の登録メソッドを実行
        this.$emit("register");
      } else if (this.modaltyp == "deletion") {
        //親の削除メソッドを実行
        this.$emit("deletion");
      } else if (this.modaltyp == "csvinput") {
        //親の取込メソッドを実行
        this.$emit("csvinput");
      } else if (this.modaltyp == "csvoutput") {
        //親の出力メソッドを実行
        this.$emit("csvoutput");
      }
    },
    //完了ポップアップ表示
    modalCompletion() {
      this.$modal.show("modal-completioncontent");
    },
    //エラーポップアップ表示
    modalError() {
      this.$modal.show("modal-errorcontent");
    },
    //共通ポップアップ表示
    modalCommon() {
      this.$modal.show("modal-common");
    },

    //非表示処理
    //確認ポップアップ非表示
    modalContentHide() {
      this.$modal.hide("modal-content");
    },
    //完了ポップアップ非表示
    modalCompletionHide() {
      this.$modal.hide("modal-completioncontent");
    },
    //エラーポップアップ非表示
    modalErrorHide() {
      this.$modal.hide("modal-errorcontent");
    },
    //共通ポップアップ非表示
    modalCommonHide() {
      this.$modal.hide("modal-common");
    },
    //アップロード中ポップアップ非表示
    //共通ポップアップ非表示
    modalUploadHide() {
      this.$modal.hide("modal-upload");
    },
  },
};
</script>   


<style scoped>
/*ポップアップ*/
.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 400px;
  text-align: center;
}

.div-reg-el1 {
  position: absolute;
  top: 150px;
  left: 130px;
}

.div-reg-el2,
.div-reg-el3,
.div-reg-el4 {
  position: absolute;
  top: 150px;
  left: 185px;
}

/*確認ポップアップボタン*/
button.button1,
button.button2 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  height: 30.98px;
  margin-right: 20px;
}

/*完了、エラー、共通ポップアップボタン*/
button.button3,
button.button4,
button.button5 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  height: 30.98px;
}
</style>