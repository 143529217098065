<template>
  <div>
    <div class="select">
      <div class="div_search">
        <div class="div_product_grp_nm">
          <div class="div_label-wrapper">
            <label>商品グループ名</label>
          </div>
          <input type="text" id="products-name" maxlength="20" />
        </div>
      </div>
      <div class="div_search_btn btn">
        <button class="button1" @click="onClickSearch">検索</button>
      </div>

      <div class="div_search_detail">
        <div class="div_search_show">
          <label class="table-title">商品グループ一覧</label>
        </div>
        <div class="div_search_memo">
          <p>検索結果：{{ totalCount }} 件</p>
          <div class="div-page-btn">
            <button class="prev" @click="prevPage">≪</button>
            <p>{{ currentPage }} / {{ totalPage }}</p>
            <button class="next" @click="nextPage">≫</button>
          </div>
        </div>
      </div>

      <div class="div_search_result title">
        <table>
          <thead>
            <tr>
              <th
                scope="col"
                id="sortProdId"
                @click="sortProdId"
                class="product_grp_id"
              >
                商品グループID
              </th>
              <th
                scope="col"
                id="sortProdNm"
                @click="sortProdNm"
                class="product_grp_nm"
              >
                商品グループ名
              </th>
              <th
                scope="col"
                id="sortUnitNum"
                @click="sortUnitNum"
                class="device_total"
              >
                登録端末数
              </th>
              <!-- <th scope="col" class="muji"></th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div class="div_search_result items">
        <table class="targetTable">
          <tbody>
            <tr
              v-for="(row, key) in productsList"
              v-bind:key="row.prodId"
              @click="select(row, key)"
            >
              <td class="product_grp_id">{{ row.prodId }}</td>
              <td class="product_grp_nm">{{ row.prodNm }}</td>
              <td class="device_total">{{ row.unitNum }}</td>
              <!-- <td class="muji"></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="div_regist">
      <div class="tab_sel" btn>
        <button class="create_btn" @click="onClickCreate">新規</button>
      </div>
      <div class="div_reg_el">
        <div class="div_product_grp_id">
          <div class="div_label-wrapper">
            <label>商品グループID</label>
          </div>
          <input
            type="text"
            id="products-id"
            disabled="true"
            maxlength="16"
            oninput="value = value.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i,'');"
          />
        </div>
      </div>

      <div class="div_reg_el">
        <div class="div_product_grp_nm_2">
          <div class="div_label-wrapper">
            <label>商品グループ名</label>
          </div>
          <input
            type="text"
            id="productsupdate-name"
            disabled="true"
            maxlength="20"
          />
        </div>
      </div>

      <div class="div_reg_el">
        <div class="div_update_btn btn">
          <button class="button2" @click="show('update')" disabled="true">
            更新
          </button>
        </div>
        <div class="div_insert_btn btn">
          <button class="button3" @click="show('register')" disabled="true">
            登録
          </button>
        </div>
        <div class="div_delete_btn btn">
          <button class="button4" @click="show('deletion')" disabled="true">
            削除
          </button>
        </div>
      </div>
    </div>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @update="update"
      @register="register"
      @deletion="deletion"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";
export default {
  // props: ["modalTyp"],
  components: {
    ModalWindow,
  },

  created: function () {
    console.log("MngProducts is opend.");
  },

  data() {
    return {
      productsList: [], //商品グループ名一覧
      modalTyp: "", //ポップアップ種別
      totalCount: 0, // 検索結果
      currentPage: 1, // 現在ページ
      totalPage: 1, // 全ページ
      requestPage: 1, // 次遷移ページ
      selectRow: null, // 選択行
      sortItem: "prodId", // ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, // ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgProdId: 0, // ソートフラグ(商品グループID)
      sortFlgProdNm: 0, // ソートフラグ(商品グループ名)
      sortFlgUnitNum: 0, // ソートフラグ(登録端末数)
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentProdNm: "", // 検索商品グループ名保持用
    };
  },

  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },

  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngProducts search Start");
        const condition = {
          prodNm: this.currentProdNm,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        axios
          .get(
            "/prodsearch?" +
              "prodNm=" +
              encodeURIComponent(this.escape(condition.prodNm)) +
              "&" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            // 正常処理の場合
            if (response.data.status == 0 || response.data.status == 1) {
              this.productsList = response.data.data;
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;

              //テーブルが9行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.productsList != null) {
                if (this.productsList.length < 10) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";
              document.querySelector("div.div-page-btn").style.visibility =
                "visible";

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector("#sortProdId").innerText =
                  "商品グループID";
                document.querySelector("#sortProdNm").innerText =
                  "商品グループ名";
                document.querySelector("#sortUnitNum").innerText = "登録端末数";
              } else {
                // ページ遷移ボタンの活性状態切り替え
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prev").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = false;
                }
                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.next").disabled = false;
                }

                // 初回検索時のみ「商品グループID ▲」表示
                if (this.sortItem === "prodId" && this.sortDirection === 0) {
                  document.querySelector("#sortProdId").innerText =
                    "商品グループID ▲";
                }
              }

              //前回選択行の背景色を解除
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
              }

              console.log("MngProducts search Success");
            }

            if (this.productsList != null && this.productsList.length > 0) {
              document.querySelector(".targetTable").style.width = "auto";
            } else {
              document.querySelector(".targetTable").style.width = "1203px";
            }
            
            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngProducts search ERROR2");
              // 商品グループ名のクリア、活性化
              document.getElementById("products-name").value = null;
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            //スクロールバーの位置をtopに移動する
            document.querySelector(".div_search_result.items").scrollTop = 0;
            console.log("MngProducts search End");
            this.axiosFlg = 0;
          });
      }
    },

    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;
      this.currentProdNm = document.querySelector("#products-name").value;
      // 検索メソッドの呼び出し
      this.search();
    },

    // 更新ボタン押下時処理
    update() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngProducts update Start");
        // 商品グループ名の未入力チェック
        if (document.getElementById("productsupdate-name").value == "") {
          this.axiosFlg = 0;
          //未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp = "商品グループ名が未入力です。");
          return;
        }

        const condition = {
          prodId: document.querySelector("#products-id").value,
          prodNm: document.querySelector("#productsupdate-name").value,
        };

        let searchFlag = false;
        axios
          .post("/produp", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            // 正常処理の場合
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngProducts update Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngProducts update ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 3) {
              console.log("MngProducts update ERROR3");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngProducts update End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 登録ボタン押下時処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngProducts register Start");
        // 商品グループID、商品グループ名の未入力チェック
        if (
          document.getElementById("products-id").value == "" ||
          document.getElementById("productsupdate-name").value == ""
        ) {
          this.axiosFlg = 0;
          // 未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "商品グループIDまたは、商品グループ名が未入力です。");
          return;
        }

        const condition = {
          prodId: document.querySelector("#products-id").value,
          prodNm: document.querySelector("#productsupdate-name").value,
        };

        let searchFlag = false;
        axios
          .post("/prodin", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            // 正常処理の場合
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              console.log("MngProducts register Success");
              searchFlag = true;
            }
            // データが重複する場合
            if (response.data.status == 1) {
              console.log("MngProducts register ERROR1");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }
            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngProducts register ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngProducts register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 削除ボタン押下時処理
    deletion() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngProducts deletion Start");
        const condition = {
          prodId: document.querySelector("#products-id").value,
        };

        let searchFlag = false;
        axios
          .post("/proddel", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            // 正常処理
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngProducts deletion Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngProducts deletion ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 2) {
              console.log("MngProducts deletion ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngProducts deletion End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 一覧データ選択時処理
    select: function (row, key) {
      console.log("MngProducts select Start");
      // 商品グループIDのデータを取得して表示、非活性化
      document.getElementById("products-id").value = row.prodId;
      document.getElementById("products-id").disabled = true;
      // 商品グループ名のデータを取得して表示、活性化
      document.getElementById("productsupdate-name").value = row.prodNm;
      document.getElementById("productsupdate-name").disabled = false;

      //更新ボタン活性化
      document.querySelector("button.button2").disabled = false;
      document.querySelector(".button2").style["background-color"] = "#1a2f77";
      //登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      //削除ボタン活性化
      document.querySelector("button.button4").disabled = false;
      document.querySelector(".button4").style["background-color"] = "#1a2f77";

      //前回選択行の背景色を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }

      //選択行の背景色を変更
      this.selectRow = document.querySelector(
        ".targetTable"
      ).lastElementChild.children[key];
      this.selectRow.style["background-color"] = "#0080ff20";

      console.log("MngProducts select End");
    },

    //新規ボタン押下処理
    onClickCreate() {
      console.log("MngProducts onClickCreate Start");
      // 商品グループIDのクリア、活性化
      document.getElementById("products-id").value = null;
      document.getElementById("products-id").disabled = false;
      // 商品グループ名のクリア、活性化
      document.getElementById("productsupdate-name").value = null;
      document.getElementById("productsupdate-name").disabled = false;

      //テーブルビューの選択状態を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      // 更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン活性化
      document.querySelector("button.button3").disabled = false;
      document.querySelector(".button3").style["background-color"] = "#1a2f77";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";

      console.log("MngProducts onClickCreate End");
    },

    // ソート機能(商品グループID)
    sortProdId() {
      console.log("MngProducts sortProdId Start");
      if (this.productsList != null && this.productsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "prodId";
        // 昇順・降順の切替
        if (this.sortFlgProdId == 0) {
          this.sortDirection = 1;
          this.sortFlgProdId = 1;
          document.querySelector("#sortProdId").innerText = "商品グループID ▼";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortUnitNum").innerText = "登録端末数";
        } else {
          this.sortDirection = 0;
          this.sortFlgProdId = 0;
          document.querySelector("#sortProdId").innerText = "商品グループID ▲";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortUnitNum").innerText = "登録端末数";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngProducts sortProdId End");
    },

    // ソート機能(商品グループ名)
    sortProdNm() {
      console.log("MngProducts sortProdNm Start");
      if (this.productsList != null && this.productsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "prodNm";
        // 昇順・降順の切替
        if (this.sortFlgProdNm == 0) {
          this.sortDirection = 1;
          this.sortFlgProdNm = 1;
          document.querySelector("#sortProdId").innerText = "商品グループID";
          document.querySelector("#sortProdNm").innerText = "商品グループ名 ▼";
          document.querySelector("#sortUnitNum").innerText = "登録端末数";
        } else {
          this.sortDirection = 0;
          this.sortFlgProdNm = 0;
          document.querySelector("#sortProdId").innerText = "商品グループID";
          document.querySelector("#sortProdNm").innerText = "商品グループ名 ▲";
          document.querySelector("#sortUnitNum").innerText = "登録端末数";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngProducts sortProdNm End");
    },

    // ソート機能(登録端末数)
    sortUnitNum() {
      console.log("MngProducts sortUnitNum Start");
      if (this.productsList != null && this.productsList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "unitNum";
        // 昇順・降順の切替
        if (this.sortFlgUnitNum == 0) {
          this.sortDirection = 1;
          this.sortFlgUnitNum = 1;
          document.querySelector("#sortProdId").innerText = "商品グループID";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortUnitNum").innerText = "登録端末数 ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgUnitNum = 0;
          document.querySelector("#sortProdId").innerText = "商品グループID";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortUnitNum").innerText = "登録端末数 ▲";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngProducts sortUnitNum End");
    },

    // 前ボタン押下時処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },

    // 次ボタン押下時処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
        // document.querySelectorAll("tr.selected");
      }
    },

    // ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.$refs.modal.modalContent(), (this.modalTyp = modaltyp);
    },

    // 画面の初期化処理
    clearDisp() {
      // 一覧表示の値をクリア
      this.productsList = [];

      // 検索結果、ページ遷移ボタンの非表示
      document.querySelector("div.div_search_memo").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";

      // ソートフラグの初期化
      this.sortDirection = 0;
      this.sortFlgProdId = 0;
      this.sortFlgProdNm = 0;
      this.sortFlgUnitNum = 0;

      // テーブルヘッダの初期化
      document.querySelector("#sortProdId").innerText = "商品グループID";
      document.querySelector("#sortProdNm").innerText = "商品グループ名";
      document.querySelector("#sortUnitNum").innerText = "登録端末数";

      // 検索条件は初期化対象外
      // 商品グループ名のクリア、活性化
      // document.getElementById("products-name").value = null;

      // 商品グループIDのクリア、非活性化
      document.getElementById("products-id").value = null;
      document.getElementById("products-id").disabled = true;
      // 商品グループ名のクリア、非活性化
      document.getElementById("productsupdate-name").value = null;
      document.getElementById("productsupdate-name").disabled = true;

      // 更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";
    },
  },
};
</script>

<style scoped>
.div_search {
  display: flex;
  padding: 15px 0 0 0;
}

.div_reg_el {
  display: flex;
  padding: 5px 0 0 0;
}

.div_product_grp_nm,
.div_product_grp_id,
.div_product_grp_nm_2 {
  width: 470px;
  display: inline-block;
}

.div_product_grp_nm_2 {
  margin-top: 20px;
}

.div_search_show {
  display: flex;
  flex-basis: 80%;
  align-items: center;
  width: 200px;
}

.div_search_detail {
  display: flex;
}

.div_search_btn {
  text-align: right;
  margin-left: auto;
}

.div_search_memo {
  text-align: right;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.button2,
button.button3,
button.button4 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

button.create_btn {
  width: 70px;
  border-radius: 6px;
}

.div_label-wrapper {
  display: inline-block;
  width: 140px;
}

.btn {
  border-radius: 30px;
}

.div_search_result.items {
  margin-top: -1px;
  height: 252px;
  overflow-y: auto;
  width: fit-content;
  border-style: solid;
  border-width: 1px;
}

.div_regist {
  margin-top: 30px;
}

.div_regist .div_reg_el:nth-child(4) {
  display: flex;
  margin-left: 65%;
}

.div_update_btn,
.div_insert_btn,
.div_delete_btn {
  margin-right: 20px;
}

.product_grp_id {
  width: 320px;
}

.product_grp_nm {
  width: 530px;
}

.device_total {
  width: 320px;
}

td.device_total {
  border-right: 0px;
}

.targetTable {
  width: 1203px;
}

tr.selected {
  background-color: #0080ff20;
}

tr.selected td {
  border-top-width: 1px;
  border-top-color: #0055ff59;
  border-bottom-width: 1px;
  border-bottom-color: #0055ff59;
}

th {
  height: 30px;
  cursor: pointer;
}

td.device_total {
  text-align: left;
}

.div-page-btn {
  display: flex;
  justify-content: center;
}

button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

td.muji {
  border-right: 0px;
}
</style>