<template>
  <router-view></router-view>
</template>

<script>
export default {
  created: () => document.documentElement.setAttribute("lang", "ja"),
  mounted: function () {
    const title = "デジタルサイネージ配信管理";
    document.title = title;
    console.log(
      "=== " + title + " ver." + localStorage.getItem("version") + " ==="
    );
  },
};
</script>

<style>
td,
button,
select {
  cursor: pointer;
}
</style>