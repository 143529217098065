<template>
  <div>
    <div class="select">
      <div class="div-search">
        <div class="div-device-no">
          <div class="div-label-wrapper">
            <label>端末番号</label>
          </div>
          <input
            type="text"
            id="device-id"
            maxlength="16"
            oninput="value = value.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i,'');"
          />
        </div>
        <div class="div-store-no">
          <div class="div-label-wrapper">
            <label>店舗コード</label>
          </div>
          <input
            type="text"
            @input="onInputStoreNo"
            id="store-id"
            maxlength="16"
            oninput="value = value.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i,'');"
          />
        </div>
        <div class="div-store-nm">
          <div class="div-label-wrapper">
            <label>店舗名</label>
          </div>
          <select id="store-name" @change="onStoreNmChange">
            <option></option>
            <option
              v-for="option in storeNmOptions"
              v-bind:key="option.storeId"
              v-bind:value="option.storeId"
            >
              {{ option.storeId }}：{{ option.storeNm }}
            </option>
          </select>
        </div>
      </div>
      <div class="div-search">
        <div class="div-product-grp-nm">
          <div class="div-label-wrapper">
            <label>商品グループ名</label>
          </div>
          <select id="product-group-name">
            <option></option>
            <option
              v-for="option in prodNmOptions"
              v-bind:key="option.prodId"
              v-bind:value="option.prodId"
            >
              {{ option.prodNm }}
            </option>
          </select>
        </div>
        <div class="div-device-typ">
          <div class="div-label-wrapper">
            <label>端末種別</label>
          </div>
          <select id="device-type">
            <option></option>
            <option
              v-for="option in deviceTypeOptions"
              v-bind:key="option.client_typ"
              v-bind:value="option.client_typ"
            >
              {{ option.typ_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="div-search-btn btn">
        <button class="button1" @click="onClickSearch">検索</button>
      </div>

      <div class="div-search-detail">
        <div class="div-search-show">
          <label class="table-title">端末一覧</label>
        </div>
        <div class="div_search_memo">
          <p>検索結果：{{ totalCount }} 件</p>
          <div class="div-page-btn">
            <button class="prev" @click="prevPage" disabled="true">≪</button>
            <p>{{ currentPage }} / {{ totalPage }}</p>
            <button class="next" @click="nextPage" disabled="true">≫</button>
          </div>
        </div>
      </div>

      <div class="div_search_result">
        <table class="targetTable">
          <thead>
            <tr>
              <th
                scope="col"
                id="sortClientId"
                @click="sortClientId"
                class="device-no"
              >
                端末番号
              </th>
              <th
                scope="col"
                id="sortStoreNm"
                @click="sortStoreNm"
                class="shop-nm"
              >
                店舗名
              </th>
              <th
                scope="col"
                id="sortProdNm"
                @click="sortProdNm"
                class="product-grp"
              >
                商品グループ名
              </th>
              <th
                scope="col"
                id="sortClientTyp"
                @click="sortClientTyp"
                class="device-typ"
              >
                端末種別
              </th>
              <th
                scope="col"
                id="sortRemarks"
                @click="sortRemarks"
                class="remarks"
              >
                メモ
              </th>
              <th
                scope="col"
                id="sortLastPlaylistNm"
                @click="sortLastPlaylistNm"
                class="last-playlist-nm"
              >
                配信済<br />プレイリスト名
              </th>
              <th
                scope="col"
                id="sortLastDeliveryDt"
                @click="sortLastDeliveryDt"
                class="last-delivery-dt"
              >
                配信日
              </th>
              <th
                scope="col"
                id="sortPlaylistNm"
                @click="sortPlaylistNm"
                class="playlist-nm"
              >
                配信予定<br />プレイリスト名
              </th>
              <th
                scope="col"
                id="sortDeliveryDt"
                @click="sortDeliveryDt"
                class="delivery-dt"
              >
                配信予定日
              </th>
              <th
                scope="col"
                id="sortLastAccess"
                @click="sortLastAccess"
                class="last-access-dt"
              >
                最終アクセス日時
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(row, key) in deviceList"
              v-bind:key="row.deviceId"
              @click="select(row, key)"
            >
              <td class="device-no">{{ row.clientId }}</td>
              <td class="shop-nm">{{ row.storeNm }}</td>
              <td class="product-grp">{{ row.prodNm }}</td>
              <td class="device-typ">{{ row.clientTypNm }}</td>
              <td class="remarks">{{ row.remarks }}</td>
              <td class="last-playlist-nm">{{ row.lastPlaylistNm }}</td>
              <td class="last-delivery-dt">
                {{ row.lastDeliveryDt | moment1 }}
              </td>
              <td class="playlist-nm">{{ row.playlistNm }}</td>
              <td class="delivery-dt">{{ row.deliveryDt | moment1 }}</td>
              <td class="last-access-dt">{{ row.lastAccess | moment2 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="div-regist">
      <div class="tab-sel" btn>
        <button class="create-btn" @click="onClickCreate">新規</button>
      </div>
      <div class="div-reg-el">
        <div class="div-device-no">
          <div class="div-label-wrapper">
            <label>端末番号</label>
          </div>
          <input
            type="text"
            id="deviceupdate-id"
            disabled="true"
            maxlength="16"
            oninput="value = value.replace(/[^a-zA-Z0-9!-/:-@¥[-`{-~]+/i,'');"
          />
        </div>
        <div class="div-store-nm">
          <div class="div-label-wrapper">
            <label>店舗名</label>
          </div>
          <select id="storeupdate-name" disabled="true">
            <option></option>
            <option
              v-for="option in storeNmOptions"
              v-bind:key="option.storeId"
              v-bind:value="option.storeId"
            >
              {{ option.storeNm }}
            </option>
          </select>
        </div>
      </div>
      <div class="div-reg-el">
        <div class="div-product-grp-nm">
          <div class="div-label-wrapper">
            <label>商品グループ名</label>
          </div>
          <select id="product-group-update-name" disabled="true">
            <option></option>
            <option
              v-for="option in prodNmOptions"
              v-bind:key="option.prodId"
              v-bind:value="option.prodId"
            >
              {{ option.prodNm }}
            </option>
          </select>
        </div>
        <div class="div-device-typ">
          <div class="div-label-wrapper">
            <label>端末種別</label>
          </div>
          <select id="deviceupdate-type" disabled="true">
            <option
              v-for="option in deviceTypeOptions"
              v-bind:key="option.client_typ"
              v-bind:value="option.client_typ"
            >
              {{ option.typ_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="div-reg-el">
        <div class="div-reboot">
          <div class="div-label-wrapper">
            <label for="chk-restart">再起動</label>
          </div>
          <input
            type="checkbox"
            id="chk-restart"
            class="chkbox"
            disabled="true"
          />
        </div>

        <div class="div-remarks">
          <div class="div-label-wrapper">
            <label>メモ</label>
          </div>
          <input type="text" id="remarks-id" maxlength="30" disabled="true" />
        </div>
      </div>

      <div class="div-reg-el">
        <div class="div-update-btn btn">
          <button class="button2" @click="show('update')" disabled="true">
            更新
          </button>
        </div>
        <div class="div-insert-btn btn">
          <button class="button3" @click="show('register')" disabled="true">
            登録
          </button>
        </div>
        <div class="div-delete-btn btn">
          <button class="button4" @click="show('deletion')" disabled="true">
            削除
          </button>
        </div>
      </div>
    </div>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @update="update"
      @register="register"
      @deletion="deletion"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import ModalWindow from "./ModalWindow";
import moment from "moment";
export default {
  components: {
    ModalWindow,
  },
  filters: {
    moment1: function (date) {
      if (date == null) {
        return null;
      }
      return moment(date).format("YYYY/MM/DD");
    },
    moment2: function (date) {
      if (date == null) {
        return null;
      }
      return moment(date).format("YYYY/MM/DD hh:mm");
    },
  },

  activated: function () {
    console.log("MngDevice is opend.");

    Promise.all([
      axios.get("/storetype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
      axios.get("/prodtype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
      axios.get("/clienttype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
    ])
      .then(([response1, response2, response3]) => {
        console.log(
          "response1:",
          response1,
          ", response2:",
          response2,
          ", response3:",
          response3
        );
        this.storeNmOptions = response1.data.data;
        this.prodNmOptions = response2.data.data;
        this.deviceTypeOptions = response3.data.datas;

        // プルダウン読み込み完了時点で、店舗コード入力欄をチェックする
        this.onInputStoreNo();
      })
      .catch((error) => {
        if (error.response && error.response.data.status === 401) {
          return;
        }
        // エラーポップアップを表示「予期せぬエラーが発生しました。」
        this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
        console.log(error);
      });
  },
  data() {
    return {
      deviceList: [], // 端末リスト
      prodNmOptions: [], // 商品グループプルダウン
      storeNmOptions: [], // 地域グループプルダウン
      deviceTypeOptions: [], // 端末種別プルダウン
      modalTyp: "", //ポップアップ種別
      totalCount: 0, // 検索結果
      currentPage: 1, // 現在ページ
      totalPage: 1, // 全ページ
      requestPage: 1, // 次遷移ページ
      selectRow: null, // 選択行
      sortItem: "clientId", // ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, // ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgClientId: 0, // ソートフラグ(端末番号)
      sortFlgStoreNm: 0, // ソートフラグ(店舗名)
      sortFlgProdNm: 0, // ソートフラグ(商品グループ名)
      sortFlgClientTyp: 0, // ソートフラグ(端末種別)
      sortFlgLastPlaylistNm: 0, // ソートフラグ(配信済プレイリスト名)
      sortFlgLastDeliveryDt: 0, // ソートフラグ(配信日)
      sortFlgPlaylistNm: 0, // ソートフラグ(配信予定プレイリスト名)
      sortFlgDeliveryDt: 0, // ソートフラグ(配信予定日)
      sortFlgLastAccess: 0, // ソートフラグ(最終アクセス日時)
      sortFlgRemarks: 0, // ソートフラグ(メモ)
      rebootFlg: 0, // 再起動フラグ
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentClientId: "", // 検索端末番号保持用
      currentStoreId: "", // 検索店舗ID保持用
      currentProdNm: "", // 検索商品グループ名保持用
      currentClientTyp: "", // 検索端末種別保持用
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        console.log("MngDevices search Start");
        this.axiosFlg = 1;
        const condition = {
          clientId: this.currentClientId,
          storeId: this.currentStoreId,
          prodNm: this.currentProdNm,
          clientTyp: this.currentClientTyp,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        axios
          .get(
            "/clientsearch?" +
              "clientId=" +
              encodeURIComponent(this.escape(condition.clientId)) +
              "&" +
              "storeId=" +
              encodeURIComponent(this.escape(condition.storeId)) +
              "&" +
              "prodNm=" +
              encodeURIComponent(this.escape(condition.prodNm)) +
              "&" +
              "clientTyp=" +
              encodeURIComponent(condition.clientTyp) +
              "&" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            // 正常処理の場合
            if (response.data.status == 0 || response.data.status == 1) {
              this.deviceList = response.data.data;
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;

              //テーブルが7行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.deviceList != null) {
                if (this.deviceList.length < 8) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";
              document.querySelector("div.div-page-btn").style.visibility =
                "visible";

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector("#sortClientId").innerText = "端末番号";
                document.querySelector("#sortStoreNm").innerText = "店舗名";
                document.querySelector("#sortProdNm").innerText =
                  "商品グループ名";
                document.querySelector("#sortClientTyp").innerText = "端末種別";
                document.querySelector("#sortLastPlaylistNm").innerText =
                  "配信済プレイリスト名";
                document.querySelector("#sortLastDeliveryDt").innerText =
                  "配信日";
                document.querySelector("#sortPlaylistNm").innerText =
                  "配信予定プレイリスト名";
                document.querySelector("#sortDeliveryDt").innerText =
                  "配信予定日";
                document.querySelector("#sortLastAccess").innerText =
                  "最終アクセス日時";
                document.querySelector("#sortRemarks").innerText = "メモ";
              } else {
                // ページ遷移ボタンの活性状態切り替え
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prev").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = false;
                }
                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.next").disabled = false;
                }

                // 初回検索時のみ「端末番号 ▲」表示
                if (this.sortItem === "clientId" && this.sortDirection === 0) {
                  document.querySelector("#sortClientId").innerText =
                    "端末番号 ▲";
                }
              }

              //前回選択行の背景色を解除
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
              }

              console.log("MngDevices search Success");
            }

            // if (this.productsList != null && this.productsList.length > 0) {
            //   document.querySelector(".targetTable").style.width = "auto";
            // } else {
            //   document.querySelector(".targetTable").style.width = "1202px";
            // }

            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngDevices search ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            //スクロールバーの位置をtopに移動する
            document.querySelector(".div_search_result").scrollTop = 0;
            console.log("MngDevices search End");
            this.axiosFlg = 0;
          });
      }
    },

    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;
      this.currentClientId = document.querySelector("#device-id").value;
      this.currentStoreId = document.querySelector("#store-id").value;
      var objpgn = document.getElementById("product-group-name");
      this.currentProdNm = objpgn.options[objpgn.selectedIndex].text;
      this.currentClientTyp = document.querySelector("#device-type").value;
      // 検索メソッドの呼び出し
      this.search();
    },

    // 更新ボタン押下時処理
    update() {
      if (this.axiosFlg == 0) {
        console.log("MngDevices update Start");
        this.axiosFlg = 1;
        // 店舗名、商品グループ名の未入力チェック
        if (
          document.getElementById("storeupdate-name").value == "" ||
          document.getElementById("product-group-update-name").value == ""
        ) {
          this.axiosFlg = 0;
          //未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp = "店舗名または、商品グループ名が未入力です。");
          return;
        }
        // 再起動チェックボックスの確認
        if (document.querySelector("#chk-restart").checked == true) {
          this.rebootFlg = 1;
        } else {
          this.rebootFlg = 0;
        }

        const condition = {
          clientId: document.querySelector("#deviceupdate-id").value,
          storeId: document.querySelector("#storeupdate-name").value,
          prodId: document.querySelector("#product-group-update-name").value,
          clientTyp: document.querySelector("#deviceupdate-type").value,
          rebootFlg: this.rebootFlg,
          remarks: document.querySelector("#remarks-id").value,
        };

        let searchFlag = false;
        axios
          .post("/clientup", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngDevices update Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngDevices update ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // データが重複する場合
            if (response.data.status == 2) {
              console.log("MngDevices update ERROR2");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }

            // 該当のデータが存在する場合
            if (response.data.status == 3) {
              console.log("MngDevices update ERROR3");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngDevices update End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 登録ボタン押下時処理
    register() {
      if (this.axiosFlg == 0) {
        console.log("MngDevices register Start");
        this.axiosFlg = 1;
        // 端末番号、店舗名、商品グループ名の未入力チェック
        if (
          document.getElementById("deviceupdate-id").value == "" ||
          document.getElementById("storeupdate-name").value == "" ||
          document.getElementById("product-group-update-name").value == ""
        ) {
          this.axiosFlg = 0;
          //未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "端末番号、店舗名、商品グループ名のいずれかが未入力です。");
          return;
        }

        // 再起動チェックボックスの確認
        if (document.querySelector("#chk-restart").checked == true) {
          this.rebootFlg = 1;
        } else {
          this.rebootFlg = 0;
        }

        const condition = {
          clientId: document.querySelector("#deviceupdate-id").value,
          storeId: document.querySelector("#storeupdate-name").value,
          prodId: document.querySelector("#product-group-update-name").value,
          clientTyp: document.querySelector("#deviceupdate-type").value,
          rebootFlg: this.rebootFlg,
          remarks: document.querySelector("#remarks-id").value,
        };

        let searchFlag = false;
        axios
          .post("/clientin", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngDevices register Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            // データが重複する場合
            if (response.data.status == 1) {
              console.log("MngDevices register ERROR1");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }
            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngDevices register ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngDevices register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 削除ボタン押下時処理
    deletion() {
      if (this.axiosFlg == 0) {
        this.axiosFlg = 1;
        console.log("MngDevices deletion Start");
        const condition = {
          clientId: document.querySelector("#deviceupdate-id").value,
          storeNm: document.querySelector("#storeupdate-name").value,
        };

        let searchFlag = false;
        axios
          .post("/clientdel", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngDevices deletion Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("MngDevices deletion ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 2) {
              console.log("MngDevices deletion ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngDevices deletion End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    // 一覧データ選択時処理
    select: function (row, key) {
      console.log("MngDevices select Start");
      // 端末番号のデータを取得して表示、非活性化
      document.getElementById("deviceupdate-id").value = row.clientId;
      document.getElementById("deviceupdate-id").disabled = true;
      // 店舗名のデータを取得して表示、活性化
      // document.getElementById("storeupdate-name").value = row.storeNm;
      document.getElementById("storeupdate-name").disabled = false;
      // 商品グループ名のデータを取得して表示、活性化
      // document.getElementById("product-group-update-name").value = row.prodNm;
      document.getElementById("product-group-update-name").disabled = false;
      // 端末種別のデータを取得して表示、活性化
      // document.getElementById("deviceupdate-type").value = row.clientTypNm;
      document.getElementById("deviceupdate-type").disabled = false;
      // チェックボックスの活性化
      document.getElementById("chk-restart").checked = false;
      document.getElementById("chk-restart").disabled = false;

      // 店舗名プルダウンの設定
      document.querySelector("#storeupdate-name").value = null;
      for (var i = 0; i < this.storeNmOptions.length; i++) {
        if (row.storeNm == this.storeNmOptions[i].storeNm) {
          document.querySelector("#storeupdate-name").value =
            this.storeNmOptions[i].storeId;
          break;
        }
      }

      // 商品グループ名プルダウンの設定
      document.querySelector("#product-group-update-name").value = null;
      for (i = 0; i < this.prodNmOptions.length; i++) {
        if (row.prodNm == this.prodNmOptions[i].prodNm) {
          document.querySelector("#product-group-update-name").value =
            this.prodNmOptions[i].prodId;
          break;
        }
      }

      // 端末種別プルダウンの設定
      document.querySelector("#deviceupdate-type").value = null;
      for (i = 0; i < this.deviceTypeOptions.length; i++) {
        if (row.clientTypNm == this.deviceTypeOptions[i].typ_name) {
          document.querySelector("#deviceupdate-type").value =
            this.deviceTypeOptions[i].client_typ;
          break;
        }
      }

      // メモのデータを取得して表示、活性化
      document.getElementById("remarks-id").value = row.remarks;
      document.getElementById("remarks-id").disabled = false;

      //更新ボタン活性化
      document.querySelector("button.button2").disabled = false;
      document.querySelector(".button2").style["background-color"] = "#1a2f77";
      //登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      //削除ボタン活性化
      document.querySelector("button.button4").disabled = false;
      document.querySelector(".button4").style["background-color"] = "#1a2f77";

      //前回選択行の背景色を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }

      //選択行の背景色を変更
      this.selectRow =
        document.querySelector(".targetTable").lastElementChild.children[key];
      this.selectRow.style["background-color"] = "#0080ff20";

      console.log("MngDevices select End");
    },

    //新規ボタン押下処理
    onClickCreate() {
      console.log("MngDevices onClickCreate Start");
      // 端末番号のクリア、活性化
      document.getElementById("deviceupdate-id").value = null;
      document.getElementById("deviceupdate-id").disabled = false;
      // 店舗名の活性化
      document.getElementById("storeupdate-name").value = null;
      document.getElementById("storeupdate-name").disabled = false;
      // 商品グループ名の活性化
      document.getElementById("product-group-update-name").value = null;
      document.getElementById("product-group-update-name").disabled = false;
      // 端末種別の活性化
      document.getElementById("deviceupdate-type").value =
        this.deviceTypeOptions[0].client_typ;
      document.getElementById("deviceupdate-type").disabled = false;
      // チェックボックスの活性化
      document.getElementById("chk-restart").checked = false;
      document.getElementById("chk-restart").disabled = false;

      //テーブルビューの選択状態を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      // メモのクリア、活性化
      document.getElementById("remarks-id").value = null;
      document.getElementById("remarks-id").disabled = false;

      //更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      //登録ボタンの活性化
      document.querySelector("button.button3").disabled = false;
      document.querySelector(".button3").style["background-color"] = "#1a2f77";
      //削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";

      console.log("MngDevices onClickCreate End");
    },

    // 店舗名プルダウンで選択している店舗コードを、
    // 店舗コード欄に反映する
    onStoreNmChange: function () {
      var objsn = document.getElementById("store-name");
      if (objsn.selectedIndex > 0) {
        document.querySelector("#store-id").value =
          this.storeNmOptions[objsn.selectedIndex - 1].storeId;
      } else {
        document.querySelector("#store-id").value = null;
      }
    },

    // 店舗コードに入力した値が完全一致する場合、
    // 店舗名プルダウンに反映する
    onInputStoreNo: function () {
      var inputid = document.querySelector("#store-id").value;
      this.$nextTick(function () {
        var objsn = document.getElementById("store-name");
        objsn.value = null;

        for (let i = 0; i < this.storeNmOptions.length; i++) {
          let storeid = this.storeNmOptions[i].storeId;
          if (inputid === storeid) {
            objsn.selectedIndex = i + 1;
          }
        }
      });
    },

    // ソート機能(端末番号)
    sortClientId() {
      console.log("MngDevices sortClientId Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "clientId";
        // 昇順・降順の切替
        if (this.sortFlgClientId == 0) {
          this.sortDirection = 1;
          this.sortFlgClientId = 1;
          document.querySelector("#sortClientId").innerText = "端末番号 ▼";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgClientId = 0;
          document.querySelector("#sortClientId").innerText = "端末番号 ▲";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortClientId End");
    },

    // ソート機能(店舗名)
    sortStoreNm() {
      console.log("MngDevices sortStoreNm Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "storeNm";
        // 昇順・降順の切替
        if (this.sortFlgStoreNm == 0) {
          this.sortDirection = 1;
          this.sortFlgStoreNm = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名 ▼";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgStoreNm = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名 ▲";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortStoreNm End");
    },

    // ソート機能(商品グループ名)
    sortProdNm() {
      console.log("MngDevices sortProdNm Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "prodNm";
        // 昇順・降順の切替
        if (this.sortFlgProdNm == 0) {
          this.sortDirection = 1;
          this.sortFlgProdNm = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名 ▼";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgProdNm = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名 ▲";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortProdNm End");
    },

    // ソート機能(端末種別)
    sortClientTyp() {
      console.log("MngDevices sortClientTyp Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "clientTyp";
        // 昇順・降順の切替
        if (this.sortFlgClientTyp == 0) {
          this.sortDirection = 1;
          this.sortFlgClientTyp = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別 ▼";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgClientTyp = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別 ▲";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortClientTyp End");
    },

    // ソート機能(配信済プレイリスト名)
    sortLastPlaylistNm() {
      console.log("MngDevices sortPlaylistNm Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistNm";
        // 昇順・降順の切替
        if (this.sortFlgPlaylistNm == 0) {
          this.sortDirection = 1;
          this.sortFlgPlaylistNm = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済 ▼<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgPlaylistNm = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済 ▲<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortPlaylistNm End");
    },

    // ソート機能(配信日)
    sortLastDeliveryDt() {
      console.log("MngDevices sortDeliveryDt Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "deliveryDt";
        // 昇順・降順の切替
        if (this.sortFlgDeliveryDt == 0) {
          this.sortDirection = 1;
          this.sortFlgDeliveryDt = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日 ▼";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgDeliveryDt = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日 ▲";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortDeliveryDt End");
    },

    // ソート機能(配信予定プレイリスト名)
    sortPlaylistNm() {
      console.log("MngDevices sortPlaylistNm Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistNm";
        // 昇順・降順の切替
        if (this.sortFlgPlaylistNm == 0) {
          this.sortDirection = 1;
          this.sortFlgPlaylistNm = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定 ▼<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgPlaylistNm = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定 ▲<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortPlaylistNm End");
    },

    // ソート機能(配信予定日)
    sortDeliveryDt() {
      console.log("MngDevices sortDeliveryDt Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "deliveryDt";
        // 昇順・降順の切替
        if (this.sortFlgDeliveryDt == 0) {
          this.sortDirection = 1;
          this.sortFlgDeliveryDt = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日 ▼";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgDeliveryDt = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日 ▲";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortDeliveryDt End");
    },

    // ソート機能(最終アクセス日時)
    sortLastAccess() {
      console.log("MngDevices sortLastAccess Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "lastAccess";
        // 昇順・降順の切替
        if (this.sortFlgLastAccess == 0) {
          this.sortDirection = 1;
          this.sortFlgLastAccess = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時 ▼";
          document.querySelector("#sortRemarks").innerText = "メモ";
        } else {
          this.sortDirection = 0;
          this.sortFlgLastAccess = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時 ▲";
          document.querySelector("#sortRemarks").innerText = "メモ";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortLastAccess End");
    },

    // ソート機能(メモ)
    sortRemarks() {
      console.log("MngDevices sortRemarks Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "remarks";
        // 昇順・降順の切替
        if (this.sortFlgRemarks == 0) {
          this.sortDirection = 1;
          this.sortFlgRemarks = 1;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ ▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgRemarks = 0;
          document.querySelector("#sortClientId").innerText = "端末番号";
          document.querySelector("#sortStoreNm").innerText = "店舗名";
          document.querySelector("#sortProdNm").innerText = "商品グループ名";
          document.querySelector("#sortClientTyp").innerText = "端末種別";
          document.querySelector("#sortLastPlaylistNm").innerHTML =
            "配信済<br>プレイリスト名";
          document.querySelector("#sortLastDeliveryDt").innerText = "配信日";
          document.querySelector("#sortPlaylistNm").innerHTML =
            "配信予定<br>プレイリスト名";
          document.querySelector("#sortDeliveryDt").innerText = "配信予定日";
          document.querySelector("#sortLastAccess").innerText =
            "最終アクセス日時";
          document.querySelector("#sortRemarks").innerText = "メモ ▲";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngDevices sortRemarks End");
    },

    // 前ボタン押下時処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },

    // 次ボタン押下時処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
      }
    },

    //ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.$refs.modal.modalContent(), (this.modalTyp = modaltyp);
    },

    // 画面の初期化処理
    clearDisp() {
      // 一覧表示の値をクリア
      this.deviceList = [];

      // 検索結果、ページ遷移ボタンの非表示
      document.querySelector("div.div_search_memo").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";

      // ソートフラグの初期化
      this.sortDirection = 0;
      this.sortFlgClientId = 0;
      this.sortFlgStoreNm = 0;
      this.sortFlgProdNm = 0;
      this.sortFlgClientTyp = 0;
      this.sortFlgPlaylistNm = 0;
      this.sortFlgDeliveryDt = 0;
      this.sortFlgLastAccess = 0;
      this.sortFlgRemarks = 0;

      // テーブルヘッダの初期化
      document.querySelector("#sortClientId").innerText = "端末番号";
      document.querySelector("#sortStoreNm").innerText = "店舗名";
      document.querySelector("#sortProdNm").innerText = "商品グループ名";
      document.querySelector("#sortClientTyp").innerText = "端末種別";
      document.querySelector("#sortPlaylistNm").innerText = "プレイリスト名";
      document.querySelector("#sortDeliveryDt").innerText = "配信日";
      document.querySelector("#sortLastAccess").innerText = "最終アクセス日時";
      document.querySelector("#sortRemarks").innerText = "メモ";

      // 検索条件は初期化対象外
      // 端末番号のクリア
      // document.getElementById("device-id").value = null;
      // 商品グループ名のクリア
      // document.getElementById("product-group-name").value = null;
      // 店舗名のクリア
      // document.getElementById("store-name").value = null;
      // 端末種別のクリア
      // document.getElementById("device-type").value = null;

      // 端末番号のクリア、非活性化
      document.getElementById("deviceupdate-id").value = null;
      document.getElementById("deviceupdate-id").disabled = true;
      // 店舗名の初期値選択、非活性化
      document.getElementById("storeupdate-name").value = "";
      document.getElementById("storeupdate-name").disabled = true;
      // 商品グループ名の初期値選択、非活性化
      document.getElementById("product-group-update-name").value = "";
      document.getElementById("product-group-update-name").disabled = true;
      // 端末種別の初期値選択、非活性化
      document.getElementById("deviceupdate-type").value =
        this.deviceTypeOptions[0].client_typ;

      document.getElementById("deviceupdate-type").disabled = true;
      // チェックボックスの非活性化
      document.getElementById("chk-restart").checked = false;
      document.getElementById("chk-restart").disabled = true;

      // メモのクリア、非活性化
      document.getElementById("remarks-id").value = null;
      document.getElementById("remarks-id").disabled = true;

      // 更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";
    },
  },
};
</script>

<style scoped>
.div-search {
  display: flex;
  padding: 12px 0 0 0;
}

.div-reg-el {
  display: flex;
  padding: 5px 0 0 0;
}

.div-device-no,
.div-store-no,
.div-store-nm,
.div-product-grp-nm,
.div-device-typ,
.div-remarks {
  width: 400px;
}

.div-device-no,
.div-store-no,
.div-store-nm,
.div-product-grp-nm,
.div-device-typ,
.div-reboot,
.div-remarks {
  display: inline-block;
}

.div-remarks {
  margin-left: 248px;
}

.div-search-show {
  display: flex;
  flex-basis: 80%;
  align-items: center;
  width: 200px;
}

.div-search-detail {
  display: flex;
}

.div-search-btn {
  text-align: right;
  margin-left: auto;
}

.div_search_memo {
  text-align: right;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.button2,
button.button3,
button.button4 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

button.create-btn {
  width: 70px;
  border-radius: 6px;
}

.div-label-wrapper {
  display: inline-block;
  width: 140px;
}

.btn {
  border-radius: 30px;
}

.div-regist {
  margin-top: 10px;
}

.div-regist .div-reg-el:nth-child(5) {
  display: flex;
  margin-left: 65%;
}

.div-update-btn,
.div-insert-btn,
.div-delete-btn {
  margin-right: 20px;
}

.device-no {
  width: 200px;
  border-left: hidden;
}

.shop-nm {
  width: 300px;
}

.product-grp {
  width: 300px;
}

.device-typ {
  width: 150px;
}

.remarks {
  width: 300px;
}

.last-playlist-nm {
  width: 250px;
}

.last-delivery-dt {
  width: 150px;
}

.playlist-nm {
  width: 250px;
}

.delivery-dt {
  width: 150px;
}

.last-access-dt {
  width: 200px;
}

tr.selected {
  background-color: #0080ff20;
}

tr.selected td {
  border-top-width: 1px;
  border-top-color: #0055ff59;
  border-bottom-width: 1px;
  border-bottom-color: #0055ff59;
}

.div-page-btn {
  display: flex;
  justify-content: center;
}

button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

button.sort {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

input.chkbox {
  transform: scale(1.6);
}

.div_search_result {
  margin-top: 0;
  height: 260px;
  width: 1200px;
  overflow-y: auto;
  overflow-x: scroll;
  border-style: solid;
  border-width: 1px;
}

.div_search_result table {
  width: 2000px;
  border-top: hidden;
  border-collapse: separate;
}

.div_search_result thead th {
  position: sticky; /*固定する*/
  position: -webkit-sticky;
  top: 0; /*ブラウザの上からの距離はゼロ*/
  border-bottom: 1px solid black;
  border-top: hidden;
  border-left: 0px solid black;
  border-top: 0px solid black;
}
</style>
