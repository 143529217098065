<template>
  <div class="body">
    <div class="select">
      <div class="div-search">
        <div class="div-content-nm">
          <div class="div-label-wrapper">
            <label>コンテンツ名</label>
          </div>
          <input type="text" id="content-id" maxlength="30" />
        </div>
        <div class="div-content-typ">
          <div class="div-label-wrapper">
            <label>コンテンツ種別</label>
          </div>
          <select id="content-typ">
            <option></option>
            <option
              v-for="option in productGroupOptions"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="div-search">
        <div class="div_last_upgrade_date">
          <div class="div-label-wrapper">
            <label>アップロード日</label>
          </div>
          <input
            type="date"
            name="calendar"
            max="2999-12-31"
            id="uploadstart-id"
          />
          <label id="date">～</label>
          <input
            type="date"
            name="calendar"
            max="2999-12-31"
            id="uploadend-id"
          />
        </div>
      </div>

      <div class="div_search_btn btn">
        <button class="button1" @click="onClickSearch">検索</button>
      </div>
      <div class="div-search-show">
        <div class="div-search-title">
          <label class="table-title">コンテンツ一覧</label>
        </div>

        <div class="div_search_memo">
          <p>検索結果：{{ totalCount }} 件</p>
          <div>
            <div class="div-page-btn">
              <button class="prev" @click="prevPage" disabled="true">≪</button>
              <p>{{ currentPage }} / {{ totalPage }}</p>
              <button class="next" @click="nextPage">≫</button>
            </div>
          </div>
        </div>
      </div>

      <div class="div-search-result title">
        <table>
          <thead>
            <tr>
              <th
                scope="col"
                id="sortConNm"
                @click="sortConNm"
                class="content-nm"
              >
                コンテンツ名
              </th>
              <th scope="col" class="pre">表示</th>
              <th
                scope="col"
                id="sortConFl"
                @click="sortConFl"
                class="content-file"
              >
                ファイル名
              </th>
              <th
                scope="col"
                id="sortConTyp"
                @click="sortConTyp"
                class="content-typ"
              >
                コンテンツ種別
              </th>
              <th
                scope="col"
                id="sortPlayList"
                @click="sortPlayList"
                class="playlist-nm"
              >
                紐付きプレイリスト数
              </th>
              <th
                scope="col"
                id="sortUploadDt"
                @click="sortUploadDt"
                class="upload-dt"
              >
                アップロード日
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div class="div-search-result items">
        <table class="targetTable">
          <tbody>
            <tr
              v-for="(device, num) in deviceList"
              v-bind:key="device.deviceId"
              @click="select(device, num)"
            >
              <td class="content-nm">{{ device.contentsNm }}</td>
              <td class="pre">
                <img
                  v-bind:src="startButtonImagePath"
                  v-on:click="onStartButton(device, num)"
                  class="btn_start"
                />
              </td>
              <td class="content-file">{{ device.contentsFile }}</td>
              <td class="content-typ">
                {{ device.contentsTyp == 0 ? "動画" : "静止画" }}
              </td>
              <td class="playlist-nm">{{ device.linkPlaylist }}</td>
              <td class="upload-dt">{{ device.uploadDt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="div-regist">
      <div class="tab-sel" btn>
        <button class="create-btn" @click="onClickCreate">新規</button>
      </div>
      <div class="div-reg-el">
        <div class="div-content-file">
          <div class="div-label-wrapper">
            <label>ファイル名</label>
          </div>
          <input
            type="text"
            value=""
            id="contentupdatefile-id"
            disabled="true"
            maxlength="30"
          />
          <label for="label-input">
            <input
              type="file"
              name="file"
              id="label-input"
              @change="changeFile"
              style="display: none"
              @click="onClickChangeFile"
              disabled="true"
            />参照
          </label>
          <input type="hidden" value="" id="contentselect-type" />
          <input type="hidden" value="" id="contentselect-file" />
        </div>
      </div>
      <div class="div-reg-el">
        <div class="div-content-nm">
          <div class="div-label-wrapper">
            <label>コンテンツ名</label>
          </div>
          <input
            type="text"
            value=""
            id="contentupdate-id"
            maxlength="30"
            disabled="true"
          />
        </div>
      </div>
    </div>
    <div class="div-notes">
      <div class="div-notes-label">
        <label>
          <p>ファイル種別は以下の通り</p>
          <p class="p-notes">●静止画：BMP/GIF/JPG/PNG</p>
          <p class="p-notes">●動画&nbsp;：mp4/webm</p>
        </label>
      </div>
    </div>
    <div class="div-reg-el div-ctrl" id="modal">
      <div class="div-update-btn btn">
        <!--
        <button class="button2" @click="show('update')" disabled="true">
          更新
        </button>
        -->
      </div>
      <div class="div-insert-btn btn">
        <button class="button3" @click="show('register')" disabled="true">
          登録
        </button>
      </div>
      <div class="div-delete-btn btn">
        <button class="button4" @click="show('deletion')" disabled="true">
          削除
        </button>
      </div>
    </div>    
    <div></div>
    <movieModal
      v-if="showMovie"
      v-bind:showMovieData="this.movieData"
      v-bind:showMovieType="this.movieType"
      @close="showMovie = false"
    ></movieModal>
    <pictureModal
      v-if="showPicture"
      v-bind:showPictureData="this.pictureData"
      @close="showPicture = false"
    ></pictureModal>
    <LoadingModal
      v-if="showLoading"
      @close="showLoading = false"
    ></LoadingModal>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @update="update"
      @register="register"
      @deletion="deletion"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import movieModal from "./MovieModal";
import pictureModal from "./PictureModal";
import ModalWindow from "./ModalWindow";
import LoadingModal from "./LoadingModal";

export default {
  components: {
    movieModal,
    pictureModal,
    ModalWindow,
    LoadingModal,
  },

  created: function () {
    console.log("MngContents is opened.");
    axios
      .get("/contentsmaxsize", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.status == 0) {
          this.contentsMaxSize = response.data.size;
        }
        // その他のエラー
        else {
          // エラーポップアップを表示「予期せぬエラーが発生しました。」
          this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          return;
        }
     })
      .catch((error) => {
        if (error.response && error.response.data.status === 401) {
          return;
        }
        // エラーポップアップを表示「予期せぬエラーが発生しました。」
        this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
        console.log(error);
      });
  },

  data() {
    return {
      productGroupOptions: [
        { id: "0", name: "動画" },
        { id: "1", name: "静止画" },
      ],
      totalCount: 0, // 検索結果
      currentPage: 1, // 現在ページ
      totalPage: 1, // 全ページ
      requestPage: 1, // 次遷移ページ
      modalTyp: "", //ポップアップ種別
      selectRow: null, //選択行
      file: {}, //参照ファイル名
      startButtonImagePath: require("@/assets/png/startButton.png"), //プレビューボタン
      showMovie: false, //プレビュー表示
      movieData: null, //動画データ
      movieType: null, //動画コンテンツタイプ
      showPicture: false, //画像表示
      pictureData: null, //画像データ
      showLoading: false, //ローディング画面
      sortItem: "contentsNm", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgConNm: 0, // ソートフラグ(コンテンツ名)
      sortFlgConFl: 0, // ソートフラグ(ファイル名)
      sortFlgConTyp: 0, // ソートフラグ(コンテンツ種別)
      sortFlgPlayList: 0, // ソートフラグ(紐付きプレイリスト数)
      sortFlgUploadDt: 0, // ソートフラグ(アップロード日)
      currentContentsNm: null, //選択中のコンテンツ情報
      axiosFlg: 0, // 通信フラグ(連打対応)
      deviceList: [], //コンテンツ名リスト
      blob: null, //動画または写真のbinaryデータをラップしたもの
      currentContentsNms: "", // 検索コンテンツ名保持用
      currentContentsTyp: "", // 検索コンテンツ種別保持用
      currentUploadDtStart: "", // 検索アップロード開始日保持用
      currentUploadDtEnd: "", // 検索アップロード終了日保持用
      contentsMaxSize: 1024*1024*1024*1, // 最大コンテンツサイズ
    };
  },
  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },
  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngContents search Start");

        const condition = {
          contentsNm: this.currentContentsNms,
          contentsTyp: this.currentContentsTyp,
          uploadDtStart: this.currentUploadDtStart,
          uploadDtEnd: this.currentUploadDtEnd,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        // 端末指定区分の設定
        for (var i = 0; i < this.productGroupOptions.length; i++) {
          if (condition.contentsTyp == this.productGroupOptions[i].name) {
            condition.contentsTyp = this.productGroupOptions[i].id;
            break;
          }
        }

        axios
          .get(
            "/contentssearch?" +
              "contentsNm=" +
              encodeURIComponent(this.escape(condition.contentsNm)) +
              "&" +
              "contentsTyp=" +
              encodeURIComponent(condition.contentsTyp) +
              "&" +
              "uploadDtStart=" +
              encodeURIComponent(condition.uploadDtStart) +
              "&" +
              "uploadDtEnd=" +
              encodeURIComponent(condition.uploadDtEnd) +
              "&" +
              "page=" +
              encodeURIComponent(condition.page) +
              "&" +
              "sortItem=" +
              encodeURIComponent(condition.sortItem) +
              "&" +
              "sortDirection=" +
              encodeURIComponent(condition.sortDirection),
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            // 正常処理の場合
            if (response.data.status == 0 || response.data.status == 1) {
              this.deviceList = response.data.data;
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;

              //テーブルが7行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.deviceList != null) {
                if (this.deviceList.length < 8) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";
              document.querySelector("div.div-page-btn").style.visibility =
                "visible";

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector("#sortConNm").innerText = "コンテンツ名";
                document.querySelector("#sortConFl").innerText = "ファイル名";
                document.querySelector("#sortConTyp").innerText =
                  "コンテンツ種別";
                document.querySelector("#sortPlayList").innerText =
                  "紐付きプレイリスト数";
                document.querySelector("#sortUploadDt").innerText =
                  "アップロード日";
              } else {
                // ページ遷移ボタンの活性状態切り替え
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prev").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = false;
                }

                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.next").disabled = false;
                }

                // 初回検索時のみ「コンテンツ名 ▲」表示
                if (
                  this.sortItem === "contentsNm" &&
                  this.sortDirection === 0
                ) {
                  document.querySelector("#sortConNm").innerText =
                    "コンテンツ名 ▲";
                }
              }

              // 前回選択行の背景色を解除
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
              }
              console.log("MngContents search Success");
            }

            if (this.productsList != null && this.productsList.length > 0) {
              document.querySelector(".targetTable").style.width = "auto";
            } else {
              document.querySelector(".targetTable").style.width = "1207px";
            }

            // その他のエラー
            if (response.data.status == 2) {
              console.log("MngContents search ERROR2");
              // 入力欄のクリア
              document.getElementById("content-id").value = null;
              document.getElementById("content-typ").value = null;
              document.getElementById("uploadstart-id").value = null;
              document.getElementById("uploadend-id").value = null;
              //エラーポップアップを表示
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngContents search End");
            //スクロールバーの位置をtopに移動する
            document.querySelector(".div-search-result.items").scrollTop = 0;
            this.axiosFlg = 0;
          });
      }
    },

    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;

      // 日付のハイフンを削除
      var sourceStr1 = document.querySelector("#uploadstart-id").value;
      var datefirst = sourceStr1.replace(/-/g, "");
      var sourceStr2 = document.querySelector("#uploadend-id").value;
      var dateend = sourceStr2.replace(/-/g, "");

      this.currentContentsNms = document.querySelector("#content-id").value;
      this.currentContentsTyp = document.querySelector("#content-typ").value;
      this.currentUploadDtStart = datefirst;
      this.currentUploadDtEnd = dateend;

      // 検索メソッドの呼び出し
      this.search();
    },

    //登録ボタン押下処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngContents register Start");

        //ファイル名、コンテンツ名の空白判定
        if (
          document.querySelector("#contentupdate-id").value == "" ||
          document.querySelector("#contentupdatefile-id").value == ""
        ) {
          this.axiosFlg = 0;
          //未入力エラー
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "ファイル参照または、<br>コンテンツ名が未入力です。");
          return;
        }

        // アプロードファイルサイズ制限
        if (this.file.size > (this.contentsMaxSize * 1024 * 1024)) {
          this.axiosFlg = 0;
          // アプロードファイルサイズ上限オーバー
          this.$refs.modal.modalError(),
            (this.modalTyp =
              this.contentsMaxSize + "MBを超えるコンテンツはアップロードできません。");
          return;
        }

        const condition = {
          //contentsFile: document.querySelector("#contentupdatefile-id").value,
          contentsNm: document.querySelector("#contentupdate-id").value,
        };

        //アップロード中ポップアップ表示
        this.$modal.show("modal-upload");

        let formData = new FormData();
        //formData.append(condition.contentsNm, this.file);
        formData.append("contentsNm", condition.contentsNm);
        formData.append("file", this.file);

        let searchFlag = false;
        axios
          .post("/contentsin", formData, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              "content-type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngContents register Success");
              //完了ポップアップ表示
              this.$modal.show("modal-completioncontent");
              searchFlag = true;
            }
            // データが重複する場合
            else if (response.data.status == 2) {
              console.log("MngContents register ERROR1");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }
            // 拡張子エラー
            else if (response.data.status == 3) {
              console.log("MngContents register ERROR3");
              // エラーポップアップ表示
              this.$refs.modal.modalError(),
                (this.modalTyp =
                  "ファイルの拡張子が<br>動画・静止画ではありません。");
              return;
            }
            // アップロードエラー
            else if (response.data.status == 4) {
              console.log("MngContents register ERROR4");
              // エラーポップアップ表示
              this.$refs.modal.modalError(),
                (this.modalTyp =
                  "アップロード先ファイルパスが<br>取得できませんでした。");
              return;
            }
            // その他のエラー
            else {
              console.log("MngContents register ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngContents register End");
            //アップロード中ポップアップ非表示
            this.$modal.hide("modal-upload");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //削除ボタン押下処理
    deletion() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("MngContents deletion Start");
        const condition = {
          contentsNm: document.querySelector("#contentupdate-id").value,
          contentsFile: document.querySelector("#contentselect-file").value,
          contentsTyp: document.querySelector("#contentselect-type").value,
        };

        let searchFlag = false;
        axios
          .post("/contentsdel", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("MngContents deletion Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            // 該当のデータが存在しない場合
            else if (response.data.status == 3) {
              console.log("MngContents deletion ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
            // その他のエラー
            else {
              console.log("MngContents deletion ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngContents deletion End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //コンテンツ一覧のデータ選択時
    select: function (device, num) {
      console.log("MngContents select Start");
      // ファイル名、コンテンツ名、コンテンツ種別のデータを取得して表示
      document.querySelector("#contentselect-file").value = device.contentsFile;
      document.querySelector("#contentupdatefile-id").value =
        device.contentsFile;
      document.querySelector("#contentupdate-id").value = device.contentsNm;
      document.querySelector("#contentselect-type").value = device.contentsTyp;
      // ファイル名の活性化
      //document.querySelector("#contentupdatefile-id").disabled = false;
      // コンテンツ名の非活性化
      document.querySelector("#contentupdate-id").disabled = true;
      //選択中のコンテンツ情報を保持（コンテンツ情報の削除用）
      this.currentContentsNm = device.contentsNm;

      //登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      //削除ボタン活性化
      document.querySelector("button.button4").disabled = false;
      document.querySelector(".button4").style["background-color"] = "#1a2f77";

      //更新ボタン非活性化
      //document.querySelector("button.button2").disabled = true;
      //document.querySelector(".button2").style["background-color"] = "gray";

      //参照ボタンの活性化
      document.querySelector("#label-input").disabled = false;
      document.querySelector('label[for="label-input"]').style["color"] =
        "black";

      //前回選択行の背景色を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }

      //選択行の背景色を変更
      this.selectRow = document.querySelector(
        ".targetTable"
      ).lastElementChild.children[num];
      this.selectRow.style["background-color"] = "#0080ff20";

      console.log("MngContents select End");
    },

    // 前ボタン押下処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },
    // 次ボタン押下処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
        document.querySelectorAll("tr.selected");
      }
    },

    //新規ボタン押下処理
    onClickCreate() {
      console.log("MngContents onClickCreate Start");
      // ファイル名、コンテンツ名のクリア
      document.querySelector("#contentupdatefile-id").value = null;
      document.querySelector("#contentupdate-id").value = null;
      // コンテンツ名の活性化
      //document.querySelector("#contentupdatefile-id").disabled = false;
      document.querySelector("#contentupdate-id").disabled = false;

      //テーブルビューの選択状態を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }

      //更新ボタン非活性化
      //document.querySelector("button.button2").disabled = true;
      //document.querySelector(".button2").style["background-color"] = "gray";
      //登録ボタンの活性化
      document.querySelector("button.button3").disabled = false;
      document.querySelector(".button3").style["background-color"] = "#1a2f77";
      //削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";

      //参照ボタンの活性化
      document.querySelector("#label-input").disabled = false;
      document.querySelector('label[for="label-input"]').style["color"] =
        "black";

      console.log("MngContents onClickCreate End");
    },

    //参照ボタン初期化
    onClickChangeFile: function (e) {
      //ファイル名を初期化
      e.target.value = "";
    },

    //参照ボタン押下処理
    changeFile: function (e) {
      //ファイル情報の取得
      const files = e.target.files;
      this.file = files[0];
      //ファイル名に表示
      document.getElementById("contentupdatefile-id").value = this.file.name;

      //更新ボタン活性化
      if (document.querySelector("button.button3").disabled) {
        //document.querySelector("button.button2").disabled = false;
        //document.querySelector(".button2").style["background-color"] =
        ("#1a2f77");
      }
    },

    //プレビューボタン押下処理
    onStartButton(device, num) {
      if (this.axiosFlg == 0) {
        this.axiosFlg = 1;
        this.select(device, num);
        console.log("MngContents onStartButton Start");

        //ローディング画面表示
        this.showLoading = true;

        const condition = {
          contents_file: document.getElementById("contentupdatefile-id").value,
        };
        axios
          .create({
            responseType: "arraybuffer",
          })
          .get("/contentspreview/" + condition.contents_file, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            var contents = response.data;
            var unit8 = new Uint8Array(contents);

            if (contents.byteLength == 1 && unit8[0] == 1) {
              // エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              console.log("MngPlaylists onStartButton fauld");
            } else {
              //コンテンツタイプを取得
              let videoOrPicContentType = response.headers["content-type"];

              //動画ポップアップ表示
              if (
                videoOrPicContentType == "video/mp4" ||
                videoOrPicContentType == "video/webm"
              ) {
                this.blob = new Blob([contents], {
                  type: videoOrPicContentType,
                });
                this.movieData = window.URL.createObjectURL(this.blob);
                this.movieType = videoOrPicContentType;
                console.log("the movie is started");
                this.showMovie = true;
              }
              //画像ポップアップ表示
              else if (
                videoOrPicContentType == "image/bmp" ||
                videoOrPicContentType == "image/gif" ||
                videoOrPicContentType == "image/jpeg" ||
                videoOrPicContentType == "image/png"
              ) {
                this.blob = new Blob([contents], {
                  type: videoOrPicContentType,
                });
                this.pictureData = window.URL.createObjectURL(this.blob);
                console.log("the picture is started");
                this.showPicture = true;
              } else {
                //error処理
                throw "このデータの拡張子は対応していません。";
              }
              console.log("MngContents onStartButton Success");
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("MngContents onStartButton End");
            this.axiosFlg = 0;
            //ローディング画面終了
            this.showLoading = false;
          });
      }
    },

    //ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },

    // ソート機能(コンテンツ名)
    sortConNm() {
      console.log("MngContents sortConNm Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "contentsNm";
        // 昇順・降順の切替
        if (this.sortFlgConNm == 0) {
          this.sortDirection = 1;
          this.sortFlgConNm = 1;
          document.querySelector("#sortConNm").innerText = "コンテンツ名▼";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        } else {
          this.sortDirection = 0;
          this.sortFlgConNm = 0;
          document.querySelector("#sortConNm").innerText = "コンテンツ名▲";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngContents sortConNm End");
    },

    // ソート機能(ファイル名)
    sortConFl() {
      console.log("MngContents sortConFl Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "contentsFile";
        // 昇順・降順の切替
        if (this.sortFlgConFl == 0) {
          this.sortDirection = 1;
          this.sortFlgConFl = 1;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名▼";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        } else {
          this.sortDirection = 0;
          this.sortFlgConFl = 0;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名▲";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngContents sortConFl End");
    },

    // ソート機能(コンテンツ種別)
    sortConTyp() {
      console.log("MngContents sortConTyp Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "contentsTyp";
        // 昇順・降順の切替
        if (this.sortFlgConTyp == 0) {
          this.sortDirection = 1;
          this.sortFlgConTyp = 1;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別▼";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        } else {
          this.sortDirection = 0;
          this.sortFlgConTyp = 0;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別▲";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngContents sortConTyp End");
    },

    // ソート機能(紐付きプレイリスト数)
    sortPlayList() {
      console.log("MngContents sortPlayList Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "linkPlaylist";
        // 昇順・降順の切替
        if (this.sortFlgPlayList == 0) {
          this.sortDirection = 1;
          this.sortFlgPlayList = 1;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数▼";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        } else {
          this.sortDirection = 0;
          this.sortFlgPlayList = 0;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数▲";
          document.querySelector("#sortUploadDt").innerText = "アップロード日";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngContents sortPlayList End");
    },

    // ソート機能(アップロード日)
    sortUploadDt() {
      console.log("MngContents sortUploadDt Start");
      if (this.deviceList != null && this.deviceList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "uploadDt";
        // 昇順・降順の切替
        if (this.sortFlgUploadDt == 0) {
          this.sortDirection = 1;
          this.sortFlgUploadDt = 1;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgUploadDt = 0;
          document.querySelector("#sortConNm").innerText = "コンテンツ名";
          document.querySelector("#sortConFl").innerText = "ファイル名";
          document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
          document.querySelector("#sortPlayList").innerText =
            "紐付きプレイリスト数";
          document.querySelector("#sortUploadDt").innerText = "アップロード日▲";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("MngContents sortUploadDt End");
    },

    // 画面の初期化処理
    clearDisp() {
      // 一覧表示の値をクリア
      this.deviceList = [];

      // 検索結果、ページ遷移ボタンの非表示
      document.querySelector("div.div_search_memo").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";

      // ソートフラグの初期化
      this.sortFlgConNm = 0;
      this.sortFlgConFl = 0;
      this.sortFlgConTyp = 0;
      this.sortFlgPlayList = 0;
      this.sortFlgUploadDt = 0;

      // テーブルヘッダの初期化
      document.querySelector("#sortConNm").innerText = "コンテンツ名";
      document.querySelector("#sortConFl").innerText = "ファイル名";
      document.querySelector("#sortConTyp").innerText = "コンテンツ種別";
      document.querySelector("#sortPlayList").innerText =
        "紐付きプレイリスト数";
      document.querySelector("#sortUploadDt").innerText = "アップロード日";

      // 検索条件は初期化対象外
      // 入力欄のクリア
      // document.getElementById("content-id").value = null;
      // document.getElementById("content-typ").value = null;
      // document.getElementById("uploadstart-id").value = null;
      // document.getElementById("uploadend-id").value = null;

      // ファイル名、コンテンツ名のクリア
      document.querySelector("#contentupdatefile-id").value = null;
      document.querySelector("#contentupdate-id").value = null;
      // ファイル名、コンテンツ名の非活性化
      document.querySelector("#contentupdatefile-id").disabled = true;
      document.querySelector("#contentupdate-id").disabled = true;

      // 更新ボタン非活性化
      //document.querySelector("button.button2").disabled = true;
      //document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";

      //参照ボタンの非活性化
      document.querySelector("#label-input").disabled = true;
      document.querySelector('label[for="label-input"]').style["color"] =
        "white";
    },
  },
};
</script>

<style scoped>
.div-search {
  display: flex;
  padding: 15px 0 0 0;
}

.div-reg-el {
  display: flex;
  padding: 5px 0 0 0;
}

.div-content-nm,
.div-content-typ,
.div-upload-start,
.div-upload-end {
  width: 470px;
}

.div-content-nm,
.div-content-typ,
.div-reboot {
  display: inline-block;
}

.div-search-show {
  display: flex;
}

.div_search_memo {
  text-align: right;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.button3,
button.button4 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

button.create-btn {
  width: 70px;
  border-radius: 6px;
}

.div-label-wrapper {
  display: inline-block;
  width: 140px;
}

input[type="date"],
input[id="content-id"],
select[id="content-typ"],
select {
  width: 200px;
}
.div_search_btn {
  text-align: right;
  margin-left: auto;
}

/*ファイル名、コンテンツ名*/
input[id="contentupdatefile-id"],
input[id="contentupdate-id"],
select {
  width: 300px;
}

.btn {
  border-radius: 30px;
}

.div-search-result.items {
  margin-top: -1px;
  height: 275.6px;
  overflow-y: auto;
  overflow-x: hidden;
  width: fit-content;
  border-style: solid;
  border-width: 1px;
}

.div-regist {
  margin-top: 10px;
  width: 550px;
  display: inline-block;
}

.div-ctrl {
  display: flex;
  justify-content: flex-end;
}

.div-update-btn,
.div-insert-btn,
.div-delete-btn {
  margin-right: 20px;
}

.content-nm {
  width: 280px;
  border-bottom: 0px;
}
.pre {
  width: 35px;
  border-bottom: 0px;
  text-align: center;
}
.content-file {
  width: 300px;
  border-bottom: 0px;
}
.content-typ {
  width: 145px;
  border-bottom: 0px;
}
.playlist-nm {
  width: 200px;
  border-bottom: 0px;
}
.upload-dt {
  width: 180px;
  border-bottom: 0px;
}
td.upload-dt {
  border-right: 0px;
}

.targetTable {
  width: 1207px;
}

.selected {
  background-color: #0080ff20;
}

th {
  border-width: 1px;
  height: 30px;
}

#sortConNm,
#sortConFl,
#sortConTyp,
#sortPlayList,
#sortUploadDt {
  cursor: pointer;
}

tr.selected td {
  border-top-width: 1px;
  border-top-color: #0055ff59;
  border-bottom-width: 1px;
  border-bottom-color: #0055ff59;
}

.div-content-file,
.div-content-nm,
label[for="label-input"] {
  display: inline-block;
}

/*参照ボタン*/
label[for="label-input"] {
  color: #c5c5c5;
  background-color: rgb(239, 239, 239);
  border-style: outset;
  border-width: 2px;
  border-color: rgb(118, 118, 118);
  width: 70px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  height: 25px;
  padding-top: 3px;
  margin-left: 10px;
}

/*スタートボタン*/
.btn_start {
  width: 20px;
  padding: 5px 0px 0px 0px;
}

/*ポップアップ*/
.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

button.button5,
button.button6 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  height: 30.98px;
  margin-right: 20px;
}
button.button7,
button.button8 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  height: 30.98px;
}
/*Ok,キャンセルボタン*/
.div-reg-el2 {
  position: absolute;
  top: 200px;
  left: 300px;
}

.div-reg-el3,
.div-reg-el4 {
  position: absolute;
  top: 200px;
  left: 250px;
}

/*ページボタン*/
.div-page-btn {
  display: flex;
  justify-content: center;
}
button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
.div_last_upgrade_date {
  width: 800px;
  display: inline-block;
}
/*アップロード日のマージン調整*/
#date {
  margin-left: 10px;
  margin-right: 10px;
}
/*アップロード日*/
input[id="uploadstart-id"],
input[id="uploadend-id"] {
  width: 200px;
  height: 27px;
}
.div-notes {
  width: 260px;
  display: inline-block;
  vertical-align: top;
  margin-top: 30px;
  margin-left: 40px;
}
.div-notes-label {
  border: thin solid  #000000;
  padding: 6Px;
}
.p-notes {
  margin-left: 15px;
}
</style>