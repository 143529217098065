<template>
  <div class="menu">
    <div class="notupdated">
      <div class="level-1">
        <p
          id="Unaccessed"
          class="selected"
          @click="selItem"
          data-comp="Unaccessed"
        >
          未アクセス端末
        </p>
      </div>
    </div>
    <hr />
    <div class="manage">
      <div class="level-1">
        <p>管理</p>
      </div>
      <div class="level-2">
        <p id="MngAreas" @click="selItem" data-comp="MngAreas">
          地域グループ管理
        </p>
        <p id="MngProducts" @click="selItem" data-comp="MngProducts">
          商品グループ管理
        </p>
        <p id="MngStores" @click="selItem" data-comp="MngStores">店舗管理</p>
        <p id="MngDevices" @click="selItem" data-comp="MngDevices">端末管理</p>
        <p id="MngPlaylists" @click="selItem" data-comp="MngPlaylists">
          プレイリスト管理
        </p>
        <p id="MngContents" @click="selItem" data-comp="MngContents">
          コンテンツ管理
        </p>
      </div>
    </div>
    <hr />
    <div class="delivery">
      <div class="level-1">
        <p>設定</p>
      </div>
      <div class="level-2">
        <p id="CnfDelivery" @click="selItem" data-comp="CnfDelivery">
          プレイリスト配信設定
        </p>
        <p id="CnfUrgent" @click="selItem" data-comp="CnfUrgent">
          緊急配信設定
        </p>
      </div>
    </div>
    <hr />
    <div class="csv">
      <div class="level-1">
        <p id="CsvInprtExprt" @click="selItem" data-comp="CsvInprtExprt">
          CSV取込・出力
        </p>
      </div>
    </div>
    <hr />
    <div class="logout">
      <div class="level-1">
        <p @click="logout">ログアウト</p>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
const SELECTED = "selected";
var lastSelectLevel_1 = null;
var lastSelectLevel_2 = null;
export default {
  methods: {
    selItem: function (e) {
      const component = e.target.dataset.comp;
      this.$store.commit("transition", component);
      this.removeAllselectedOfLevel_1(component);
      this.removeAllselectedOfLevel_2(component);
      this.selectCategory(e);
    },
    removeAllselectedOfLevel_1: function (component) {
      let els = document.querySelectorAll(".level-1 p.selected");
      els.forEach((el) => {
        if (el.id != component) {
          el.classList.remove(SELECTED);
        }
      });
    },
    removeAllselectedOfLevel_2: function (component) {
      let els = document.querySelectorAll(".level-2 p.selected");
      els.forEach((el) => {
        if (el.id != component) {
          el.classList.remove(SELECTED);
        }
      });
    },
    selectCategory: function (e) {
      const target = e.target;

      if (target != lastSelectLevel_1) {
        target.classList.add(SELECTED);
        lastSelectLevel_1 = target;
      }
    },
    selectSubCategory: function (e) {
      const target = e.target;

      if (target != lastSelectLevel_2) {
        target.classList.add(SELECTED);
        lastSelectLevel_2 = target;
      }
    },
    logout: function () {
      this.$store.commit("logout");
      localStorage.removeItem("idToken");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
* {
  font-size: 18px;
}
.menu {
  width: 250px;
  height: 793px;
  color: white;
  background-color: #1a2f77;
  padding: 0;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: gray;
}

div p {
  cursor: pointer;
}

hr {
  border-width: 1px 0 0 0;
}

.selected {
  color: yellow;
}

.level-1 {
  padding: 5px 0 0 0;
  font-size: 28px;
}
.level-2 {
  padding: 5px 0 0 25px;
  font-size: 22px;
}

div {
  padding: 10px 20px;
}
</style>