<template>
  <div class="select">
    <div class="div_search">
      <div class="div_schedule_name">
        <div class="div_label-wrapper">
          <label>スケジュール名</label>
        </div>
        <input type="text" id="input_schedule_name" maxlength="30" />
      </div>
      <div class="div_playlist_name">
        <div class="div_label-wrapper">
          <label>プレイリスト名</label>
        </div>
        <input type="text" id="input_playlist_name" maxlength="30" />
      </div>
      <div class="div_local_group">
        <div class="div_label-wrapper">
          <label>地域グループ</label>
        </div>
        <select id="input_local_group">
          <option></option>
          <option
            v-for="option in areaOptions"
            v-bind:key="option.locId"
            v-bind:value="option.locId"
          >
            {{ option.locNm }}
          </option>
        </select>
      </div>
    </div>
    <div class="div_search">
      <div class="div_product_group">
        <div class="div_label-wrapper">
          <label>商品グループ</label>
        </div>
        <select id="input_product_group">
          <option></option>
          <option
            v-for="option in prodOptions"
            v-bind:key="option.prodId"
            v-bind:value="option.prodId"
          >
            {{ option.prodNm }}
          </option>
        </select>
      </div>
      <div class="div_select_device">
        <div class="div_label-wrapper">
          <label>端末指定</label>
        </div>
        <select id="select_device">
          <option></option>
          <option
            v-for="option in selectDeviceList"
            v-bind:key="option.id"
            v-bind:value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="div_delivary_condition">
        <div class="div_label-wrapper">
          <label>配信状況</label>
        </div>
        <select
          id="select_delivary_condition"
          name="selectbox_delivary_condition"
        >
          <option></option>
          <option
            v-for="option in delivaryConditionList"
            v-bind:key="option.id"
            v-bind:value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="div_search">
      <div class="div_delivery_day">
        <div class="div_label-wrapper">
          <label>配信日</label>
        </div>
        <input class="date" id="date_first" type="date" max="2999-12-31" />
        <label id="date">～</label>
        <input class="date" id="date_end" type="date" max="2999-12-31" />
      </div>
    </div>
    <div class="div-search-btn btn">
      <button class="button1" @click="onClickSearch">検索</button>
    </div>

    <div class="div-search-detail">
      <div class="div-search-show">
        <label class="table-title">プレイリスト配信一覧</label>
      </div>
      <div class="div_search_memo">
        <p>検索結果：{{ totalCount }} 件</p>
        <div class="div-page-btn">
          <button class="prev" @click="prevPage" disabled="true">≪</button>
          <p>{{ currentPage }} / {{ totalPage }}</p>
          <button class="next" @click="nextPage" disabled="true">≫</button>
        </div>
      </div>
    </div>

    <div class="div_search_result">
      <table class="targetTable">
        <thead>
          <tr>
            <th
              scope="col"
              id="sortScheduleId"
              @click="sortScheduleId"
              class="t_schedule_id"
            >
              スケジュールID
            </th>
            <th
              scope="col"
              id="sortScheduleNm"
              @click="sortScheduleNm"
              class="t_schedule_name"
            >
              スケジュール名
            </th>
            <th
              scope="col"
              id="sortPlaylistNm"
              @click="sortPlaylistNm"
              class="t_playlist_name"
            >
              プレイリスト名
            </th>
            <th
              scope="col"
              id="sortLocNm"
              @click="sortLocNm"
              class="t_local_group"
            >
              地域グループ
            </th>
            <th
              scope="col"
              id="sortProdNm"
              @click="sortProdNm"
              class="t_product_group"
            >
              商品グループ
            </th>
            <th
              scope="col"
              id="sortDesignation"
              @click="sortDesignation"
              class="t_select_device"
            >
              端末指定
            </th>
            <th
              scope="col"
              id="sortDeliveryDt"
              @click="sortDeliveryDt"
              class="t_delivery_day"
            >
              配信日
            </th>
            <th
              scope="col"
              id="sortDeliveryFlg"
              @click="sortDeliveryFlg"
              class="t_delivary_condition"
            >
              配信状況
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(row, key) in deliveryList"
            :key="key"
            @click="onPlaylist(row, key)"
          >
            <td class="t_schedule_id">{{ row.scheduleId }}</td>
            <td class="t_schedule_name">{{ row.scheduleNm }}</td>
            <td class="t_playlist_name">{{ row.playlistNm }}</td>
            <td class="t_local_group">{{ row.locNm }}</td>
            <td class="t_product_group">{{ row.prodNm }}</td>
            <td class="t_select_device">
              {{ row.designation == 1 ? "有" : "無" }}
            </td>
            <td class="t_delivery_day">{{ row.deliveryDt }}</td>
            <td class="t_delivary_condition">
              <p v-if="row.deliveryFlg == 0">未配信</p>
              <p v-else-if="row.deliveryFlg == 1">配信済</p>
              <p v-else>配信中</p>
            </td>
            <td class="t_schedule_id" style="display: none">
              {{ row.scheduleId }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="div_regist">
      <div class="tab_sel" btn>
        <button v-on:click="onClickCreate" class="create_btn">新規</button>
      </div>
      <div class="div_new_reg_el">
        <div class="div_new_schedule_name">
          <div class="div_label-wrapper">
            <label>スケジュール名</label>
          </div>
          <input
            type="text"
            class="textbox_new_select"
            id="select_schedule_name"
            disabled="true"
            maxlength="30"
          />
        </div>
        <div class="div_playlist_name">
          <div class="div_label-wrapper">
            <label>プレイリスト名</label>
          </div>
          <select id="select_playlist_name" disabled="true">
            <option></option>
            <option
              v-for="option in playlistOptions"
              v-bind:key="option.playlistId"
              v-bind:value="option.playlistId"
            >
              {{ option.playlistNm }}
            </option>
          </select>
        </div>
      </div>
      <div class="div_new_reg_el">
        <div class="div_new_local_group">
          <div class="div_label-wrapper">
            <label>地域グループ</label>
          </div>
          <select id="areaupdate-name" disabled="true" @change="pullChange">
            <option></option>
            <option
              v-for="option in areaOptions"
              v-bind:key="option.locId"
              v-bind:value="option.locId"
            >
              {{ option.locNm }}
            </option>
          </select>
        </div>
        <div class="div_new_product_group">
          <div class="div_label-wrapper">
            <label>商品グループ</label>
          </div>
          <select id="produpdate-name" disabled="true" @change="pullChange">
            <option></option>
            <option
              v-for="option in prodOptions"
              v-bind:key="option.prodId"
              v-bind:value="option.prodId"
            >
              {{ option.prodNm }}
            </option>
          </select>
        </div>
      </div>
      <div class="div_new_reg_el">
        <div class="div_new_select_device">
          <div class="div_label-wrapper">
            <label>端末指定</label>
          </div>
          <input
            type="text"
            class="textbox_new_select"
            id="select_select_device"
            @click="onSelect"
            value="指定なし"
            disabled="true"
            autocomplete="off"
            readonly
          />
          <button
            v-on:click="onSelectStatus"
            class="deliveryStatusbtn"
            disabled="true"
          >
            配信状況
          </button>
        </div>
        <div class="div_new_delivery_day">
          <div class="div_label-wrapper">
            <label>配信日</label>
          </div>
          <input
            type="date"
            class="textbox_new_select"
            id="select_delivery_date"
            disabled="true"
            max="2999-12-31"
          />
        </div>
      </div>

      <div class="div_reg_el">
        <div class="div_update_btn btn">
          <button @click="show('update')" class="button2" disabled="true">
            更新
          </button>
        </div>
        <div class="div_insert_btn btn">
          <button @click="show('register')" class="button3" disabled="true">
            登録
          </button>
        </div>
        <div class="div_delete_btn btn">
          <button @click="show('deletion')" class="button4" disabled="true">
            削除
          </button>
        </div>
      </div>
    </div>
    <cnfDeliveryChildeModel
      v-if="showModal"
      @parentMethod="updateSelectDevice"
      @close="showModal = false"
    ></cnfDeliveryChildeModel>
    <keep-alive>
      <cnfDeliveryStatusChildeModel
        v-if="showDeliveryStatusModal"
        @parentMethod="updateSelectDevice"
        @close="showDeliveryStatusModal = false"
        :fromscheduleid="forChildeScheduleId"
        :fromplaylistid="forChildePlaylistId"
      ></cnfDeliveryStatusChildeModel>
    </keep-alive>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @update="update"
      @register="register"
      @deletion="deletion"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import cnfDeliveryChildeModel from "./CnfDeliveryChilde";
import cnfDeliveryStatusChildeModel from "./CnfDeliveryStatusChilde";
import ModalWindow from "./ModalWindow";

export default {
  components: {
    cnfDeliveryChildeModel,
    cnfDeliveryStatusChildeModel,
    ModalWindow,
  },
  //このページに遷移時に処理される
  activated: function () {
    console.log("CnfDelivery is opend.");

    var date = new Date();
    date.setDate(date.getDate()); //本日の日付を取得
    var yyyy = date.getFullYear();
    var mm = ("0" + (date.getMonth() + 1)).slice(-2);
    var dd = ("0" + date.getDate()).slice(-2);
    date = yyyy + "-" + mm + "-" + dd;
    document.getElementById("select_delivery_date").setAttribute("min", date);

    Promise.all([
      axios.get("/loctype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
      axios.get("/prodtype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
      axios.get("/playlistnmtype", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        },
      }),
    ])
      .then(([response1, response2, response3]) => {
        console.log(
          "response1:",
          response1,
          ", response2:",
          response2,
          ", response3:",
          response3
        );
        this.areaOptions = response1.data.data;
        this.prodOptions = response2.data.data;
        this.playlistOptions = response3.data.data;
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.status === 401) {
          return;
        }
      });
  },

  data() {
    return {
      selectDeviceList: [
        // 端末指定
        { id: "0", name: "無" },
        { id: "1", name: "有" },
      ],
      delivaryConditionList: [
        // 配信状況
        { id: "0", name: "未配信" },
        { id: "1", name: "配信済" },
        { id: "2", name: "配信中" },
      ],
      areaOptions: [], // 地域グループプルダウン
      prodOptions: [], // 商品グループプルダウン
      playlistOptions: [], // プレイリストプルダウン
      modalTyp: "", //ポップアップ種別
      selectRow: null, //プレイリストの選択されている行
      totalCount: 0, // 検索結果
      currentPage: 1, // 現在ページ
      totalPage: 1, // 全ページ
      requestPage: 1, // 次遷移ページ
      sortItem: "scheduleId", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      showModal: false, //端末選択子画面
      showDeliveryStatusModal: false, //配信状況子画面
      currentclientId: [], //選択中の端末番号
      deliveryList: [], // 端末リスト
      sortFlgScheduleId: 0, // ソートフラグ(スケジュールID)
      sortFlgScheduleNm: 0, // ソートフラグ(スケジュール名)
      sortFlgPlaylistNm: 0, // ソートフラグ(プレイリスト名)
      sortFlgLocNm: 0, // ソートフラグ(地域グループ名)
      sortFlgProdNm: 0, // ソートフラグ(商品グループ名)
      sortFlgDesignation: 0, // ソートフラグ(端末指定)
      sortFlgDeliveryDt: 0, // ソートフラグ(配信日)
      sortFlgDeliveryFlg: 0, // ソートフラグ(配信状況)
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentScheduleId: "", // 選択中のスケジュールID(更新用)
      currentScheduleNm: "", // 検索スケジュール名保持用
      currentPlaylistNm: "", // 検索プレイリスト名保持用
      currentLocNm: "", // 検索地域グループ名保持用
      currentProdNm: "", // 検索商品グループ名保持用
      currentDesignation: "", // 検索端末指定保持用
      currentDeliveryFlg: "", // 検索配信状況保持用
      currentDeliveryDtStart: "", // 検索配信開始日保持用
      currentDeliveryDtEnd: "", // 検索配信終了日保持用
      forChildeScheduleId: "", // 配信状況子画面用スケジュールID(props用)
      forChildePlaylistId: "", // 配信状況子画面用プレイリストID(props用)
      currentPlaylistId: 0, // 配信状況子画面用プレイリストID(set用)
    };
  },

  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },

  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDelivery search Start");

        const condition = {
          scheduleNm: this.currentScheduleNm,
          playlistNm: this.currentPlaylistNm,
          locNm: this.currentLocNm,
          prodNm: this.currentProdNm,
          designation: this.currentDesignation,
          deliveryFlg: this.currentDeliveryFlg,
          deliveryDtStart: this.currentDeliveryDtStart,
          deliveryDtEnd: this.currentDeliveryDtEnd,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        axios
          .get(
            "/schedulesearch?" +
              "scheduleNm=" +
              encodeURIComponent(this.escape(condition.scheduleNm)) +
              "&" +
              "playlistNm=" +
              encodeURIComponent(this.escape(condition.playlistNm)) +
              "&" +
              "locNm=" +
              encodeURIComponent(this.escape(condition.locNm)) +
              "&" +
              "prodNm=" +
              encodeURIComponent(this.escape(condition.prodNm)) +
              "&" +
              "designation=" +
              encodeURIComponent(condition.designation) +
              "&" +
              "deliveryFlg=" +
              encodeURIComponent(condition.deliveryFlg) +
              "&" +
              "deliveryDtStart=" +
              encodeURIComponent(condition.deliveryDtStart) +
              "&" +
              "deliveryDtEnd=" +
              encodeURIComponent(condition.deliveryDtEnd) +
              "&" +
              "page=" +
              condition.page +
              "&" +
              "sortItem=" +
              condition.sortItem +
              "&" +
              "sortDirection=" +
              condition.sortDirection,
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            // 正常処理の場合
            if (response.data.status == 0 || response.data.status == 1) {
              this.deliveryList = response.data.data;
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;

              //テーブルが8行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.deliveryList != null) {
                if (this.deliveryList.length < 9) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";
              document.querySelector("div.div-page-btn").style.visibility =
                "visible";

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector("#sortScheduleId").innerText =
                  "スケジュールID";
                document.querySelector("#sortScheduleNm").innerText =
                  "スケジュール名";
                document.querySelector("#sortPlaylistNm").innerText =
                  "プレイリスト名";
                document.querySelector("#sortLocNm").innerText = "地域グループ";
                document.querySelector("#sortProdNm").innerText =
                  "商品グループ";
                document.querySelector("#sortDesignation").innerText =
                  "端末指定";
                document.querySelector("#sortDeliveryDt").innerText = "配信日";
                document.querySelector("#sortDeliveryFlg").innerText =
                  "配信状況";
              } else {
                // ページ遷移ボタンの活性状態切り替え
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prev").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = false;
                }
                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.next").disabled = false;
                }

                // 初回検索時のみ「スケジュール ▲」表示
                if (
                  this.sortItem === "scheduleId" &&
                  this.sortDirection === 0
                ) {
                  document.querySelector("#sortScheduleId").innerText =
                    "スケジュールID▲";
                }
              }

              // 前回選択行の背景色を解除
              if (this.selectRow != null) {
                this.selectRow.style["background-color"] = "transparent";
              }
              console.log("CnfDelivery search Success");
            }

            // if (this.playListItem != null && this.playListItem.length > 0) {
            //   document.querySelector(".targetTable").style.width = "auto";
            // } else {
            //   document.querySelector(".targetTable").style.width = "2700px";
            // }

            // その他のエラー
            if (response.data.status == 2) {
              console.log("CnfDelivery search ERROR2");
              // スケジュール名のクリア
              document.getElementById("input_schedule_name").value = null;
              // プレイリスト名のクリア
              document.getElementById("input_playlist_name").value = null;
              // 地域グループのクリア
              document.getElementById("input_local_group").value = null;
              // 商品グループのクリア
              document.getElementById("input_product_group").value = null;
              // 端末指定のクリア
              document.getElementById("select_device").value = null;
              // 配信状況のクリア
              document.getElementById("select_delivary_condition").value = null;
              // 配信日(開始日)のクリア
              document.getElementById("date_first").value = null;
              // 配信日(終了日)のクリア
              document.getElementById("date_end").value = null;
              // エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            // エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            document.querySelector(".div_search_result").scrollTop = 0;
            console.log("CnfDelivery search End");
            this.axiosFlg = 0;
          });
      }
    },

    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;

      // 日付のハイフンを削除
      var sourceStr1 = document.querySelector("#date_first").value;
      var datefirst = sourceStr1.replace(/-/g, "");
      var sourceStr2 = document.querySelector("#date_end").value;
      var dateend = sourceStr2.replace(/-/g, "");

      this.currentScheduleNm = document.querySelector(
        "#input_schedule_name"
      ).value;
      this.currentPlaylistNm = document.querySelector(
        "#input_playlist_name"
      ).value;
      var ilgObj = document.getElementById("input_local_group");
      this.currentLocNm = ilgObj.options[ilgObj.selectedIndex].text;
      var ipgObj = document.getElementById("input_product_group");
      this.currentProdNm = ipgObj.options[ipgObj.selectedIndex].text;
      this.currentDesignation = document.querySelector("#select_device").value;
      this.currentDeliveryFlg = document.querySelector(
        "#select_delivary_condition"
      ).value;
      this.currentDeliveryDtStart = datefirst;
      this.currentDeliveryDtEnd = dateend;

      // 検索メソッドの呼び出し
      this.search();
    },

    // 更新ボタン押下時の処理
    update() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDelivery update Start");
        // プレイリスト名の未入力チェック
        if (document.querySelector("#select_playlist_name").value == "") {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp = "プレイリスト名が未入力です。");
          return;
        }
        // 端末指定ありで、地域グループまたは商品グループが指定ありの場合、エラー
        if (
          document.querySelector("#select_select_device").value == "指定あり" &&
          (!document.querySelector("#areaupdate-name").value == "" ||
            !document.querySelector("#produpdate-name").value == "")
        ) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "地域グループと、<br>商品グループの指定がない場合のみ端末指定を行うことができます。");
          return;
        }
        //地域グループ、商品グループ、端末指定のすべてが未入力の場合、エラー
        if (
          document.querySelector("#select_select_device").value == "指定なし" &&
          document.querySelector("#areaupdate-name").value == "" &&
          document.querySelector("#produpdate-name").value == ""
        ) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "地域グループ、商品グループ、端末指定の1つは入力が必要です。");
          return;
        }

        // 日付チェック
        var inputDate = document.querySelector("#select_delivery_date").value;
        var date = new Date();
        date.setDate(date.getDate()); //本日の日付を取得
        var yyyy = date.getFullYear();
        var mm = ("0" + (date.getMonth() + 1)).slice(-2);
        var dd = ("0" + date.getDate()).slice(-2);
        date = yyyy + "-" + mm + "-" + dd;

        if (inputDate == "") {
          // 不正日付
          this.axiosFlg = 0;
          this.$refs.modal.modalError(), (this.modalTyp = "不正な日付です。");
          return;
        } else if (inputDate < date) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp = "本日以降の日付を選択してください。");
          return;
        }

        const condition = {
          scheduleId: this.currentScheduleId, // 選択中のスケジュールID(hidden項目)
          scheduleNm: document.querySelector("#select_schedule_name").value,
          playlistId: document.querySelector("#select_playlist_name").value,
          locId: document.querySelector("#areaupdate-name").value,
          prodId: document.querySelector("#produpdate-name").value,
          deliveryDt: document.querySelector("#select_delivery_date").value,
          data: this.currentclientId, // 子画面で選択した端末番号の配列
        };

        let searchFlag = false;
        axios
          .post("/scheduleup", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("CnfDelivery update Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("CnfDelivery update ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // データが重複する場合
            if (response.data.status == 2) {
              console.log("CnfDelivery update ERROR2");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }

            // 該当のデータが存在する場合
            if (response.data.status == 3) {
              console.log("CnfDelivery update ERROR3");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("CnfDelivery update End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //登録ボタン押下時の処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDelivery register Start");
        //スケジュール名または、プレイリスト名の未入力チェック
        if (
          document.querySelector("#select_schedule_name").value == "" ||
          document.querySelector("#select_playlist_name").value == ""
        ) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "スケジュール名または、<br>プレイリスト名が未入力です。");
          return;
        }
        //端末指定ありで、地域グループまたは商品グループが指定ありの場合、エラー
        if (
          document.querySelector("#select_select_device").value == "指定あり" &&
          (!document.querySelector("#areaupdate-name").value == "" ||
            !document.querySelector("#produpdate-name").value == "")
        ) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "地域グループと、<br>商品グループの指定がない場合のみ<br>端末指定を行うことができます。");
          return;
        }
        //地域グループ、商品グループ、端末指定のすべてが未入力の場合、エラー
        if (
          this.currentclientId.length == 0 &&
          document.querySelector("#areaupdate-name").value == "" &&
          document.querySelector("#produpdate-name").value == ""
        ) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp =
              "地域グループ、商品グループ、端末指定の1つは入力が必要です。");
          return;
        }

        // 日付チェック
        var inputDate = document.querySelector("#select_delivery_date").value;
        var date = new Date();
        date.setDate(date.getDate()); //本日の日付を取得
        var yyyy = date.getFullYear();
        var mm = ("0" + (date.getMonth() + 1)).slice(-2);
        var dd = ("0" + date.getDate()).slice(-2);
        date = yyyy + "-" + mm + "-" + dd;

        if (inputDate == "") {
          // 不正日付
          this.axiosFlg = 0;
          this.$refs.modal.modalError(), (this.modalTyp = "不正な日付です。");
          return;
        } else if (inputDate < date) {
          this.axiosFlg = 0;
          this.$refs.modal.modalError(),
            (this.modalTyp = "本日以降の日付を選択してください。");
          return;
        }

        const condition = {
          scheduleNm: document.querySelector("#select_schedule_name").value,
          playlistId: document.querySelector("#select_playlist_name").value,
          locId: document.querySelector("#areaupdate-name").value,
          prodId: document.querySelector("#produpdate-name").value,
          deliveryDt: document.querySelector("#select_delivery_date").value,
          data: this.currentclientId,
        };

        let searchFlag = false;
        axios
          .post("/schedulein", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("CnfDelivery register Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }
            // データが重複する場合
            if (response.data.status == 1) {
              console.log("CnfDelivery register ERROR1");
              //エラーポップアップを表示「すでに登録されているデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common04");
              return;
            }
            // その他のエラー
            if (response.data.status == 2) {
              console.log("CnfDelivery register ERROR2");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("CnfDelivery register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //削除ボタン押下時の処理
    deletion() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfDelivery deletion Start");
        const condition = {
          scheduleId: this.currentScheduleId,
        };

        let searchFlag = false;
        axios
          .post("/scheduledel", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              // 画面の初期化
              this.clearDisp();
              console.log("CnfDelivery deletion Success");
              //完了ポップアップ表示
              this.$refs.modal.modalCompletion();
              searchFlag = true;
            }

            // その他のエラー
            if (response.data.status == 1) {
              console.log("CnfDelivery deletion ERROR1");
              //エラーポップアップを表示「予期せぬエラーが発生しました。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
              return;
            }

            // 該当のデータが存在しない場合
            if (response.data.status == 2) {
              console.log("CnfDelivery deletion ERROR2");
              //エラーポップアップを表示「すでに存在しないデータです。」
              this.$refs.modal.modalCommon(), (this.modalTyp = "common05");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示「予期せぬエラーが発生しました。」
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
            return;
          })
          .finally(() => {
            console.log("CnfDelivery deletion End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },

    //プレイリスト一覧を押下時の処理
    onPlaylist(row, key) {
      console.log("CnfDelivery onPlaylist Start");
      // スケジュール名のテキストボックスを非活性化
      document.querySelector("#select_schedule_name").disabled = true;

      // スケジュール名以外のテキストボックスを活性化
      document.querySelector("#select_playlist_name").disabled = false;
      document.querySelector("#areaupdate-name").disabled = false;
      document.querySelector("#produpdate-name").disabled = false;
      document.querySelector("#select_select_device").disabled = false;
      document.querySelector("#select_delivery_date").disabled = false;

      // 日付の/を-に変更
      var sourceStr1 = row.deliveryDt;
      var date = sourceStr1.replace("（指定あり）", "");
      date = date.replace("（指定なし）", "");
      date = date.replace(/\//g, "-");

      console.log(date);

      // 選択されたプレイリストの内容をテキストボックスに反映
      document.querySelector("#select_schedule_name").value = row.scheduleNm;
      document.querySelector("#select_playlist_name").value = row.playlistNm;
      document.querySelector("#select_delivery_date").value = date;
      this.currentScheduleId = row.scheduleId;
      document.getElementById("select_select_device").style.color = "black";
      document.getElementById("select_delivery_date").style.color = "black";

      // 商品グループ名プルダウンの設定
      document.querySelector("#produpdate-name").value = null;
      for (var i = 0; i < this.prodOptions.length; i++) {
        if (row.prodNm == this.prodOptions[i].prodNm) {
          document.querySelector("#produpdate-name").value =
            this.prodOptions[i].prodId;
          break;
        }
      }

      // 地域グループ名プルダウンの設定
      document.querySelector("#areaupdate-name").value = null;
      for (i = 0; i < this.areaOptions.length; i++) {
        if (row.locNm == this.areaOptions[i].locNm) {
          document.querySelector("#areaupdate-name").value =
            this.areaOptions[i].locId;
          break;
        }
      }

      // プレイリスト名プルダウンの設定
      document.querySelector("#select_playlist_name").value = null;
      for (i = 0; i < this.playlistOptions.length; i++) {
        if (row.playlistNm == this.playlistOptions[i].playlistNm) {
          document.querySelector("#select_playlist_name").value =
            this.playlistOptions[i].playlistId;
          break;
        }
      }

      // 端末指定の設定
      if (row.designation == 1) {
        document.querySelector("#select_select_device").value = "指定あり";
      } else {
        document.querySelector("#select_select_device").value = "指定なし";
      }

      // 配信状況ボタン
      if (row.deliveryFlg == 2) {
        document.querySelector("button.deliveryStatusbtn").disabled = false;
        document.querySelector(".deliveryStatusbtn").style["background-color"] =
          "#1a2f77";
      } else {
        document.querySelector("button.deliveryStatusbtn").disabled = true;
        document.querySelector(".deliveryStatusbtn").style["background-color"] =
          "gray";
      }

      //配信状況子画面用プレイリストID(set用)
      this.currentPlaylistId = row.playlistId;

      //更新ボタン活性化
      document.querySelector("button.button2").disabled = false;
      document.querySelector(".button2").style["background-color"] = "#1a2f77";
      //登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      //削除ボタン活性化
      document.querySelector("button.button4").disabled = false;
      document.querySelector(".button4").style["background-color"] = "#1a2f77";

      //前回選択されていた場合選択箇所の色を変更
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }

      //選択行の色を変更
      this.selectRow =
        document.querySelector(".targetTable").lastElementChild.children[key];
      this.selectRow.style["background-color"] = "#0080ff20";

      console.log("CnfDelivery onPlaylist End");
    },

    //新規ボタン押下時の処理
    onClickCreate() {
      console.log("CnfDelivery onClickCreate Start");
      // テキストボックスを活性化
      document.querySelector("#select_schedule_name").disabled = false;
      document.querySelector("#select_playlist_name").disabled = false;
      document.querySelector("#areaupdate-name").disabled = false;
      document.querySelector("#produpdate-name").disabled = false;
      document.querySelector("#select_select_device").disabled = false;
      document.querySelector("#select_delivery_date").disabled = false;

      // テキストボックスのクリア
      document.querySelector("#select_schedule_name").value = null;
      document.querySelector("#select_select_device").value = "指定なし";
      document.getElementById("select_select_device").style.color = "black";
      this.currentclientId = [];
      var date = new Date();
      date.setDate(date.getDate()); //本日の日付を取得
      var yyyy = date.getFullYear();
      var mm = ("0" + (date.getMonth() + 1)).slice(-2);
      var dd = ("0" + date.getDate()).slice(-2);
      date = yyyy + "-" + mm + "-" + dd;
      document.querySelector("#select_delivery_date").value = date;
      document.getElementById("select_delivery_date").style.color = "black";

      // プルダウンのクリア
      document.querySelector("#select_playlist_name").value = null;
      document.querySelector("#areaupdate-name").value = null;
      document.querySelector("#produpdate-name").value = null;

      //プレイリストに選択行があった場合、選択を外す。
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
        this.selectRow = null;
      }
      //更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      //登録ボタンの活性化
      document.querySelector("button.button3").disabled = false;
      document.querySelector(".button3").style["background-color"] = "#1a2f77";
      //削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";

      //配信状況ボタン非活性化
      document.querySelector("button.deliveryStatusbtn").disabled = true;
      document.querySelector(".deliveryStatusbtn").style["background-color"] =
        "gray";

      console.log("CnfDelivery onClickCreate End");
    },

    //戻るボタン押下時の処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },

    //進むボタン押下時の処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
      }
    },

    //端末指定欄押下時
    onSelect() {
      //子画面を表示する
      this.showModal = true;
    },

    //配信状況ボタン押下時
    onSelectStatus() {
      //配信状況子画面に、選択中データのスケジュールIDを渡す
      this.forChildeScheduleId = this.currentScheduleId;
      //配信状況子画面に、選択中データのプレイリストIDを渡す
      this.forChildePlaylistId = this.currentPlaylistId;
      //配信状況子画面を表示する
      this.showDeliveryStatusModal = true;
    },

    //子画面で決定ボタン押下
    updateSelectDevice(clientId) {
      this.currentclientId = clientId;
      console.log(this.currentclientId);
      //子画面で端末が選択された場合のみ端末番号欄に指定ありを表示
      if (clientId != "") {
        document.getElementById("select_select_device").value = "指定あり";
      }

      // 商品グループ名、地域グループ名を初期化
      document.querySelector("#areaupdate-name").value = null;
      document.querySelector("#produpdate-name").value = null;

      //ダイアログを閉じる
      this.showModal = false;
    },

    //ポップアップ表示処理
    show(modaltyp) {
      //登録、更新、削除ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },

    // ソート機能(スケジュールID)
    sortScheduleId() {
      console.log("CnfDelivery sortScheduleId Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "scheduleId";
        // 昇順・降順の切替
        if (this.sortFlgScheduleId == 0) {
          this.sortDirection = 1;
          this.sortFlgScheduleId = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID▼";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgScheduleId = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID▲";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortScheduleId End");
    },

    // ソート機能(スケジュール名)
    sortScheduleNm() {
      console.log("CnfDelivery sortScheduleNm Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "scheduleNm";
        // 昇順・降順の切替
        if (this.sortFlgScheduleNm == 0) {
          this.sortDirection = 1;
          this.sortFlgScheduleNm = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名▼";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgScheduleNm = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名▲";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortScheduleNm End");
    },

    // ソート機能(プレイリスト名)
    sortPlaylistNm() {
      console.log("CnfDelivery sortPlaylistNm Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistNm";
        // 昇順・降順の切替
        if (this.sortFlgPlaylistNm == 0) {
          this.sortDirection = 1;
          this.sortFlgPlaylistNm = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名▼";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgPlaylistNm = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名▲";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortPlaylistNm End");
    },

    // ソート機能(地域グループ)
    sortLocNm() {
      console.log("CnfDelivery sortLocNm Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "locNm";
        // 昇順・降順の切替
        if (this.sortFlgLocNm == 0) {
          this.sortDirection = 1;
          this.sortFlgLocNm = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ▼";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgLocNm = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ▲";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortLocNm End");
    },

    // ソート機能(商品グループ)
    sortProdNm() {
      console.log("CnfDelivery sortProdNm Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "prodNm";
        // 昇順・降順の切替
        if (this.sortFlgProdNm == 0) {
          this.sortDirection = 1;
          this.sortFlgProdNm = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ▼";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgProdNm = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ▲";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortProdNm End");
    },

    // ソート機能(端末指定)
    sortDesignation() {
      console.log("CnfDelivery sortDesignation Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "designation";
        // 昇順・降順の切替
        if (this.sortFlgDesignation == 0) {
          this.sortDirection = 1;
          this.sortFlgDesignation = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定▼";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgDesignation = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定▲";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortDesignation End");
    },

    // ソート機能(配信日)
    sortDeliveryDt() {
      console.log("CnfDelivery sortDeliveryDt Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "deliveryDt";
        // 昇順・降順の切替
        if (this.sortFlgDeliveryDt == 0) {
          this.sortDirection = 1;
          this.sortFlgDeliveryDt = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日▼";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        } else {
          this.sortDirection = 0;
          this.sortFlgDeliveryDt = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日▲";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortDeliveryDt End");
    },

    // ソート機能(配信状況)
    sortDeliveryFlg() {
      console.log("CnfDelivery sortDeliveryFlg Start");
      if (this.deliveryList != null && this.deliveryList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "deliveryFlg";
        // 昇順・降順の切替
        if (this.sortFlgDeliveryFlg == 0) {
          this.sortDirection = 1;
          this.sortFlgDeliveryFlg = 1;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgDeliveryFlg = 0;
          document.querySelector("#sortScheduleId").innerText =
            "スケジュールID";
          document.querySelector("#sortScheduleNm").innerText =
            "スケジュール名";
          document.querySelector("#sortPlaylistNm").innerText =
            "プレイリスト名";
          document.querySelector("#sortLocNm").innerText = "地域グループ";
          document.querySelector("#sortProdNm").innerText = "商品グループ";
          document.querySelector("#sortDesignation").innerText = "端末指定";
          document.querySelector("#sortDeliveryDt").innerText = "配信日";
          document.querySelector("#sortDeliveryFlg").innerText = "配信状況▲";
        }
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfDelivery sortDeliveryFlg End");
    },

    // 画面の初期化処理
    clearDisp() {
      // 一覧表示の値をクリア
      this.deliveryList = [];

      // 検索結果、ページ遷移ボタンの非表示
      document.querySelector("div.div_search_memo").style.visibility = "hidden";
      document.querySelector("div.div-page-btn").style.visibility = "hidden";

      // ソートフラグの初期化
      this.sortFlgScheduleId = 0;
      this.sortFlgScheduleNm = 0;
      this.sortFlgPlaylistNm = 0;
      this.sortFlgLocNm = 0;
      this.sortFlgProdNm = 0;
      this.sortFlgDesignation = 0;
      this.sortFlgDeliveryDt = 0;
      this.sortFlgDeliveryFlg = 0;

      // テーブルヘッダの初期化
      document.querySelector("#sortScheduleId").innerText = "スケジュールID";
      document.querySelector("#sortScheduleNm").innerText = "スケジュール名";
      document.querySelector("#sortPlaylistNm").innerText = "プレイリスト名";
      document.querySelector("#sortLocNm").innerText = "地域グループ";
      document.querySelector("#sortProdNm").innerText = "商品グループ";
      document.querySelector("#sortDesignation").innerText = "端末指定";
      document.querySelector("#sortDeliveryDt").innerText = "配信日";
      document.querySelector("#sortDeliveryFlg").innerText = "配信状況";

      // テキストボックスを非活性化
      document.querySelector("#select_schedule_name").disabled = true;
      document.querySelector("#select_playlist_name").disabled = true;
      document.querySelector("#areaupdate-name").disabled = true;
      document.querySelector("#produpdate-name").disabled = true;
      document.querySelector("#select_select_device").disabled = true;
      document.querySelector("#select_delivery_date").disabled = true;

      // 検索条件は初期化対象外
      // テキストボックスのクリア
      // document.querySelector("#input_schedule_name").value = null;
      // document.querySelector("#input_playlist_name").value = null;
      // document.querySelector("#input_local_group").value = null;
      // document.querySelector("#input_product_group").value = null;
      // document.querySelector("#select_device").value = null;
      // document.querySelector("#select_delivary_condition").value = null;
      // document.querySelector("#date_first").value = null;
      // document.querySelector("#date_end").value = null;

      document.querySelector("#select_schedule_name").value = null;
      // document.querySelector("#select_playlist_name").value = this.playlistOptions[0].playlistId;
      // document.querySelector("#select_local_group").value = this.areaOptions[0].locId;
      // document.querySelector("#select_product_group").value = this.prodOptions[0].prodId;
      document.querySelector("#select_playlist_name").value = null;
      document.querySelector("#areaupdate-name").value = null;
      document.querySelector("#produpdate-name").value = null;
      document.querySelector("#select_select_device").value = "指定なし";
      this.currentclientId = [];
      document.querySelector("#select_delivery_date").value = null;
      document.getElementById("select_select_device").style.color = "#CCCCCC";
      document.getElementById("select_delivery_date").style.color = "#CCCCCC";

      // 更新ボタン非活性化
      document.querySelector("button.button2").disabled = true;
      document.querySelector(".button2").style["background-color"] = "gray";
      // 登録ボタン非活性化
      document.querySelector("button.button3").disabled = true;
      document.querySelector(".button3").style["background-color"] = "gray";
      // 削除ボタン非活性化
      document.querySelector("button.button4").disabled = true;
      document.querySelector(".button4").style["background-color"] = "gray";
    },

    pullChange() {
      document.querySelector("#select_select_device").value = "指定なし";
      this.currentclientId = [];
    },
  },
};
</script>

<style scoped>
/*----------------------------common----------------------------------*/
/* body {
  font-family: "メイリオ", Meiryo, "ＭＳ ゴシック" sans-serif;
} */

.content {
  /* min-width: 1200px; */
  overflow: auto;
  padding: 10px 30px;
  font-size: 18px;
  vertical-align: top;
}

button {
  width: 120px;
  font-size: 18px;
}

button.tab {
  background-color: white;
  border-style: solid;
  border-width: 0;
}

button.tab.selected {
  border-width: 1px;
}
/*--------------------------------------------------------------------*/

body {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.div_search,
.div_reg_el {
  display: flex;
  padding: 11px 0 0 0;
}

/*-------入力フィールド-----------*/
.div_schedule_name,
.div_playlist_name,
.div_local_group,
.div_product_group,
.div_select_device,
.div_delivary_condition {
  width: 400px;
  display: inline-block;
  /* margin-right: 100px; */
}
.div_delivery_day {
  /* width: 600px; */
  display: inline-block;
  /* margin-right: 100px; */
}

.div_new_schedule_name,
.dev_new_playlist_name,
.div_new_local_group,
.div_new_product_group,
.div_new_select_device,
.div_new_delivery_day,
.dev_new_delivary_condition {
  width: 550px;
  display: inline-block;
  /* margin-right: 100px; */
}

.div_label-wrapper {
  display: inline-block;
  width: 130px;
}

/* input[type="text"],
select {
  width: 200px;
} */

/* select {
  height: 30px;
  width: 205px;
} */

/* input[type="date"] {
  width: 260px;
} */

/* #date_first {
  width: 200px;
}

#date_end {
  width: 200px;
} */

.div_regist {
  margin-top: 15px;
}

/*-------------------------------*/
/*------------ボタン--------------*/

.div_search_show {
  display: flex;
}

.div_search_title {
  display: flex;
  flex-basis: 88%;
  justify-content: left;
  align-items: left;
  margin-top: 50px;
}

.div_search_btn {
  text-align: center;
}

.div_search_btn p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.button2,
button.button3,
button.button4 {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

button.create_btn {
  width: 70px;
  border-radius: 6px;
  margin-top: -5px;
}

/*配信状況ボタン*/
button.deliveryStatusbtn {
  background-color: gray;
  color: white;
  border-radius: 6px;
}

.btn {
  border-radius: 30px; /*border-radius:角丸の半径;*/
}
.div_update_btn,
.div_insert_btn,
.div_delete_btn {
  margin-right: 20px;
}
/*--------------------------------*/
/*---------プレイリスト一覧--------*/

.targetTable {
  width: 2700px;
}
/*
.div_search_result.items {
  margin-top: -1px;
  height: 221px;
  overflow-y: auto;
  border-style: solid;
  border-width: 1px;
}*/

.div_reg_el:last-child {
  display: flex;
  justify-content: flex-end;
}

.t_schedule_id {
  width: 200px;
}
.t_schedule_name {
  width: 500px;
}
.t_playlist_name {
  width: 500px;
}
.t_local_group {
  width: 350px;
}
.t_product_group {
  width: 350px;
}
.t_select_device {
  width: 100px;
}
.t_delivery_day {
  width: 200px;
}
.t_delivary_condition {
  width: 100px;
}

.div_new_reg_el {
  padding: 3px 10px;
}

.table_border_style_hidden tr td {
  border-style: hidden;
}

tr.selected {
  background-color: #0080ff20;
}

tr.selected td {
  border-top-width: 1px;
  border-top-color: #0055ff59;
  border-bottom-width: 1px;
  border-bottom-color: #0055ff59;
}

.page_button {
  height: 10px;
  width: 20px;
}
.page_num {
  white-space: nowrap;
}

.div-page-btn {
  display: flex;
  justify-content: center;
}

.div-search-btn {
  text-align: right;
  margin-left: auto;
  margin-top: -25px;
}

button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}

.div-search-detail {
  display: flex;
}

.div-search-show {
  display: flex;
  flex-basis: 80%;
  align-items: center;
  width: 200px;
}

.div_search_memo {
  text-align: right;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

td.t_delivary_condition {
  border-right: 0px;
}

/*最終更新日のマージン調整*/
#date {
  margin-left: 10px;
  margin-right: 10px;
}

#select_select_device {
  color: #cccccc;
}

#select_delivery_date {
  color: #cccccc;
}

.div_search_result {
  margin-top: 0;
  height: 260px;
  width: 1200px;
  overflow-y: auto;
  overflow-x: scroll;
  border-style: solid;
  border-width: 1px;
}

.div_search_result table {
  width: 2000px;
  border-top: hidden;
  border-collapse: separate;
}

.div_search_result thead th {
  position: sticky; /*固定する*/
  position: -webkit-sticky;
  top: 0; /*ブラウザの上からの距離はゼロ*/
  border-bottom: 1px solid black;
  border-top: hidden;
  border-left: 0px solid black;
  border-top: 0px solid black;
}
</style>
