<template>
  <div>
    <div class="select">
      <div class="div_playlist_nm">
        <div class="div_label-wrapper">
          <label>プレイリスト名</label>
        </div>
        <input type="text" value="" id="playlist-nm" maxlength="30" />
      </div>
    </div>

    <div class="div_search_btn btn">
      <button style="margin-left: 30px" class="button1" @click="onClickSearch">
        検索
      </button>
    </div>
    <div class="div_search_detail">
      <div class="div_search_show">
        <label class="table-title">プレイリスト一覧</label>
      </div>

      <div class="div_search_memo">
        <p>検索結果：{{ totalCount }} 件</p>
        <div>
          <div class="div-page-btn">
            <button class="prev" @click="prevPage" disabled="true">≪</button>
            <p>{{ currentPage }} / {{ totalPage }}</p>
            <button class="next" @click="nextPage" disabled="true">≫</button>
          </div>
        </div>
      </div>
    </div>

    <div class="div_search_result title">
      <table>
        <thead>
          <tr>
            <th scope="col" class="playlist_nm" @click="sortPlayList">
              プレイリスト名
            </th>
            <th scope="col" class="delivery_day" @click="sortDeliveryDt">
              配信日
            </th>
            <!-- <th scope="col" class="muji"></th> -->
          </tr>
        </thead>
      </table>
    </div>

    <div class="div-search-result items">
      <table class="targetTable">
        <tbody>
          <tr
            v-for="(data, num) in emergencyList"
            v-bind:key="data.playlistNm"
            @click="select(data, num)"
          >
            <td class="playlist_nm">{{ data.playlistNm }}</td>
            <td class="delivery_day">{{ data.deliveryDt }}</td>
            <!-- <td class="muji"></td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="div_regist">
      <div class="div_device_no">
        <div class="div_label-wrapper">
          <label>端末番号</label>
        </div>
        <input
          type="text"
          id="device-id"
          value=""
          @click="onSelect"
          autocomplete="off"
        />
      </div>

      <div class="div_reg_el">
        <div class="div_emergency_delivery_btn">
          <button
            class="emergency"
            id="emergency-btn"
            type="submit"
            disabled="true"
            @click="show('register')"
          >
            緊急配信
          </button>
        </div>
      </div>
    </div>
    <cnfUrgentChildeModel
      v-if="showModal"
      @parentMethod="updateSelectDevice"
      @close="showModal = false"
    ></cnfUrgentChildeModel>
    <ModalWindow
      ref="modal"
      :modaltyp="modalTyp"
      @register="register"
    ></ModalWindow>
  </div>
</template>

<script>
import axios from "axios";
import cnfUrgentChildeModel from "./CnfUrgentChilde";
import ModalWindow from "./ModalWindow";

export default {
  components: {
    cnfUrgentChildeModel,
    ModalWindow,
  },

  data() {
    return {
      emergencyList: [],
      totalCount: 0,
      currentPage: 1,
      totalPage: 1,
      selectRow: null, //選択行
      requestPage: 1, //ページ数
      sortItem: "playlistNm", //ソートを行う項目(サーバへ送るパラメータ)
      sortDirection: 0, //ソートの昇順・降順の指定(サーバへ送るパラメータ 0:ASK,1:DESK)
      sortFlgplayList: 0, // ソートフラグ(プレイリスト名)
      sortFlgdeliveryDt: 0, // ソートフラグ(配信日)
      currentplaylistId: null, //選択中のプレイリストID
      currentdeviceId: [], //選択中の端末番号
      showModal: false,
      modalTyp: "", //ポップアップ種別
      axiosFlg: 0, // 通信フラグ(連打対応)
      currentPlaylistNm: "", // 検索プレイリスト名保持用
    };
  },

  computed: {
    idToken() {
      return this.$store.getters.idToken;
    },
  },

  methods: {
    // 共通検索処理
    search() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfUrgent search Start");

        const condition = {
          playlistNm: this.currentPlaylistNm,
          page: this.requestPage,
          sortItem: this.sortItem,
          sortDirection: this.sortDirection,
        };

        axios
          .get(
            "/emergency/search?" +
              "playlistNm=" +
              encodeURIComponent(this.escape(condition.playlistNm)) +
              "&" +
              "page=" +
              condition.page +
              "&" +
              "sortItem=" +
              condition.sortItem +
              "&" +
              "sortDirection=" +
              condition.sortDirection,
            {
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.status);

            if (response.data.status == 0 || response.data.status == 1) {
              this.currentPage = response.data.currentPage;
              this.totalPage = response.data.totalPage;
              this.totalCount = response.data.number;
              this.emergencyList = response.data.data;

              //テーブルが7行以下の場合は、下線を表示する
              document.querySelector(".targetTable").style["border-bottom"] =
                "hidden";

              if (this.emergencyList != null) {
                if (this.emergencyList.length < 8) {
                  document.querySelector(".targetTable").style[
                    "border-bottom"
                  ] = "dotted 1px";
                }
              }

              // 検索結果、ページ遷移ボタンの表示
              document.querySelector("div.div_search_memo").style.visibility =
                "visible";
              document.querySelector("div.div-page-btn").style.visibility =
                "visible";

              // 初回検索時はプレイリスト名の昇順
              if (this.sortItem === "playlistNm" && this.sortDirection === 0) {
                document.querySelector(".playlist_nm").innerText =
                  "プレイリスト名▲";
              }

              // 緊急配信ボタンの活性化
              document.querySelector("button.emergency").disabled = false;
              document.querySelector(".emergency").style["background-color"] =
                "#1a2f77";

              //端末番号のテキストボックスを活性化
              document.querySelector("#device-id").disabled = false;

              // 検索結果0件の時、ページ遷移ボタン非表示
              if (this.totalCount === 0) {
                document.querySelector("div.div-page-btn").style.visibility =
                  "hidden";
                document.querySelector(".playlist_nm").innerText =
                  "プレイリスト名";
                document.querySelector(".delivery_day").innerText = "配信日";
              } else {
                if (
                  response.data.currentPage === 1 ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.prev").disabled = true;
                } else {
                  document.querySelector("button.prev").disabled = false;
                }

                if (
                  response.data.currentPage === response.data.totalPage ||
                  response.data.totalPage < 2
                ) {
                  document.querySelector("button.next").disabled = true;
                } else {
                  document.querySelector("button.next").disabled = false;
                }
              }
              document.querySelector(".targetTable").style.width = "auto";
              console.log("CnfUrgent search Success");
            }
            //その他DBエラー（構文エラー、DB接続失敗）の場合
            else if (response.data.status == 2) {
              console.log("CnfUrgent search ERROR2");
              this.$refs.modal.modalError(),
                (this.modalTyp = "プレイリストの検索に失敗しました。");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            document.querySelector(".div-search-result.items").scrollTop = 0;
            console.log("CnfUrgent search End");
            this.axiosFlg = 0;
          });
      }
    },
    // 検索ボタン押下時処理
    onClickSearch() {
      this.requestPage = 1;
      this.currentPlaylistNm = document.getElementById("playlist-nm").value;
      // 検索メソッドの呼び出し
      this.search();
    },
    //前ボタン押下処理
    prevPage() {
      if (this.currentPage > 1) {
        this.requestPage = this.currentPage - 1;
        this.search();
      }
    },
    //次ボタン押下処理
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.requestPage = this.currentPage + 1;
        this.search();
      }
    },
    //端末番号欄押下時
    onSelect() {
      //子画面を表示する
      this.showModal = true;
    },

    //緊急配信ボタン押下処理
    register() {
      if (this.axiosFlg === 0) {
        this.axiosFlg = 1;
        console.log("CnfUrgent register Start");

        //プレイリスト名の空白判定
        if (this.currentplaylistId == null) {
          this.axiosFlg = 0;
          //未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp = "緊急配信するプレイリストを選択してください。");
          return;
        }
        //端末番号の空白判定
        if (document.getElementById("device-id").value == "") {
          this.axiosFlg = 0;
          //未入力エラーポップアップ表示
          this.$refs.modal.modalError(),
            (this.modalTyp = "端末番号を指定してください。");
          return;
        }

        const condition = {
          clientList: this.currentdeviceId, //子画面から取得した端末番号
          playlistId: this.currentplaylistId, //選択行のプレイリストID
        };

        let searchFlag = false;
        axios
          .post("/emergency/save/", condition, {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
            },
          })
          .then((response) => {
            if (response.data.status == 0) {
              console.log("CnfUrgent register Success");

              //完了ポップアップを表示
              this.$refs.modal.modalCompletion();

              //端末番号をクリア
              document.getElementById("device-id").value = "";
              searchFlag = true;
            }
            //1:DBエラー（構文エラー、DB接続失敗）
            else if (response.data.status == 1) {
              console.log("CnfUrgent register ERROR1");
              this.$refs.modal.modalError(),
                (this.modalTyp = "緊急配信登録に失敗しました。");
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.data.status === 401) {
              return;
            }
            //エラーポップアップを表示
            this.$refs.modal.modalCommon(), (this.modalTyp = "common03");
          })
          .finally(() => {
            console.log("CnfUrgent register End");
            this.axiosFlg = 0;
            // 再検索
            if (searchFlag) {
              this.onClickSearch();
            }
          });
      }
    },
    //プレイリスト情報のデータ選択時
    select: function (data, num) {
      //選択したプレイリストIDを取得（画面に表示はしない）
      this.currentplaylistId = data.playlistId;

      console.log(this.currentplaylistId);
      //前回選択行の背景色を解除
      if (this.selectRow != null) {
        this.selectRow.style["background-color"] = "transparent";
      }

      //選択行の背景色を変更
      this.selectRow = document.querySelector(
        ".targetTable"
      ).lastElementChild.children[num];
      this.selectRow.style["background-color"] = "#0080ff20";
    },

    //子画面で決定ボタン押下
    updateSelectDevice(deviceName) {
      this.currentdeviceId = deviceName;
      //端末番号欄に指定ありを表示
      document.getElementById("device-id").value = "指定あり";

      //ダイアログを閉じる
      this.showModal = false;
    },
    //ポップアップ表示処理
    show(modaltyp) {
      //登録ポップアップを表示
      this.modalTyp = modaltyp;
      this.$refs.modal.modalContent();
    },
    // ソート機能(プレイリスト名)
    sortPlayList() {
      console.log("CnfUrgent sortPlayList Start");
      if (this.emergencyList != null && this.emergencyList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "playlistNm";
        // 昇順・降順の切替
        if (this.sortFlgplayList == 0) {
          this.sortDirection = 1;
          this.sortFlgplayList = 1;
          document.querySelector(".playlist_nm").innerText = "プレイリスト名▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgplayList = 0;
          document.querySelector(".playlist_nm").innerText = "プレイリスト名▲";
        }
        document.querySelector(".delivery_day").innerText = "配信日";
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfUrgent sortPlayList End");
    },

    // ソート機能(配信日)
    sortDeliveryDt() {
      console.log("CnfUrgent sortDeliveryDt Start");
      if (this.emergencyList != null && this.emergencyList.length > 0) {
        // ソート対象項目の設定
        this.sortItem = "deliveryDt";
        // 昇順・降順の切替
        if (this.sortFlgdeliveryDt == 0) {
          this.sortDirection = 1;
          this.sortFlgdeliveryDt = 1;
          document.querySelector(".delivery_day").innerText = "配信日▼";
        } else {
          this.sortDirection = 0;
          this.sortFlgdeliveryDt = 0;
          document.querySelector(".delivery_day").innerText = "配信日▲";
        }
        document.querySelector(".playlist_nm").innerText = "プレイリスト名";
        // 検索メソッドの呼び出し
        this.search();
      }
      console.log("CnfUrgent sortDeliveryDt End");
    },
  },
};
</script>

<style scoped>
.div_playlist_nm {
  /*ラベル：プレイリスト名*/
  display: flex;
  padding-top: 15px;
}
.div_device_no {
  /*ラベル：端末番号*/
  display: flex;
  margin-top: 60px;
}

.div_playlist_nm,
.div_device_no {
  width: 800px;
  display: inline-block;
}

.div_search_detail {
  display: flex;
}

.div_search_show {
  display: flex;
  flex-basis: 80%;
  align-items: center; /*上下中央*/
  width: 200px;
}

.div_search_btn,
.div_emergency_delivery_btn {
  text-align: right;
  margin-left: auto;
}
.div_search_memo {
  text-align: right;
  margin-left: auto;
  visibility: hidden; /*初期表示時は非表示*/
  margin-right: 30px;
}

.div_emergency_delivery_btn {
  margin-top: 80px;
}

.div_search_memo p {
  margin: 5px 0 0 0;
}

button.button1 {
  background-color: #1a2f77;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

button.emergency {
  background-color: gray;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
}

.div_label-wrapper {
  display: inline-block;
  width: 140px;
}

.playlist_nm {
  width: 600px;
}

.delivery_day {
  width: 580px;
}

td.delivery_day {
  border-right: 0px;
}

.targetTable {
  width: 1202px;
}

tr.selected {
  background-color: #0080ff20;
}

tr.selected td {
  border-top-width: 1px;
  border-top-color: #0055ff59;
  border-bottom-width: 1px;
  border-bottom-color: #0055ff59;
}

.items tr {
  height: 35px;
}

/*ページボタン*/
.pageButton {
  height: 10px;
  width: 20px;
}

/*-----------------------------------*/
/*--------------一覧-----------------*/
.div-search-result.items {
  margin-top: -1px;
  height: 283px;
  overflow-y: auto;
  width: fit-content;
  border-style: solid;
  border-width: 1px;
  /* border-top: solid 2px; */
}

.div-search-show {
  display: flex;
}
/*-----------------------------------*/
/*ページボタン*/
.div-page-btn {
  display: flex;
  justify-content: center;
}
button.prev {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
button.next {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
/*ソートボタン*/
button.sort {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  width: 30px;
}
/*プレイリスト名*/
input[id="playlist-nm"],
select {
  width: 300px;
  height: 23px;
}
/*端末番号*/
input[id="device-id"],
select {
  width: 250px;
  height: 23px;
}

td.muji {
  border-right: 0px;
}
</style>
